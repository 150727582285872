<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small" *ngIf="mode === 'delivery'">
      <ion-text *ngIf="storeInfo && !storeInfo.vatBasedCustomerProductsActive">
        {{ "popups.order-now-popup.your-addresses" | translate }}
      </ion-text>
      <ion-text *ngIf="storeInfo && storeInfo.vatBasedCustomerProductsActive">
        {{ "popups.order-now-popup.delivered-to" | translate }}
      </ion-text>

      <p
        style="margin: 0"
        *ngIf="
          storeInfo &&
          storeInfo.vatBasedCustomerProductsActive &&
          groupApp &&
          groupApp.vatBasedCustomerAddressesActive &&
          selectedCustomerInvoice
        "
      >
        <ion-text>
          {{ "cart.cart-components.invoice.vat-number" | translate }}:
          {{ selectedCustomerInvoice.vatNumber }}
        </ion-text>
      </p>
    </ion-title>

    <ion-title size="small" *ngIf="mode === 'take-away'">
      {{ "popups.order-now-popup.stores" | translate }}</ion-title
    >
    <ion-title size="small" *ngIf="mode === 'dine-in'">
      {{ "popups.order-now-popup.select-table" | translate }}</ion-title
    >
    <ion-button
      size="small"
      *ngIf="
        mode === 'delivery' &&
        groupApp &&
        !groupApp.disable_address_crud &&
        !groupApp.vatBasedCustomerAddressesActive
      "
      [disabled]="selectedAddressLoading || onSaveDisable"
      [color]="deleteAddressMode ? 'danger' : 'dark'"
      slot="start"
      fill="clear"
      (click)="enableDeleteMode()"
    >
      <ion-spinner *ngIf="onSaveDisable"> </ion-spinner>
      <ion-icon
        *ngIf="!onSaveDisable"
        slot="icon-only"
        src="assets/ionicons/trash-outline.svg"
      ></ion-icon>
    </ion-button>
    <ion-button
      *ngIf="stores && stores.length > 1 && mode === 'take-away'"
      class="no-padding-ion-button"
      (click)="calculateLocations()"
      slot="end"
      size="small"
      fill="clear"
      color="primary"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/location.svg"> </ion-icon>
    </ion-button>
    <ion-button
      [disabled]="selectedAddressLoading || onSaveDisable"
      color="dark"
      slot="end"
      fill="dark"
      (click)="closeModal()"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
    <ion-progress-bar
      *ngIf="gpsDistanceLoading || searchStoreLoading"
      color="primary"
      type="indeterminate"
      style="position: absolute; bottom: 0"
    ></ion-progress-bar>
  </ion-toolbar>

  <ion-grid
    *ngIf="stores && stores.length > 3 && mode === 'take-away'"
    style="max-width: 350px"
  >
    <ion-searchbar
      animated
      [placeholder]="'search_store' | translate"
      (ionChange)="onSearchType()"
      (ionClear)="resetSearchStore()"
      type="text"
      [(ngModel)]="searchTerm"
    >
    </ion-searchbar>
  </ion-grid>
</ion-header>

<ion-backdrop
  *ngIf="showGpsLoadingAnimation"
  style="z-index: 2147483647; width: 100%; height: 100%"
  [tappable]="false"
>
</ion-backdrop>
<ion-grid
  *ngIf="showGpsLoadingAnimation"
  style="
    position: absolute;
    top: 25%;
    z-index: 2147483647;
    width: 100%;
    height: 100%;
  "
  class="ion-text-center"
>
  <ion-grid style="max-width: 180px">
    <ng-lottie
      (animationCreated)="animationCreated($event)"
      [options]="options"
    ></ng-lottie>
  </ion-grid>
</ion-grid>

<ion-content>
  <ion-list class="ion-no-padding" *ngIf="mode === 'delivery'">
    <div
      class="ion-text-center"
      style="margin-top: 10px"
      *ngIf="
        currentUser && (!currentUser.addresses || !currentUser.addresses.length)
      "
    >
      <ion-icon style="font-size: 50px" src="assets/ionicons/location.svg">
      </ion-icon>
      <h6 style="padding: 8px" class="ion-text-center">
        {{ "popups.order-now-popup.there-are-no-saved-addresses" | translate }}
      </h6>
    </div>

    <div *ngIf="currentUser && currentUser.addresses">
      <ion-item
        [disabled]="selectedAddressLoading || onSaveDisable"
        mode="ios"
        button
        lines="full"
        detail="false"
        *ngFor="
          let address of currentUser.addresses
            | filterAddressesMigrationCustomerId : migration_customer_id
        "
        (click)="
          deleteAddressMode
            ? presentConfirmDelete(address)
            : selectAddressBtn(address)
        "
      >
        <ion-label class="ion-text-wrap">
          <ion-label class="ion-no-padding">
            {{ address.formatted_address }}
          </ion-label>

          <ion-badge slot="end" *ngIf="address.unverified">
            {{ "popups.order-now-popup.unverified" | translate }}
          </ion-badge>
        </ion-label>

        <ion-icon
          *ngIf="!deleteAddressMode"
          src="assets/ionicons/chevron-forward-outline.svg"
          slot="end"
        >
        </ion-icon>

        <ion-icon
          slot="start"
          style="font-size: 23px"
          *ngIf="deleteAddressMode"
          src="assets/ionicons/trash-outline.svg"
          color="danger"
        >
        </ion-icon>
      </ion-item>
    </div>
    <ion-item lines="none" *ngIf="!currentUser">
      <ion-label>{{
        "popups.order-now-popup.there-are-no-saved-addresses" | translate
      }}</ion-label>
    </ion-item>
  </ion-list>

  <ion-list
    class="ion-no-padding"
    *ngIf="stores && stores.length > 0 && mode === 'take-away'"
  >
    <ion-item
      [class]="
        mode && mode === 'take-away' && stores && stores.length > 1
          ? 'storeItemBig'
          : ''
      "
      mode="ios"
      *ngFor="
        let store of filteredStores | orderStoresByDistance;
        trackBy: trackStores
      "
      button
      detail="false"
      lines="full"
      (click)="selectStore(store.store_id)"
    >
      <ion-label class="ion-text-wrap">
        <ion-text *ngIf="store.store_name && selectedLangugage">
          {{
            store.store_name_mobileorder &&
            store.store_name_mobileorder[selectedLangugage]
              ? store.store_name_mobileorder[selectedLangugage]
              : store.store_panel_name_langs &&
                store.store_panel_name_langs[selectedLangugage]
              ? store.store_panel_name_langs[selectedLangugage]
              : store.store_panel_name_langs && store.store_panel_name_langs.el
              ? store.store_panel_name_langs.el
              : store.store_name_panel
              ? store.store_name_panel
              : store.store_name
              ? store.store_name
              : ""
          }}</ion-text
        >
        <p>{{ store.address }}</p>
      </ion-label>
      <ion-button fill="clear" slot="end">
        <ion-icon
          color="dark"
          src="assets/ionicons/chevron-forward-outline.svg"
          slot="icon-only"
        >
        </ion-icon>
      </ion-button>
      <p
        style="
          margin: 0;
          position: absolute;
          bottom: 5px;
          right: 10px;
          font-size: small;
          z-index: 100;
        "
        *ngIf="
          store.estimatedDistanceFromClientGps &&
          !gpsDistanceLoading &&
          stores.length > 1
        "
      >
        <ion-text color="primary">
          {{ store.estimatedDistanceFromClientGps }}km
        </ion-text>
      </p>
    </ion-item>
  </ion-list>
  <ion-list
    class="ion-no-padding"
    *ngIf="stores && stores.length > 0 && mode === 'dine-in'"
  >
    <!-- <ion-item
      mode="ios"
      *ngFor="let store of stores"
      button
      detail="false"
      lines="full"
      (click)="selectStore(store.store_id)"
    >
      <ion-label class="ion-text-wrap">
        {{ store.store_name }}
        <p>{{ store.address }}</p></ion-label
      >
      <ion-button fill="clear" slot="end">
        <ion-icon
          color="dark"
          src="assets/ionicons/chevron-forward-outline.svg"
          slot="icon-only"
        >
        </ion-icon>
      </ion-button>
    </ion-item> -->
  </ion-list>
</ion-content>

<ion-footer
  *ngIf="
    mode === 'delivery' &&
    groupApp &&
    !groupApp.disable_address_crud &&
    !groupApp.vatBasedCustomerAddressesActive
  "
>
  <ion-grid class="ion-no-padding ion-no-margin">
    <ion-row style="width: 100%" class="ion-no-padding ion-no-margin">
      <ion-col class="ion-text-center ion-no-padding ion-no-margin">
        <ion-button
          [disabled]="selectedAddressLoading"
          (click)="addNewAddress()"
        >
          <ion-text *ngIf="!selectedAddressLoading">{{
            "popups.order-now-popup.add-new-address" | translate
          }}</ion-text>
          <ion-spinner *ngIf="selectedAddressLoading"> </ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<ion-footer
  *ngIf="
    mode === 'delivery' &&
    groupApp &&
    groupApp.vatBasedCustomerAddressesActive &&
    storeInfo &&
    storeInfo.vatBasedCustomerProductsActive
  "
>
  <ion-grid class="ion-no-padding ion-no-margin">
    <ion-row style="width: 100%" class="ion-no-padding ion-no-margin">
      <ion-col class="ion-text-center ion-no-padding ion-no-margin">
        <ion-button
          [disabled]="selectedAddressLoading"
          (click)="presentSelectInvoiceModal()"
        >
          <ion-text *ngIf="!selectedAddressLoading">{{
            "popups.order-now-popup.select-new-vat" | translate
          }}</ion-text>
          <ion-spinner *ngIf="selectedAddressLoading"> </ion-spinner>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
