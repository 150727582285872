<ion-header>
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text style="font-size: medium">
        {{ "popups.send-order-and-pay-popup.title" | translate }}</ion-text
      >
    </ion-title>

    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-backdrop
  [visible]="true"
  style="z-index: 1000000; opacity: 0.3"
  *ngIf="createOrderLoadingAnimation"
>
</ion-backdrop>
<ion-grid
  *ngIf="createOrderLoadingAnimation"
  style="
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 1000000000;
  "
>
  <ion-row style="width: 100%; height: 100%">
    <ion-col class="ion-text-center">
      <ion-grid style="max-width: 220px">
        <ng-lottie
          (animationCreated)="animationCreated($event)"
          [options]="animationOptions"
        ></ng-lottie>
      </ion-grid>
    </ion-col>
  </ion-row>
</ion-grid>

<ion-content #content>
  <!-- <ion-list>
    <ion-item lines="none" *ngIf="orderPickup || selectedAddress">
      <ion-label class="ion-text-wrap">



      </ion-label>
    </ion-item>
  </ion-list> -->
  <ion-list
    *ngIf="
      store_info &&
      store_info.paymentGateways &&
      store_info.paymentGateways[selectedPaymentMethodID] &&
      store_info.paymentGateways[selectedPaymentMethodID].tokenization &&
      currentUser &&
      cards &&
      cards.length > 0
    "
  >
    <ion-item
      (click)="selectCard(i)"
      lines="full"
      button
      *ngFor="let card of cards; let i = index; trackBy: trackCards"
    >
      <div
        *ngIf="card.ticketRestaurant"
        style="position: absolute; top: 35px; right: 22px"
      >
        <img
          alt="ticketRestaurantCard"
          width="50px"
          src="https://data.deliverymanager.gr/bankicons/ticketRestaurantCard.png"
        />
      </div>
      <ion-button fill="clear" slot="start">
        <ion-icon
          slot="icon-only"
          *ngIf="!card.selected"
          src="assets/ionicons/radio-button-off-outline.svg"
        ></ion-icon>
        <ion-icon
          slot="icon-only"
          *ngIf="card.selected"
          src="assets/ionicons/radio-button-on-outline.svg"
        ></ion-icon>
      </ion-button>
      <ion-label
        class="ion-no-padding"
        *ngIf="card.expiry"
        style="position: absolute; right: 23px; top: 0"
      >
        <ion-text>{{ card.expiry | date : "MM/yyyy" }}</ion-text>
      </ion-label>
      <ion-row>
        <ion-col size="12" class="ion-no-padding ion-no-margin">
          <ion-label class="ion-text-wrap">
            <strong>{{ card.number }}</strong>
            <p>
              <ion-text>
                {{ card.cardType.Name }}&nbsp;{{
                  card.bank ? "- " + card.bank : ""
                }}
              </ion-text>
            </p>
            <p *ngIf="card.invalid">
              <ion-text color="danger">
                {{
                  "payment-cards.this-card-should-be-saved-again" | translate
                }}</ion-text
              >
            </p>
          </ion-label>
        </ion-col>
      </ion-row>
    </ion-item>
    <ion-item (click)="addNewCard()" lines="full" button>
      <ion-button fill="clear" slot="start">
        <ion-icon
          slot="icon-only"
          *ngIf="!newCard.selected"
          src="assets/ionicons/radio-button-off-outline.svg"
        >
        </ion-icon>
        <ion-icon
          slot="icon-only"
          *ngIf="newCard.selected"
          src="assets/ionicons/radio-button-on-outline.svg"
        >
        </ion-icon>
      </ion-button>
      <ion-label class="ion-no-padding">
        <ion-text>{{
          "popups.send-order-and-pay-popup.add-new-payment-card" | translate
        }}</ion-text>
      </ion-label>
    </ion-item>
  </ion-list>

  <ion-item
    lines="none"
    *ngIf="
      (selectedPaymentMethodID === 'ethniki' ||
        selectedPaymentMethodID === 'piraeus' ||
        selectedPaymentMethodID === 'eurobank' ||
        selectedPaymentMethodID === 'alpha' ||
        selectedPaymentMethodID === 'mypos'|| 
      selectedPaymentMethod === 'nexi') &&
      newCard &&
      newCard.tokenization &&
      newCard.selected
    "
  >
    <ion-checkbox slot="start" [(ngModel)]="newCard.save_card"> </ion-checkbox>
    <ion-label class="ion-text-wrap" style="font-size: small">
      {{
        "popups.send-order-and-pay-popup.save-card-for-instant-future-payments"
          | translate
      }}
    </ion-label>
  </ion-item>
  <ion-item
    lines="none"
    *ngIf="
      selectedPaymentMethodID !== 'cash' &&
      selectedPaymentMethodID !== 'mobilePOS' &&
      newCard &&
      newCard.selected
    "
  >
    <ion-checkbox slot="start" [(ngModel)]="newCard.acceptedTerms">
    </ion-checkbox>
    <ion-label class="ion-text-wrap" style="font-size: small">
      {{
        "popups.send-order-and-pay-popup.i-accept-the-payment-terms" | translate
      }}
    </ion-label>
  </ion-item>
</ion-content>

<ion-footer>
  <ion-grid>
    <ion-row *ngIf="loading" style="width: 100%">
      <ion-col style="padding: 0; margin: 0" class="ion-text-center">
        <ion-spinner color="primary"> </ion-spinner>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!loading" style="width: 100%">
      <!-- <ion-col style="padding: 0; margin: 0" size="6">
        <ion-button size="medium" (click)="closeModal()" fill="clear">
          {{ "popups.send-order-and-pay-popup.cancel" | translate }}
        </ion-button>
      </ion-col> -->

      <ion-col style="padding: 0; margin: 0" size="12" class="ion-text-center">
        <ion-button
          size="medium"
          (click)="
            existedOrderItem
              ? payChangedOrderPaymentMethod()
              : checkForSendOrder()
          "
        >
          {{ "popups.send-order-and-pay-popup.send-order-button" | translate }}
          {{
            existedOrderItem
              ? existedOrderItem.grand_total
              : cartPrices && cartPrices.grand_total
              ? cartPrices.grand_total
              : ""
          }}€
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>
