import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Subscription,
  audit,
  auditTime,
  distinctUntilChanged,
  first,
} from "rxjs";
import * as fromApp from "../store/app.reducer";
import * as _ from "lodash";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { AffiliateInfoModalComponent } from "./affiliate-info-modal/affiliate-info-modal.component";
import { Router } from "@angular/router";
import { Share } from "@capacitor/share";
import { Clipboard } from "@capacitor/clipboard";
import { AffiliateReceiversCouponsComponent } from "./affiliate-receivers-coupons/affiliate-receivers-coupons.component";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import * as CouponsActions from "../discount-coupons/store/coupons.actions";
import { AnimationOptions } from "ngx-lottie";
import { LoginPopupsServiceService } from "../services/login-popups-service/login-popups-service.service";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { Capacitor } from "@capacitor/core";

declare let window: any;

@Component({
  selector: "app-affiliate",
  templateUrl: "./affiliate.page.html",
  styleUrls: ["./affiliate.page.scss"],
})
export class AffiliatePage implements OnInit {
  public windowDimensions: any;
  public copyButtonDisabled: boolean;
  public groupApp: any;
  public failedToCopyText: boolean = false;
  public disableCopyButtonTimeout: NodeJS.Timeout;
  public isPlatformBrowser: any = null;
  public selectedLanguage: any;
  public currentUser: any;
  public recipientEmail: any;
  public affiliateReceiversCouponsModal: HTMLIonModalElement;
  public sendingEmail: boolean;
  public currentStoreId: any;
  public couponCodeLoading: boolean;
  public loginState;
  public loginLoading;
  public referAFriendOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/refer-a-friend.json",
  };

  private affiliateInfoModal: HTMLIonModalElement;
  private addNewCouponCodeAlert: HTMLIonAlertElement;
  private errorToast: HTMLIonToastElement;
  private newCustomer;
  private mo_token: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private modalController: ModalController,
    private platform: Platform,
    private router: Router,
    private toastController: ToastController,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private analyticsService: AnalyticsServiceService,
    private loginPopupService: LoginPopupsServiceService
  ) {}

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        this.navigateToHome();
      }
    );

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("affiliate");
    this.analyticsService.tikTokPixelPageView("affiliate");

    this.subscriptions.push(
      this.store
        .select("platformInfo")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.isPlatformBrowser, state.browserPlatform)
          ) {
            this.isPlatformBrowser = _.cloneDeep(state.browserPlatform);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(auditTime(200))
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            console.log("groupApp", this.groupApp);
          }
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(state.currentStoreId, this.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          console.log(state.mo_token);
          if (state && !_.isEqual(state.mo_token, this.mo_token)) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }

          if (state && !_.isEqual(state.loginState, this.loginState)) {
            this.loginState = _.cloneDeep(state.loginState);

            if (
              this.loginState === "no_login" ||
              this.loginState === "login_completed"
            ) {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupState) => {
                  if (
                    groupState &&
                    groupState.groupApp &&
                    !(
                      groupState.groupApp.affiliateActive ||
                      (groupState.groupApp.affiliateProductionMode &&
                        state.user &&
                        state.user.mobile === "6945076868")
                    )
                  ) {
                    this.router.navigateByUrl("/homepage", {
                      replaceUrl: true,
                    });
                  }
                })
                .unsubscribe();
            }
          }

          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }

          if (state && !_.isEqual(state.loginLoading, this.loginLoading)) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
          }
        })
    );
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  async presentMagicLoginPopup() {
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    if (!Capacitor.isNativePlatform()) {
      this.loginPopupService.presentFirebaseModalLogin(null, null);
    } else {
      this.loginPopupService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  copyAffiliateNumber(text) {
    this.copyButtonDisabled = true;
    if (!this.isPlatformBrowser) {
      console.log("clipboard mobile");
      this.copyToClipboardMobile(text);
    } else if (this.isPlatformBrowser) {
      console.log("clipboard browser");
      this.copyToClipboardBrowser(text);
    }
  }

  sendAffiliateCodeEmail(email_to) {
    console.log(email_to);
    if (!email_to) {
      return;
    }
    this.sendingEmail = true;
    this.dataStorageService
      .sendAffiliateEmail(email_to, this.currentStoreId, this.mo_token)
      .subscribe({
        next: async (res: any) => {
          console.log("sendAffiliateCodeEmail", res);
          if (res && res.success) {
            const toast = await this.toastController.create({
              // header: this.translateService.instant("affiliate.email-sent"),
              message: this.translateService.instant("affiliate.email-sent"),
              color: "success",
              duration: 3000,
              buttons: ["OK"],
              position: "middle",
            });
            await toast.present();
            this.sendingEmail = false;
            this.recipientEmail = null;
          } else if (res && !res.success) {
            this.presentErrorToast(res.comment_id, "danger");
            this.sendingEmail = false;
            this.recipientEmail = null;
          }
        },
        error: (error) => {
          console.log(error);
          this.presentErrorToast(error, "danger");
          this.sendingEmail = false;
          this.recipientEmail = null;
          return error;
        },
      });
  }

  async presentErrorToast(message, color) {
    this.errorToast = await this.toastController.create({
      message: this.translateService.instant(message),
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.errorToast.present();
  }

  copyToClipboardBrowser(text) {
    navigator.clipboard
      .writeText(text)
      .then(async () => {
        this.failedToCopyText = false;
        this.disableCopyButtonTimeout = setTimeout(() => {
          this.copyButtonDisabled = false;
        }, 1000);
      })
      .catch(async () => {
        this.failedToCopyText = true;
        this.copyButtonDisabled = false;
        const toast = await this.toastController.create({
          message: "Try again!",
          cssClass: "copied-to-clipboard",
          color: "primary",
          duration: 1000,
          position: "middle",
        });
        toast.present();
        toast.onDidDismiss().then(() => {
          this.failedToCopyText = false;
        });
        console.log("Failed to copy text.");
      });
  }

  copyToClipboardMobile(text) {
    Clipboard.write({ string: text })
      .then(async () => {
        this.failedToCopyText = false;
        this.disableCopyButtonTimeout = setTimeout(() => {
          this.copyButtonDisabled = false;
        }, 1000);
      })
      .catch(async () => {
        this.failedToCopyText = true;
        this.copyButtonDisabled = false;
        const toast = await this.toastController.create({
          message: "Try again!",
          cssClass: "copied-to-clipboard",
          color: "primary",
          duration: 1000,
          position: "middle",
        });
        toast.present();
        toast.onDidDismiss().then(() => {
          this.failedToCopyText = false;
        });
        console.log("Failed to copy text.");
      });
  }

  async openAffiliateInfoModal() {
    this.affiliateInfoModal = await this.modalController.create({
      component: AffiliateInfoModalComponent,
      backdropDismiss: false,
      cssClass: "affiliate-info-modal",
      componentProps: {
        affiliateInformationAndTerms:
          this.groupApp.affiliateInformationAndTerms,
      },
    });
    await this.affiliateInfoModal.present();
  }

  async openAffiliateReceiversCouponsModal() {
    this.affiliateReceiversCouponsModal = await this.modalController.create({
      component: AffiliateReceiversCouponsComponent,
      backdropDismiss: false,
      cssClass: "",
      componentProps: {
        affiliate_coupon_code: this.currentUser.affiliate_coupon_code,
      },
    });
    await this.affiliateReceiversCouponsModal.present();
  }

  async socialShareCode(code) {
    let message;
    if (
      this.groupApp &&
      this.groupApp.affiliateSettings &&
      this.groupApp.affiliateSettings.affiliateShareDescription
    ) {
      message =
        this.groupApp.affiliateSettings.affiliateShareDescription.replace(
          "{{coupon_code}}",
          `${code}`
        );
    } else if (this.groupApp) {
      message = `Χρησιμοποίησε τον κωδικό ${code} για να πάρεις το κουπόνι σου!`;
    }
    console.log("affiliate bitly", this.currentUser.affiliate_bitly);
    await Share.share({
      title: "Πρόσκληση από φίλο",
      text: message,
      url: this.currentUser.affiliate_bitly,
    });
  }

  async addNewCouponCode() {
    this.addNewCouponCodeAlert = await this.alertController.create({
      header: this.translateService.instant(
        "affiliate.add-affiliate-coupon-code"
      ),
      backdropDismiss: false,
      message: this.translateService.instant(
        "discount-coupons.please-enter-your-coupon-code"
      ),
      inputs: [
        {
          name: "couponCode",
          type: "text",

          placeholder: this.translateService.instant(
            "discount-coupons.write-here"
          ),
        },
      ],
      buttons: [
        {
          text: this.translateService.instant(
            "popups.magic-login-confirmation.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "OK",
          role: "OK",
          handler: (data) => {
            if (data && data.couponCode) {
              this.couponCodeLoading = true;
              const coupon_code = data.couponCode.toString().toUpperCase();
              this.dataStorageService
                .moCheckCouponCode({
                  coupon_code: coupon_code,
                  group: this.groupApp.group,
                  store_id: this.currentStoreId,
                  mobile:
                    this.currentUser && this.currentUser.mobile
                      ? this.currentUser.mobile
                      : null,
                })

                .subscribe({
                  next: async (res: any) => {
                    if (res && res.success) {
                      this.couponCodeLoading = false;
                      const alert = await this.alertController.create({
                        header: this.translateService.instant("information"),
                        subHeader:
                          res.data && res.data.coupon_rule_description
                            ? res.data.coupon_rule_description
                            : res.data && res.data.firstName
                            ? "Έγκυρος κωδικός από: " + res.data.firstName
                            : "",

                        message: res.comment_id
                          ? this.translateService.instant(res.comment_id) +
                            " " +
                            this.translateService.instant(
                              "wait-a-minute-for-your-coupon-to-be-created"
                            )
                          : res.comments +
                            " " +
                            this.translateService.instant(
                              "wait-a-minute-for-your-coupon-to-be-created"
                            ),
                        buttons: ["OK"],
                      });
                      await alert.present();

                      if (res && res.data && res.data.affiliate_coupon_code) {
                        this.store.dispatch(
                          new CouponsActions.SetTriggerCouponCodeCheck({
                            timestamp: Date.now(),
                            type: "affiliate_coupon_code",
                            coupon_code: _.cloneDeep(
                              res.data.affiliate_coupon_code
                            ),
                          })
                        );
                      } else if (res && res.data && res.data.coupon_rule_id) {
                        this.store.dispatch(
                          new CouponsActions.SetTriggerCouponCodeCheck({
                            timestamp: Date.now(),
                            type: "coupon_rule_id",
                            coupon_code: _.cloneDeep(res.data.coupon_rule_id),
                          })
                        );
                      } else if (
                        res &&
                        res.data &&
                        res.data.partner_coupon_id
                      ) {
                        this.store.dispatch(
                          new CouponsActions.SetTriggerCouponCodeCheck({
                            timestamp: Date.now(),
                            type: "partner_coupon_id",
                            coupon_code: _.cloneDeep(
                              res.data.partner_coupon_id
                            ),
                          })
                        );
                      } else {
                        // this.store.dispatch(
                        //   new CouponsActions.SetTriggerCouponCodeCheck({
                        //     timestamp: Date.now(),
                        //     type: "coupon_rule_id",
                        //     coupon_code: _.cloneDeep(coupon_code),
                        //   })
                        // );
                      }
                    } else if (
                      !res.success &&
                      (res.comment_id || res.comments)
                    ) {
                      this.couponCodeLoading = false;
                      console.log("The coupon rule id is false or something");
                      this.presentErrorToast(
                        this.translateService.instant(
                          res.comment_id || res.comments
                        ),
                        "danger"
                      );
                    } else {
                      this.couponCodeLoading = false;
                      this.presentErrorToast(
                        this.translateService.instant(
                          "errorMessages.classic_error"
                        ),
                        "danger"
                      );
                    }
                  },
                  error: (error) => {
                    this.couponCodeLoading = false;
                    this.presentErrorToast(
                      this.translateService.instant(
                        "errorMessages.  problem_reaching_server"
                      ),
                      "danger"
                    );

                    console.log("error check coupon", error);
                  },
                });
            } else {
              if (this.errorToast) {
                this.errorToast.dismiss();
                this.errorToast = null;
              }
              this.presentErrorToast(
                this.translateService.instant(
                  "discount-coupons.please-type-a-coupon-code"
                ),
                "danger"
              );
              return false;
            }
          },
        },
      ],
    });

    await this.addNewCouponCodeAlert.present();
    this.addNewCouponCodeAlert
      .onDidDismiss()
      .then(() => {
        this.addNewCouponCodeAlert = null;
      })
      .finally(() => {});
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }
}
