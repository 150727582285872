import * as OrderCouponRulesActions from "./orderCouponRules.actions";
import * as _ from "lodash";

export interface State {
  orderCouponRules: any;
}

const initialState: State = {
  orderCouponRules: null,
};

export function orderCouponRulesReducer(
  state = initialState,
  action: OrderCouponRulesActions.OrderCouponRulesActions
) {
  switch (action.type) {
    case OrderCouponRulesActions.SET_ORDER_COUPON_RULES:
      return {
        ...state,
        orderCouponRules: action.payload,
      };
    case OrderCouponRulesActions.CLEAR_ORDER_COUPON_RULES:
      return {
        ...state,
        orderCouponRules: null,
      };

    default:
      return state;
  }
}
