<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    cartProducts &&
    cartProducts.length > 0 &&
    subtotal &&
    ((discount_amount && discount_amount !== 0 && discount_amount !== '0.00') ||
      (deliveryCost && deliveryCost !== '0') ||
      (cartCustomOptionsTotalPrice && cartCustomOptionsTotalPrice > 0) ||
      (store_info &&
        store_info.catalogPricesWithoutVat &&
        vat_total &&
        vat_total !== '0.00'))
  "
>
  <ion-text style="font-weight: bold; font-size: small" slot="start">{{
    "cart.cart-components.cart-prices.subtotal" | translate
  }}</ion-text>
  <ion-text style="font-weight: bold; font-size: small" slot="end"
    >{{ subtotal }}€</ion-text
  >
</ion-item>

<ion-item
  class="itemDiscount"
  lines="none"
  *ngIf="
    cartProducts &&
    cartProducts.length > 0 &&
    discount_amount &&
    discount_amount !== 0 &&
    discount_amount !== '0.00'
  "
>
  <ion-label class="ion-text-wrap">
    <div>
      <ion-text style="font-weight: bold; font-size: small" slot="start">{{
        "cart.cart-components.cart-prices.discount" | translate
      }}</ion-text>
    </div>
    <div
      style="font-size: x-small; line-height: 1.2; font-weight: bold"
      *ngFor="let coupon of selectedCoupons"
    >
      <ion-text
        *ngIf="
          showCouponDiscountMessage &&
          (coupon.discount || coupon.discount_fixed)
        "
        color="danger"
      >
        {{ "cart.cart-components.cart-prices.you-still-have" | translate }}
        {{
          substructCouponDiscountAndDiscount(
            coupon.discount || coupon.discount_fixed,
            cartPrices.discount_amount
          )
        }}€
        {{
          "cart.cart-components.cart-prices.left-for-the-full-coupon-discount"
            | translate
        }}
      </ion-text>
    </div>
  </ion-label>
  <ion-text style="font-weight: bold; font-size: small" slot="end"
    >{{ discount_amount }}€</ion-text
  >
</ion-item>
<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    !orderPickup &&
    !dineIn &&
    cartProducts &&
    cartProducts.length > 0 &&
    (!deliveryCost || deliveryCost === '0' || deliveryCost === 0) &&
    currentValidStore
  "
>
  <ion-text style="font-weight: bold; font-size: small" slot="start">{{
    "cart.cart-components.cart-prices.delivery-cost" | translate
  }}</ion-text>
  <ion-text
    color="danger"
    style="font-weight: bold; font-size: small"
    slot="end"
    >{{ "free" | translate }}</ion-text
  >
</ion-item>
<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    !orderPickup &&
    !dineIn &&
    cartProducts &&
    cartProducts.length > 0 &&
    deliveryCost &&
    deliveryCost !== '0' &&
    currentValidStore &&
    !currentValidStore.delivery_cost_per_kilometer_active
  "
>
  <ion-text style="font-weight: bold; font-size: small" slot="start">{{
    "cart.cart-components.cart-prices.delivery-cost" | translate
  }}</ion-text>
  <ion-text style="font-weight: bold; font-size: small" slot="end"
    >{{ deliveryCost }}€</ion-text
  >
</ion-item>
<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    !orderPickup &&
    !dineIn &&
    cartProducts &&
    cartProducts.length > 0 &&
    deliveryCost &&
    deliveryCost !== '0' &&
    currentValidStore &&
    currentValidStore.delivery_cost_per_kilometer_active
  "
>
  <ion-label style="margin: 0">
    <div>
      <ion-text style="font-weight: bold; font-size: small"
        >{{ "cart.cart-components.cart-prices.delivery-cost" | translate }}
        <span
          *ngIf="
            !currentUser &&
            !catalogMode &&
            !deliveryCostReplacedWithPrdMaxDeliveryCharge &&
            (!currentValidStore.calculation_type ||
              currentValidStore.calculation_type === 'path')
          "
          >({{ "estimation" | translate }})</span
        ></ion-text
      >

      <ion-icon
        *ngIf="!deliveryCostReplacedWithPrdMaxDeliveryCharge"
        (click)="openDeliveryCostInfoModal(currentValidStore, deliveryCost)"
        style="
          vertical-align: bottom;
          font-size: 17px;
          margin-left: 5px;
          cursor: pointer;
        "
        src="assets/ionicons/information-circle-outline.svg"
        slot="icon-only"
      ></ion-icon>
    </div>
  </ion-label>

  <ion-text style="font-weight: bold; font-size: small" slot="end"
    >{{ deliveryCost }}€</ion-text
  >
</ion-item>
<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    deliveryTipsTotal && deliveryTipsTotal > 0 && deliveryTipsTotal !== '0.00'
  "
>
  <ion-text style="font-weight: bold; font-size: small" slot="start">{{
    "cart.cart-components.cart-prices.delivery-tips" | translate
  }}</ion-text>
  <ion-text style="font-weight: bold; font-size: small" slot="end"
    >{{ deliveryTipsTotal }}€
  </ion-text>
</ion-item>

<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="wrapperTaxTotal && wrapperTaxTotal > 0 && wrapperTaxTotal !== '0.00'"
>
  <div
    slot="start"
    style="white-space: nowrap; display: flex; max-width: 135px"
  >
    <ion-text
      style="font-weight: bold; font-size: 0.75rem"
      *ngIf="enviromental_fee_translations"
      >{{
        "cart.cart-components.cart-prices.enviromental-fee-total" | translate
      }}</ion-text
    >
    <ion-text
      style="font-weight: bold; font-size: 0.75rem"
      *ngIf="!enviromental_fee_translations"
      >{{
        "cart.cart-components.cart-prices.wrapper-tax-total" | translate
      }}</ion-text
    >
  </div>

  <ion-text style="font-weight: bold; font-size: small" slot="end"
    >{{ wrapperTaxTotal }}€
  </ion-text>
</ion-item>
<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax &&
    cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax > 0 &&
    cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax !== '0.00'
  "
>
  <ion-text style="font-weight: bold; font-size: small" slot="start">{{
    "cart.cart-components.cart-prices.extra-options" | translate
  }}</ion-text>
  <ion-text style="font-weight: bold; font-size: small" slot="end"
    >{{ cartCustomOptionsTotalPriceWithoutTipsAndWrapperTax }}€
  </ion-text>
</ion-item>

<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    store_info &&
    store_info.enable_catalog_vat_levels &&
    store_info.catalogPricesWithoutVat &&
    vat_total &&
    vat_total > 0 &&
    vat_total !== '0.00'
  "
>
  <ion-text
    (click)="openVatDetailsModal()"
    style="
      font-weight: bold;
      font-size: small;
      cursor: pointer;
      margin-right: 5px;
    "
    slot="start"
    >{{ "cart.cart-components.cart-prices.vat_total" | translate }}
  </ion-text>
  <ion-icon
    (click)="openVatDetailsModal()"
    style="font-size: 20px; margin-right: 10px; cursor: pointer"
    slot="start"
    src="assets/ionicons/information-circle-outline.svg"
  >
  </ion-icon>
  <ion-text style="font-weight: bold; font-size: small" slot="end"
    >{{ vat_total }}€
  </ion-text>
</ion-item>
<ion-item
  lines="none"
  *ngIf="
    cartProducts &&
    cartProducts.length > 0 &&
    grand_total &&
    groupApp &&
    (!groupApp.design_settings ||
      !groupApp.design_settings.hideZeroPrices ||
      (groupApp.design_settings &&
        groupApp.design_settings.hideZeroPrices &&
        grand_total &&
        grand_total !== '0.00'))
  "
>
  <ion-text style="font-weight: bold; font-size: large" slot="start">{{
    "cart.cart-components.cart-prices.total-price" | translate
  }}</ion-text>
  <ion-text style="font-weight: bold; font-size: large" slot="end"
    >{{ grand_total }}€</ion-text
  >
</ion-item>

<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    cartInfoMessagesGroup &&
    cartInfoMessagesGroup.inline &&
    (cartInfoMessagesGroup.inline
      | filterInlineCartInfoMessages : cartPrices : 'cart-prices') &&
    (
      cartInfoMessagesGroup.inline
      | filterInlineCartInfoMessages : cartPrices : 'cart-prices'
    ).length &&
    groupApp &&
    selectedLang
  "
>
  <ion-label class="ion-text-wrap ion-no-margin">
    <div
      *ngFor="
        let infoMessage of cartInfoMessagesGroup.inline
          | filterInlineCartInfoMessages : cartPrices : 'cart-prices'
      "
    >
      <div
        style="color: var(--ion-color-danger)"
        [innerHTML]="
          infoMessage.title[selectedLang]
            ? infoMessage.title[selectedLang]
            : infoMessage.title[mo_base]
            ? infoMessage.title[mo_base]
            : infoMessage.title['en']
        "
        *ngIf="infoMessage && infoMessage.title"
      ></div>
      <p
        style="color: var(--ion-color-danger)"
        [innerHTML]="
          infoMessage.description[selectedLang]
            ? infoMessage.description[selectedLang]
            : infoMessage.description[mo_base]
            ? infoMessage.description[mo_base]
            : infoMessage.description['en']
        "
        style="margin: 0"
        *ngIf="infoMessage && infoMessage.description"
      ></p>
    </div>
  </ion-label>
</ion-item>

<ion-item
  class="itemSmallerHeight"
  lines="none"
  *ngIf="
    cartInfoMessagesStore &&
    cartInfoMessagesStore.inline &&
    (cartInfoMessagesStore.inline
      | filterInlineCartInfoMessages : cartPrices : 'cart-prices') &&
    (
      cartInfoMessagesStore.inline
      | filterInlineCartInfoMessages : cartPrices : 'cart-prices'
    ).length &&
    groupApp &&
    selectedLang
  "
>
  <ion-label class="ion-text-wrap ion-no-margin">
    <div
      *ngFor="
        let infoMessage of cartInfoMessagesStore.inline
          | filterInlineCartInfoMessages : cartPrices : 'cart-prices'
      "
    >
      <div
        style="color: var(--ion-color-danger)"
        [innerHTML]="
          infoMessage.title[selectedLang]
            ? infoMessage.title[selectedLang]
            : infoMessage.title[mo_base]
            ? infoMessage.title[mo_base]
            : infoMessage.title['en']
        "
        *ngIf="infoMessage && infoMessage.title"
      ></div>
      <p
        style="color: var(--ion-color-danger)"
        [innerHTML]="
          infoMessage.description[selectedLang]
            ? infoMessage.description[selectedLang]
            : infoMessage.description[mo_base]
            ? infoMessage.description[mo_base]
            : infoMessage.description['en']
        "
        style="margin: 0"
        *ngIf="infoMessage && infoMessage.description"
      ></p>
    </div>
  </ion-label>
</ion-item>

<ion-item
  *ngIf="
    filteredOrderCouponRules &&
    filteredOrderCouponRules.length &&
    cartProducts &&
    cartProducts.length
  "
>
  <ion-label
    style="font-size: small; font-weight: bold"
    class="ion-text-wrap ion-text-center"
  >
    <div *ngFor="let rule of filteredOrderCouponRules">
      <div *ngIf="!showCreationMinOrderMessage(rule)">
        <ion-text color="primary">
          <span
            *ngIf="
              rule.discountType !== 'percent' &&
              rule.discountType !== 'combined' &&
              rule.discount
            "
          >
            {{
              "cart.cart-components.cart-prices.you-will-win-a-coupon-with"
                | translate
            }}
            -{{ rule.discount }}€
            {{
              "cart.cart-components.cart-prices.discount-lowercase" | translate
            }}
            {{
              "cart.cart-components.cart-prices.for-your-next-order" | translate
            }}
          </span>
          <span *ngIf="rule.discountType === 'percent' && rule.discount">
            {{
              "cart.cart-components.cart-prices.you-will-win-a-coupon-with"
                | translate
            }}
            -{{ rule.discount }}%
            {{
              "cart.cart-components.cart-prices.discount-lowercase" | translate
            }}
            {{
              "cart.cart-components.cart-prices.for-your-next-order" | translate
            }}
          </span>
          <span
            *ngIf="
              rule.discountType === 'combined' &&
              rule.discount_percent &&
              rule.discount_fixed
            "
          >
            {{
              "cart.cart-components.cart-prices.you-will-win-a-coupon-with"
                | translate
            }}
            -{{ rule.discount_percent }} & -{{ rule.discount_fixed }}€
            {{
              "cart.cart-components.cart-prices.discount-lowercase" | translate
            }}
            {{
              "cart.cart-components.cart-prices.for-your-next-order" | translate
            }}
          </span>
          <span
            *ngIf="
              !(
                (rule.discountType !== 'percent' &&
                  rule.discountType !== 'combined' &&
                  rule.discount) ||
                (rule.discountType === 'combined' &&
                  rule.discount_percent &&
                  rule.discount_fixed)
              ) &&
              rule.product_id &&
              !rule.classic_offer_product
            "
          >
            {{
              "cart.cart-components.cart-prices.you-will-win-a-coupon-for"
                | translate
            }}
            {{ rule.description | translate }}
            {{ "cart.cart-components.cart-prices.your-next-order" | translate }}
          </span>
        </ion-text>
      </div>
      <span style="font-size: small" *ngIf="showCreationMinOrderMessage(rule)">
        <ion-text color="danger">
          {{ "cart.cart-components.cart-prices.with-order-over" | translate }}
          {{ rule.creationMinOrder }}€
          <span
            *ngIf="
              rule.discountType !== 'percent' &&
              rule.discountType !== 'combined' &&
              rule.discount
            "
          >
            {{
              "cart.cart-components.cart-prices.you-will-win-a-coupon-with-lowercase"
                | translate
            }}
            -{{ rule.discount }}€
            {{
              "cart.cart-components.cart-prices.discount-lowercase" | translate
            }}
            {{
              "cart.cart-components.cart-prices.for-your-next-order" | translate
            }}
          </span>
          <span *ngIf="rule.discountType === 'percent' && rule.discount">
            {{
              "cart.cart-components.cart-prices.you-will-win-a-coupon-with-lowercase"
                | translate
            }}
            -{{ rule.discount }}%
            {{
              "cart.cart-components.cart-prices.discount-lowercase" | translate
            }}
            {{
              "cart.cart-components.cart-prices.for-your-next-order" | translate
            }}
          </span>
          <span
            *ngIf="
              rule.discountType === 'combined' &&
              rule.discount_percent &&
              rule.discount_fixed
            "
          >
            {{
              "cart.cart-components.cart-prices.you-will-win-a-coupon-with-lowercase"
                | translate
            }}
            -{{ rule.discount_percent }}% & -{{ rule.discount_fixed }}€
            {{
              "cart.cart-components.cart-prices.discount-lowercase" | translate
            }}
            {{
              "cart.cart-components.cart-prices.for-your-next-order" | translate
            }}
          </span>
          <span
            *ngIf="
              !(
                (rule.discountType !== 'percent' &&
                  rule.discountType !== 'combined' &&
                  rule.discount) ||
                (rule.discountType === 'combined' &&
                  rule.discount_percent &&
                  rule.discount_fixed)
              ) &&
              rule.product_id &&
              !rule.classic_offer_product
            "
          >
            {{
              "cart.cart-components.cart-prices.you-will-win-a-coupon-for"
                | translate
            }}
            {{ rule.description | translate }}
            {{ "cart.cart-components.cart-prices.your-next-order" | translate }}
          </span>
        </ion-text>
      </span>
    </div>
  </ion-label>
</ion-item>

<ion-item
  *ngIf="
    ((groupApp && groupApp.pointsSystem) ||
      (currentUser && currentUser.admin)) &&
    !currentOrderPoints &&
    store_info &&
    pointsDisableRuleArr &&
    pointsDisableRuleArr.length &&
    pointsDisableRuleMessage &&
    cartProducts &&
    cartProducts.length
  "
>
  <ion-label
    style="font-size: small; font-weight: bold"
    class="ion-text-wrap ion-text-center"
  >
    <ion-text color="danger">
      {{ pointsDisableRuleMessage }}
    </ion-text>
  </ion-label>
</ion-item>

<ion-item
  lines="none"
  *ngIf="
    ((groupApp && groupApp.pointsSystem) ||
      (currentUser && currentUser.admin)) &&
    currentOrderPoints
  "
>
  <ion-label
    style="margin-right: 13px"
    [class]="
      catalogMode
        ? 'ion-text-center catalogModePoints ion-text-wrap ion-align-items-center ion-justify-content-center'
        : 'ion-text-center ion-text-wrap ion-align-items-center ion-justify-content-center'
    "
  >
    <ion-text style="font-weight: bold"
      >{{
        "cart.cart-components.cart-prices.from-current-order-you-win"
          | translate
      }}
      <ion-chip
        (click)="navigateToPointsPage()"
        color="primary"
        style="justify-content: center; vertical-align: middle"
      >
        {{ currentOrderPoints }}
      </ion-chip>
      {{ "cart.cart-components.cart-prices.points" | translate }}!
    </ion-text>
  </ion-label>
  <ion-button
    *ngIf="
      (points_deducted_from_coupon &&
        store_info &&
        store_info.overridePromoItemsForPointsSystem) ||
      pointsFactorUsed
    "
    (click)="openPointsInfoAlert()"
    size="small"
    fill="clear"
    style="position: absolute; right: 3px"
  >
    <ion-icon
      slot="icon-only"
      src="assets/ionicons/information-circle-outline.svg"
    >
    </ion-icon>
  </ion-button>
</ion-item>
