import { Action } from "@ngrx/store";

export const SET_ORDER_PICKUP = "[DELIVERY METHOD ADDRESS] Set Order Pickup";
export const SET_DINE_IN = "[DELIVERY METHOD ADDRESS] Set Dine In";
export const SET_DINE_IN_ORDER_START_TIMESTAMP =
  "[DELIVERY METHOD ADDRESS] Set Dine In Order Start Timestamp";
export const SET_OPEN_NOT_VALID_ADDRESS_MODAL =
  "[DELIVERY METHOD ADDRESS]  Set Open Not Valid Address Modal";
export const SET_OPEN_SELECT_STORE_POPUP_DELIVERY =
  "[DELIVERY METHOD ADDRESS]  Set Open Select Store Popup Delivery";
export const SET_NO_LOCATION_DINE_IN_MODAL_OPEN =
  "[DELIVERY METHOD ADDRESS]  Set No Location Dine In Modal Open";

export const SET_SELECTED_ADDRESS =
  "[DELIVERY METHOD ADDRESS]  Set Selected Address";
export const SET_SELECTED_DINEIN_TABLE =
  "[DELIVERY METHOD ADDRESS]  Set Selected Dine In Table Date";

export const SET_CHECK_SELECTED_ADDRESS =
  "[DELIVERY METHOD ADDRESS]  Set Check Selected Address";
export const SET_SELECTED_ADDRESS_LOADING =
  "[DELIVERY METHOD ADDRESS]  Set Selected Address Loading";
export const SET_VALID_STORES = "[DELIVERY METHOD ADDRESS]  Set Valid Stores";
export const SET_VALID_DELIVERY_AREAS =
  "[DELIVERY METHOD ADDRESS]  Set Valid Delivery Areas";
export const SET_SELECTED_PREORDER_DATE =
  "[DELIVERY METHOD ADDRESS]  Set Selected Preorder Date";

export const SET_PREORDER_RESPONSE =
  "[DELIVERY METHOD ADDRESS]  Set Preorder Response";

export const CLEAR_STATE = "[DELIVERY METHOD ADDRESS]  Clear State";
export const CLEAR_DINE_IN_ORDER_START_TIMESTAMP =
  "[DELIVERY METHOD ADDRESS] Clear Dine In Order Start Timestamp";
export const SET_NAVIGATION_ON_DELIVERY_METHOD_CHANGED =
  "[DELIVERY METHOD ADDRESS] Set Navigation On Delivery Method Changed";

export const SET_FIXED_POINTS_ADDRESSES =
  "[DELIVERY METHOD ADDRESS] Set Fixed Points Addresses";

export class setOrderPickup implements Action {
  readonly type = SET_ORDER_PICKUP;
  constructor(public payload: any) {}
}
export class setDineIn implements Action {
  readonly type = SET_DINE_IN;
  constructor(public payload: any) {}
}

export class setNoLocationDineInModalOpen implements Action {
  readonly type = SET_NO_LOCATION_DINE_IN_MODAL_OPEN;
  constructor(public payload: any) {}
}

export class setDineInOrderStartTimestamp implements Action {
  readonly type = SET_DINE_IN_ORDER_START_TIMESTAMP;
  constructor(public payload: any) {}
}
export class setSelectedAddress implements Action {
  readonly type = SET_SELECTED_ADDRESS;
  constructor(public payload: any) {}
}

export class setSelectedDineInTable implements Action {
  readonly type = SET_SELECTED_DINEIN_TABLE;
  constructor(public payload: any) {}
}
export class setCheckSelectedAddress implements Action {
  readonly type = SET_CHECK_SELECTED_ADDRESS;
  constructor(public payload: any) {}
}

export class setSelectedPreorderDate implements Action {
  readonly type = SET_SELECTED_PREORDER_DATE;
  constructor(public payload: any) {}
}
export class setPreorderResponse implements Action {
  readonly type = SET_PREORDER_RESPONSE;
  constructor(public payload: any) {}
}

export class setOpenNotValidAddressModal implements Action {
  readonly type = SET_OPEN_NOT_VALID_ADDRESS_MODAL;
  constructor(public payload: any) {}
}

export class setOpenSelectStorePopupDelivery implements Action {
  readonly type = SET_OPEN_SELECT_STORE_POPUP_DELIVERY;
  constructor(public payload: any) {}
}

export class setSelectedAddressLoading implements Action {
  readonly type = SET_SELECTED_ADDRESS_LOADING;
  constructor(public payload: any) {}
}

export class setValidStores implements Action {
  readonly type = SET_VALID_STORES;
  constructor(public payload: any) {}
}

export class setValidDeliveryAreas implements Action {
  readonly type = SET_VALID_DELIVERY_AREAS;
  constructor(public payload: any) {}
}

export class clearState implements Action {
  readonly type = CLEAR_STATE;
  constructor() {}
}

export class clearDineInOrderStartTimestamp implements Action {
  readonly type = CLEAR_DINE_IN_ORDER_START_TIMESTAMP;
  constructor() {}
}

export class SetNavigationOnDeliveryMethodChanged implements Action {
  readonly type = SET_NAVIGATION_ON_DELIVERY_METHOD_CHANGED;
  constructor(public payload: any) {}
}

export class SetFixedPointsAddresses implements Action {
  readonly type = SET_FIXED_POINTS_ADDRESSES;
  constructor(public payload: any) {}
}

export type DeliveryMethodAddressActions =
  | setOrderPickup
  | setDineIn
  | setNoLocationDineInModalOpen
  | setDineInOrderStartTimestamp
  | setSelectedAddress
  | SetNavigationOnDeliveryMethodChanged
  | setOpenNotValidAddressModal
  | setOpenSelectStorePopupDelivery
  | setSelectedDineInTable
  | setCheckSelectedAddress
  | setSelectedAddressLoading
  | setValidStores
  | setValidDeliveryAreas
  | setSelectedPreorderDate
  | setPreorderResponse
  | SetFixedPointsAddresses
  | clearDineInOrderStartTimestamp
  | clearState;
