import { Action } from "@ngrx/store";

export const SET_ORDER_COUPON_RULES =
  "[COUPON RULES ORDER] Set Order Coupon Rules";
export const CLEAR_ORDER_COUPON_RULES =
  "[COUPON RULES ORDER] Clear Order Coupon Rules";

export class SetOrderCouponRules implements Action {
  readonly type = SET_ORDER_COUPON_RULES;
  constructor(public payload: any) {}
}

export class clearOrderCouponRules implements Action {
  readonly type = CLEAR_ORDER_COUPON_RULES;
  constructor() {}
}

export type OrderCouponRulesActions =
  | SetOrderCouponRules
  | clearOrderCouponRules;
