import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged } from "rxjs/operators";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { PaymentGatewaysService } from "../services/payment-gateways/payment-gateways.service";
import * as fromApp from "../store/app.reducer";
import { SelectPaymentGatewayTokenizationComponent } from "./modals/select-payment-gateway-tokenization/select-payment-gateway-tokenization.component";
import { SelectStoreComponent } from "./modals/select-store/select-store.component";
import * as AuthActions from "../auth/store/auth.actions";
import { Router } from "@angular/router";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { AnimationOptions } from "ngx-lottie";
import { SelectStorePopupComponent } from "../popups/select-store-popup/select-store-popup.component";
@Component({
  selector: "app-payment-cards",
  templateUrl: "./payment-cards.page.html",
  styleUrls: ["./payment-cards.page.scss"],
})
export class PaymentCardsPage implements OnInit, OnDestroy {
  public currentUser: any;
  public loginLoading: any;
  public storesByStoreId: any;
  public windowDimensions: any;
  public selectedLangugage: any;
  public groupApp: any;

  private mo_token: any;
  private subscriptions: Subscription[] = [];
  private stores: any;
  private paymentGatewayStoresByID: any;
  private paymentGatewayStores: any;
  private paymentGateways: any;
  private allStoresPaymentGateways: any;
  private selectStoreModal: any;
  private selectPaymentForTokenizationModal: any;
  private unsubscribeBackEvent: Subscription;
  AnimationNoCardsOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/no-cards-animation.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private translateService: TranslateService,
    private paymentGatewayService: PaymentGatewaysService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private dataStorageService: DataStorageService,
    private router: Router,
    private analyticsService: AnalyticsServiceService,
    private platform: Platform
  ) {}

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.paymentGatewayService.billingInfoVerifyModalIsOpen()) {
          this.paymentGatewayService.closeBillingInfoVerifyModal();
        } else {
          this.navigateToHome();
        }
      }
    );
    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("paymentCards");
    this.analyticsService.tikTokPixelPageView("paymentCards");

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (
            state &&
            state.loginLoading &&
            !_.isEqual(this.loginLoading, state.loginLoading)
          ) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
            if (this.loginLoading === "not-loading" && _.isEmpty(state.user)) {
              this.presentErrorToast(
                this.translateService.instant(
                  "payment-cards.you-must-be-logged-in-to-see-payment-cards"
                ),
                "danger"
              );
              this.router.navigateByUrl("/homepage", {
                replaceUrl: true,
              });
            }
          }
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (this.stores) {
              this.storesByStoreId = _.groupBy(this.stores, "store_id");

              _.each(this.storesByStoreId, (store, key, list) => {
                this.storesByStoreId[key] = store[0];
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("paymentGateWays")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.allStoresPaymentGateways &&
            !_.isEqual(
              state.allStoresPaymentGateways,
              this.allStoresPaymentGateways
            )
          ) {
            this.allStoresPaymentGateways = _.cloneDeep(
              state.allStoresPaymentGateways
            );
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(state.selectedLangugage, this.selectedLangugage)
          ) {
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }

          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );
  }

  ngOnInit() {}

  trackCards(index, item) {
    return index;
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  async deleteCardAlert(index) {
    const alert = await this.alertCtrl.create({
      header: this.translateService.instant("payment-cards.attention!"),
      message: this.translateService.instant(
        "payment-cards.do-you-want-to-delete-your-card"
      ),
      buttons: [
        {
          text: this.translateService.instant("payment-cards.cancel"),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Canceled");
          },
        },
        {
          text: "OK",
          handler: () => {
            console.log("Confirm Okay");
            this.currentUser.cards.splice(index, 1);

            let obj = { updatedData: this.currentUser };
            this.updateCustomerData(obj, this.mo_token);
          },
        },
      ],
    });

    await alert.present();
  }

  updateCustomerData(obj, mo_token) {
    if (obj && mo_token) {
      this.dataStorageService

        .updateCustomerData(obj, mo_token)

        .subscribe({
          next: (res: any) => {
            if (res && res.success) {
              let updatedUser = res.data;
              console.log("writing user to state");
              this.store.dispatch(
                new AuthActions.SetUser(_.cloneDeep(updatedUser))
              );
              if (res.comment_id) {
                this.presentErrorToast(res.comment_id, "primary");
              }
            } else if (res && !res.success) {
              if (res.comment_id) {
                this.presentErrorToast(
                  this.translateService.instant(res.comment_id),
                  "danger"
                );
              } else if (res.comments) {
                this.presentErrorToast(res.comments, "danger");
              }
            } else {
              let errMsg = this.translateService.instant(
                "errorMessages.error: There was an error on saving"
              );
              this.presentErrorToast(errMsg, "danger");
            }
          },
          error: (err) => {
            let errMsg = this.translateService.instant(
              "errorMessages.error: There was an error on saving"
            );
            this.presentErrorToast(errMsg, "danger");
            return err;
          },
        });
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: this.translateService.instant(message),
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  selectStoreToSaveCard(store_id) {
    this.checkPaymentGatewaysPopup(store_id);
  }

  saveCard() {
    console.log("started save card");
    //First I need to check if there is only one store, so I will show the paymentGateways at once.
    if (this.stores && this.stores.length === 1) {
      this.checkPaymentGatewaysPopup(null);

      console.log(
        "presenting the modal for adding card without selecting store"
      );
    } else if (this.checkForDifferentPaymentGatewaysBetweenStores()) {
      //Then if there are different payment gateways between the stores, I need to make the user select the store.
      console.log("this.paymentGatewayStores", this.paymentGatewayStores);
      this.paymentGatewayStoresByID = _.keys(
        _.groupBy(this.paymentGatewayStores, "store_id")
      );
      console.log("paymentGatewayStoresByID", this.paymentGatewayStoresByID);

      if (this.paymentGatewayStoresByID.length > 1) {
        this.presentSelectStoreModal();
        console.log("select the store");
      } else {
        console.log(
          "This is the case that there are many stores but only one has enabled the payment method"
        );
        this.checkPaymentGatewaysPopup(null);
      }
    } else {
      this.checkPaymentGatewaysPopup(null);
    }
  }

  checkForDifferentPaymentGatewaysBetweenStores() {
    console.log(
      "checkForDifferentPaymentGatewaysBetweenStores this.stores",
      this.stores
    );

    this.paymentGatewayStores = [];

    _.each(this.stores, (store, key, list) => {
      _.each(this.allStoresPaymentGateways[store.store_id], (gateway, k, l) => {
        console.log(store.store_id, k, gateway);

        if (
          this.paymentGatewayService.fetchUniquePaymentGatewayAttr(
            store.store_id,
            k
          ).value &&
          gateway.active &&
          gateway.tokenization
        ) {
          this.paymentGatewayStores.push(
            _.extend(gateway, {
              uniqueKey:
                this.paymentGatewayService.fetchUniquePaymentGatewayAttr(
                  store.store_id,
                  k
                ).key,
              store_id: store.store_id,
              id: k,
            })
          );
        }
      });
    });

    console.log("payment Gateways stores", this.paymentGatewayStores);

    var paymentGatewaysById = _.groupBy(this.paymentGatewayStores, "id");
    console.log("paymentGatewaysById", paymentGatewaysById);

    return !!_.find(_.keys(paymentGatewaysById), (payment_id) => {
      console.log(
        "uniqueKey",
        payment_id,
        paymentGatewaysById[payment_id][0].uniqueKey
      );

      if (
        (paymentGatewaysById &&
          paymentGatewaysById[payment_id] &&
          paymentGatewaysById[payment_id].length !== this.stores.length) ||
        _.keys(
          _.groupBy(
            paymentGatewaysById[payment_id],
            paymentGatewaysById[payment_id][0].uniqueKey
          )
        ).length > 1
      ) {
        console.log(
          "checkForDifferentPaymentGatewaysBetweenStores TRUE",
          payment_id
        );
        return true;
      } else {
        console.log(
          "checkForDifferentPaymentGatewaysBetweenStores FALSE",
          payment_id
        );
        return false;
      }
    });
  }

  fetchPaymentGatewayIdValue(method) {
    if (method.id === "viva") {
      return method.publicKey;
    } else if (method.id === "piraeus") {
      return method.PosId;
    } else if (method.id === "ethniki" || method.id === "nbg_simplify") {
      return method.mid;
    } else if (method.id === "mypos") {
      return method.mid;
    } else if (method.id === "alpha") {
      return method.mid;
    } else if (method.id === "eurobank") {
      return method.mid;
    } else if (method.id === "paypal") {
      return "paypal";
    } else if (method.id === "nexi"){
      return method.mid
    }
      else {
      return null;
    }
  }

  checkPaymentGatewaysPopup(store_id) {
    //Here I am going to filter the same payment gateways between the stores so that I will have single payment gateways
    console.log("checkPaymentGatewaysPopup");
    this.paymentGateways = [];

    _.each(this.stores, (store, key, list) => {
      if (store_id && store.store_id !== store_id) {
        console.log("store", store.store_name, store.store_id, store_id);
        return;
      }

      _.each(store.paymentGateways, (gateway, k, l) => {
        if (
          this.paymentGatewayService.fetchUniquePaymentGatewayAttr(
            store.store_id,
            k
          ).value &&
          gateway.active &&
          !gateway.disable_payment_gateway && //i put this only for this filter gateways algorithm because i want to display the payment cards page but not giving the ability to access it!
          k !== "viva" &&
          gateway.tokenization &&
          !_.find(this.paymentGateways, (meth) => {
            return (
              this.fetchPaymentGatewayIdValue(meth) ===
              this.paymentGatewayService.fetchUniquePaymentGatewayAttr(
                store.store_id,
                k
              ).value
            );
          })
        ) {
          // console.log("before push", k);
          this.paymentGateways.push(
            _.extend(gateway, {
              store_id: store.store_id,
              id: k,
            })
          );
        }
      });
    });

    console.log("this.paymentGateways from cards", this.paymentGateways);
    //I need to select a payment gateway for tokenization or select a store if there are two different paymentGateways one for each store
    console.log("paymentGateways from cards", this.paymentGateways);
    if (this.paymentGateways.length > 1) {
      //I need to check if there are multiple paymentGateways for the specific store
      console.log("");
      this.PresentselectPaymentGatewayForTokenizationSaveCard(store_id);
    } else if (this.paymentGateways.length > 0) {
      this.paymentGatewayService.selectedPaymentGateway(
        1,
        this.paymentGateways[0],
        "saveCard",
        null,
        null,
        store_id,
        null,
        null,
        null,
        null,
        null,
        null
      );
    } else {
      console.log("support-card-save");
      let message = this.translateService.instant(
        "payment-cards.there-is-not-a-payment-gateway-support-card-save"
      );
      this.presentAlert(message);
    }
  }

  async openSelectStoreModal(storesForSelection) {
    return new Promise(async (resolve, reject) => {
      let cssClass = "selectStorePopupCss";

      if (
        storesForSelection &&
        storesForSelection.length > 1 &&
        storesForSelection.length <= 3
      ) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (storesForSelection && storesForSelection.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }

      const modal = await this.modalCtrl.create({
        component: SelectStorePopupComponent,
        componentProps: {
          storesForSelection: storesForSelection,
          onlyReturnSelectedStoreId: true,
          forSaveCard: true,
        },
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await modal.present();

      await modal.onDidDismiss().then((data) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  async presentSelectStoreModal() {
    let allStores = _.cloneDeep(this.stores);
    _.each(this.paymentGatewayStoresByID, (store_id) => {
      const foundStore = _.find(allStores, { store_id: store_id });
      if (foundStore && foundStore.store_id) {
        foundStore.supported = true;
      }
    });

    const selectedStoreId = await this.openSelectStoreModal(allStores);

    if (selectedStoreId) {
      this.selectStoreToSaveCard(selectedStoreId);
    }
  }

  async presentAlert(message) {
    const alert = await this.alertCtrl.create({
      message: message,
      buttons: ["OK"],
    });

    await alert.present();
  }

  async PresentselectPaymentGatewayForTokenizationSaveCard(store_id) {
    this.selectPaymentForTokenizationModal = await this.modalCtrl.create({
      component: SelectPaymentGatewayTokenizationComponent,
      cssClass: "selectPaymentGatewayForTokenizationPopup",
      backdropDismiss: false,
      componentProps: {
        storesByStoreId: _.cloneDeep(this.storesByStoreId),
        paymentGateways: _.cloneDeep(this.paymentGateways),
        selectedStoreId: _.cloneDeep(store_id),
      },
    });

    await this.selectPaymentForTokenizationModal.present();
    this.selectPaymentForTokenizationModal.onDidDismiss().then((data) => {
      this.selectPaymentForTokenizationModal = null;
      //When you select a store calling selectStoreToSaveCard in order to checkPaymentGatewaysPopup
      if (data && data.data) {
        this.selectStoreToSaveCard(data.data);
      }
    });
  }

  ngOnDestroy() {}

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    this.paymentGatewayService.closeAllModals();
    if (this.selectStoreModal) {
      this.selectStoreModal.dismiss();
      this.selectStoreModal = null;
    }
    if (this.selectPaymentForTokenizationModal) {
      this.selectPaymentForTokenizationModal.dismiss();
      this.selectPaymentForTokenizationModal = null;
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
