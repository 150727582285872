<ion-header [hidden]="displayOnlySpinner">
  <ion-toolbar mode="md">
    <ion-title size="small">
      <ion-text>
        {{ "popups.dine-in-modal.title" | translate }}
      </ion-text>
    </ion-title>
    <ion-button
      (click)="closeModal()"
      size="small"
      slot="end"
      fill="clear"
      color="dark"
    >
      <ion-icon slot="icon-only" src="assets/ionicons/close.svg"></ion-icon>
    </ion-button>
  </ion-toolbar>
</ion-header>

<ion-content [hidden]="displayOnlySpinner">
  <ion-grid>
    <ion-list *ngIf="!fetchTablesLoading" class="ion-no-padding">
      <ion-item
        button="true"
        detail="true"
        (click)="onStoreSelection($event)"
        *ngIf="stores && stores.length && stores.length > 1"
      >
        <ion-label>
          <ion-text *ngIf="!selectedStoreId">
            {{ "popups.dine-in-modal.select-store" | translate }}
          </ion-text>

          <ion-text *ngIf="selectedStoreId">
            {{
              selectedStore.store_name_mobileorder &&
              selectedStore.store_name_mobileorder[selectedLangugage]
                ? selectedStore.store_name_mobileorder[selectedLangugage]
                : selectedStore.store_name_langs &&
                  selectedStore.store_name_langs[selectedLangugage]
                ? selectedStore.store_name_langs[selectedLangugage]
                : selectedStore.store_name_langs &&
                  selectedStore.store_name_langs.el
                ? selectedStore.store_name_langs.el
                : selectedStore.store_name
            }}
          </ion-text>
        </ion-label>
        <!-- <ion-select
          interface="popover"
          [class]="!selectedStoreId ? 'selectPlaceHolderDanger' : ''"
          okText="{{ 'select' | translate }}"
          cancelText="{{ 'cancel' | translate }}"
          (ionChange)="changeStore($event)"
          placeholder="{{ 'please-select' | translate }}"
          [disabled]="true"
          [(ngModel)]="selectedStoreId"
        >
          <ion-select-option
            [value]="store.store_id"
            *ngFor="let store of stores"
          >
            <ion-text *ngIf="store.store_name && selectedLangugage">
              {{
                store.store_name_mobileorder &&
                store.store_name_mobileorder[selectedLangugage]
                  ? store.store_name_mobileorder[selectedLangugage]
                  : store.store_panel_name_langs &&
                    store.store_panel_name_langs[selectedLangugage]
                  ? store.store_panel_name_langs[selectedLangugage]
                  : store.store_panel_name_langs &&
                    store.store_panel_name_langs.el
                  ? store.store_panel_name_langs.el
                  : store.store_name_panel
                  ? store.store_name_panel
                  : store.store_name
                  ? store.store_name
                  : ""
              }}
            </ion-text>
            <p>{{ store.address }}</p>
          </ion-select-option>
        </ion-select> -->
      </ion-item>
      <ion-item
        *ngIf="
          selectedStore &&
          !selectedStore.dine_in_disable_table_selection &&
          selectedStore.dinein &&
          tables &&
          tables.length
        "
      >
        <ion-label>
          {{ "popups.dine-in-modal.select-table" | translate }}
        </ion-label>
        <ion-select
          [class]="!selectedTableId ? 'selectPlaceHolderDanger' : ''"
          okText="{{ 'select' | translate }}"
          cancelText="{{ 'cancel' | translate }}"
          (ionChange)="selectingTable($event)"
          placeholder="{{ 'please-select' | translate }}"
          [(ngModel)]="selectedTableId"
        >
          <ion-select-option
            [value]="table.table_id"
            *ngFor="let table of tables"
            >{{ table.name }}</ion-select-option
          >
        </ion-select>
      </ion-item>

      <ion-item
        lines="full"
        *ngIf="
          selectedStore &&
          selectedStore.dine_in_disable_table_selection &&
          selectedTableId
        "
      >
        <ion-label *ngIf="selectedDineInTable" class="ion-text-wrap">
          <strong> {{ "popups.dine-in-modal.table" | translate }}:</strong>
          {{ selectedDineInTable.name }}
        </ion-label>
        <ion-label
          *ngIf="!selectedDineInTable && selectedTableFromLinkObject"
          class="ion-text-wrap"
        >
          <strong> {{ "popups.dine-in-modal.table" | translate }}:</strong>
          {{ selectedTableFromLinkObject.name }}
        </ion-label>
      </ion-item>
      <ion-item
        lines="none"
        *ngIf="
          selectedStore &&
          selectedStore.dine_in_disable_table_selection &&
          !selectedTableId
        "
      >
        <ion-label color="danger" class="ion-text-wrap ion-text-center">
          <strong>
            {{ "popups.dine-in-modal.table-not-found" | translate }}</strong
          >
        </ion-label>
      </ion-item>

      <ion-item
        lines="none"
        *ngIf="
          selectedStore &&
          !selectedStore.dine_in_disable_table_selection &&
          selectedStoreId &&
          (!selectedStore.dinein || (tables && !tables.length))
        "
      >
        <ion-label
          class="ion-text-wrap ion-text-center"
          style="line-height: 1.2"
        >
          <ion-text color="danger" style="font-weight: bold; font-size: small">
            {{
              "popups.dine-in-modal.dinein-is-disabled-in-this-store"
                | translate
            }}
          </ion-text>
        </ion-label>
      </ion-item>
    </ion-list>

    <ion-row *ngIf="fetchTablesLoading" style="width: 100%">
      <ion-col class="ion-text-center">
        <ion-spinner> </ion-spinner>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>

<ion-footer [hidden]="displayOnlySpinner">
  <ion-grid>
    <ion-row style="width: 100%">
      <ion-col class="ion-text-center ion-no-padding ion-no-margin">
        <ion-button
          [disabled]="
            !selectedStore ||
            (stores && stores.length === 1 && !selectedStore.dinein) ||
            !selectedTableId ||
            (stores &&
              stores.length &&
              stores.length > 1 &&
              (!selectedStoreId || !selectedStore.dinein))
          "
          (click)="clickOkButton()"
        >
          {{ "popups.dine-in-modal.continue" | translate }}</ion-button
        >
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-footer>

<ion-grid style="width: 100%; height: 100%" *ngIf="displayOnlySpinner">
  <ion-row
    class="ion-align-items-center ion-justify-content-center"
    style="width: 100%; height: 100%"
  >
    <ion-col class="ion-align-self-center ion-text-center">
      <ion-spinner> </ion-spinner>
    </ion-col>
  </ion-row>
</ion-grid>
