import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
  retry,
  tap,
} from "rxjs/operators";
import { Router } from "@angular/router";
import { DataStorageService } from "../data-storage/data-storage.service";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import { AlertController, ModalController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { NotValidAddressModalComponent } from "src/app/address/not-valid-address-modal/not-valid-address-modal.component";
import * as currentStoreActions from "../../store/current-store/current-store.actions";
import { SelectStorePopupComponent } from "src/app/popups/select-store-popup/select-store-popup.component";
import * as manuallyTriggeredActions from "../../store/manuallyTriggerFinalPriceCheckAddress/manuallyTriggerFinalPrice.actions";
import { AnalyticsServiceService } from "../analitycsService/analytics-service.service";
import { SelectStoreImagePopupComponent } from "src/app/popups/select-store-image-popup/select-store-image-popup.component";
import * as AuthActions from "../../auth/store/auth.actions";

@Injectable({
  providedIn: "root",
})
export class SelectedAddressService {
  public orderPickup: any;
  public dineIn: any;
  public selectedAddressForCheck: any;
  public selectedAddress: any;
  private currentStoreId: any;
  private openSelectStorePopupDelivery: any;
  private triggerCheckAddressTimestamp: any = null;
  private notValidAddressModal: any;
  private selectStoreModal: any;
  private navigationOnDeliveryMethodChange: any;
  private updated_address_from_check_selected_address = null;
  private loginState;
  private waitingLoginState = false;
  private waitingLoginStateManuallyTrigger = false;
  constructor(
    private http: HttpClient,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private modalController: ModalController,
    private analyticsService: AnalyticsServiceService
  ) {}

  checkToSelectAddressSubs() {
    this.store
      .select("auth")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (state && !_.isEqual(state.loginState, this.loginState)) {
          this.loginState = _.cloneDeep(state.loginState);
          if (
            this.loginState &&
            this.loginState !== "login_loading" &&
            this.waitingLoginState
          ) {
            this.waitingLoginState = false;
            this.selectedAddressCheck(false);
          }
          if (
            this.loginState &&
            this.loginState !== "login_loading" &&
            this.waitingLoginStateManuallyTrigger
          ) {
            this.waitingLoginStateManuallyTrigger = false;
            this.selectedAddressCheck(true);
          }
        }
      });

    this.store
      .select("manuallyTriggerFinalPriceCheckAddress")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.triggerCheckAddressTimestamp &&
          !_.isEqual(
            this.triggerCheckAddressTimestamp,
            state.triggerCheckAddressTimestamp
          )
        ) {
          this.triggerCheckAddressTimestamp = _.cloneDeep(
            state.triggerCheckAddressTimestamp
          );
          if (this.selectedAddress) {
            this.selectedAddressForCheck = _.cloneDeep(this.selectedAddress);
            this.store.dispatch(
              new DeliveryMethodAddressActions.setCheckSelectedAddress(
                this.selectedAddressForCheck
              )
            );
            if (this.loginState && this.loginState !== "login_loading") {
              this.selectedAddressCheck(true);
            } else {
              this.waitingLoginStateManuallyTrigger = true;
            }
          }
        }
      });

    this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(state.currentStoreId, this.currentStoreId)
        ) {
          this.currentStoreId = _.cloneDeep(state.currentStoreId);
        }
      });
    this.store
      .select("deliveryMethodAddress")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && !_.isEqual(this.orderPickup, state.orderPickup)) {
          this.orderPickup = _.cloneDeep(state.orderPickup);
        }
        if (state && !_.isEqual(this.dineIn, state.dineIn)) {
          this.dineIn = _.cloneDeep(state.dineIn);
        }
        if (state && !_.isEqual(this.selectedAddress, state.selectedAddress)) {
          this.selectedAddress = _.cloneDeep(state.selectedAddress);
        }
        if (
          state &&
          !_.isEqual(this.selectedAddressForCheck, state.checkSelectedAddress)
        ) {
          this.selectedAddressForCheck = _.cloneDeep(
            state.checkSelectedAddress
          );

          if (!state.selectedAddressLoading && this.selectedAddressForCheck) {
            if (this.loginState && this.loginState !== "login_loading") {
              this.selectedAddressCheck(false);
            } else {
              this.waitingLoginState = true;
            }
          }
        }
        if (
          state &&
          !_.isEqual(
            state.navigationOnDeliveryMethodChange,
            this.navigationOnDeliveryMethodChange
          )
        ) {
          this.navigationOnDeliveryMethodChange = _.cloneDeep(
            state.navigationOnDeliveryMethodChange
          );
        }
      });
  }

  selectedAddressCheckActions(manuallyTriggered, groupAppState) {
    if (groupAppState && groupAppState.selectedLangugage) {
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedAddressLoading(true)
      );

      if (manuallyTriggered) {
        this.store.dispatch(
          new manuallyTriggeredActions.SetCheckAddresssLoading(true)
        );
        this.store.dispatch(
          new manuallyTriggeredActions.SetCheckAddressSuccess(false)
        );
      }
      this.checkFormattedAddress(manuallyTriggered, groupAppState);
    }
  }

  selectedAddressCheck(manuallyTriggered) {
    //console.log("selected address check current url", this.router.url);

    this.store
      .select("auth")
      .pipe(first())
      .subscribe((authState) => {
        this.store
          .select("groupApp")
          .pipe(first())
          .subscribe((groupAppState) => {
            if (
              groupAppState &&
              groupAppState.groupApp &&
              groupAppState.groupApp.vatBasedCustomerAddressesActive
            ) {
              if (authState && authState.user) {
                const currentUser = _.cloneDeep(authState.user);

                const addressFound = _.find(currentUser.addresses, {
                  address_id: this.selectedAddressForCheck.address_id,
                  migration_customer_id: authState.migration_customer_id,
                });
                console.log("address found", addressFound);

                if (!addressFound) {
                  this.clearDeliveryMethod();
                } else {
                  this.selectedAddressCheckActions(
                    manuallyTriggered,
                    groupAppState
                  );
                }
              }
            } else {
              this.selectedAddressCheckActions(
                manuallyTriggered,
                groupAppState
              );
            }
          });
      });
  }

  clearDeliveryMethod() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidStores(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenNotValidAddressModal(false)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(false)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(null)
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.dineIn = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.selectedDineInTable = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  async presentSelectStoreModal(validStores) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe(async (state) => {
        if (
          state &&
          state.groupApp &&
          state.groupApp.images_select_store_mobileorder
        ) {
          this.selectStoreModal = await this.modalController.create({
            component: SelectStoreImagePopupComponent,
            cssClass: "selectStoreImagePopup",
            backdropDismiss: true,
            componentProps: { showValidStores: true },
          });
        } else {
          this.selectStoreModal = await this.modalController.create({
            component: SelectStorePopupComponent,
            cssClass: "selectStoreInPaymentCardsPopup",
            backdropDismiss: false,
            componentProps: { showValidStores: true },
          });
        }

        await this.selectStoreModal.present();
        this.selectStoreModal.onDidDismiss().then(async (data) => {
          this.selectStoreModal = null;
          //When you select a store calling selectStoreToSaveCard in order to checkPaymentGatewaysPopup
          if (data && data.data && data.data.selectedStoreId) {
            this.store
              .select("cart")
              .pipe(first())
              .subscribe(async (cart) => {
                if (cart && cart.products && cart.products.length > 0) {
                  // console.log("cart Products length", cart);
                  if (
                    this.currentStoreId &&
                    !_.isEqual(this.currentStoreId, data.data.selectedStoreId)
                  ) {
                    const alert = await this.alertController.create({
                      cssClass: "my-custom-class",
                      header: this.translateService.instant("alert"),
                      backdropDismiss: false,
                      message: this.translateService.instant(
                        "delivery-method-address.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
                      ),
                      buttons: [
                        {
                          text: this.translateService.instant("cancel"),
                          role: "cancel",
                          cssClass: "secondary",
                          handler: () => {
                            if (!this.selectedAddress) {
                              if (navigator.cookieEnabled) {
                                let deliveryMethodAddress =
                                  localStorage.getItem("deliveryMethodAddress");
                                if (deliveryMethodAddress) {
                                  localStorage.removeItem(
                                    "deliveryMethodAddress"
                                  );
                                }
                              }
                              this.store.dispatch(
                                new DeliveryMethodAddressActions.setDineIn(null)
                              );
                              this.store.dispatch(
                                new DeliveryMethodAddressActions.setOrderPickup(
                                  null
                                )
                              );

                              this.store.dispatch(
                                new DeliveryMethodAddressActions.setSelectedAddress(
                                  _.cloneDeep(null)
                                )
                              );
                              this.store.dispatch(
                                new DeliveryMethodAddressActions.setValidStores(
                                  _.cloneDeep(null)
                                )
                              );
                              this.store.dispatch(
                                new DeliveryMethodAddressActions.setValidDeliveryAreas(
                                  _.cloneDeep(null)
                                )
                              );
                              this.store.dispatch(
                                new DeliveryMethodAddressActions.setOpenNotValidAddressModal(
                                  false
                                )
                              );
                              this.store.dispatch(
                                new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
                                  false
                                )
                              );
                              this.openSelectStorePopupDelivery = "again";
                            }
                            this.selectedAddressForCheck = "again";

                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setSelectedAddressLoading(
                                false
                              )
                            );
                            this.store.dispatch(
                              new manuallyTriggeredActions.SetCheckAddresssLoading(
                                false
                              )
                            );
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setCheckSelectedAddress(
                                null
                              )
                            );
                            this.openSelectStorePopupDelivery = "again";

                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
                                false
                              )
                            );
                          },
                        },
                        {
                          text: this.translateService.instant("yes"),
                          handler: () => {
                            this.selectStoreModalSetAddress(data);
                          },
                        },
                      ],
                    });
                    await alert.present();
                  } else if (
                    this.currentStoreId &&
                    _.isEqual(this.currentStoreId, data.data.selectedStoreId)
                  ) {
                    this.selectStoreModalSetAddress(data);
                  }
                } else if (
                  (cart && !cart.products) ||
                  (cart.products && cart.products.length === 0)
                ) {
                  this.selectStoreModalSetAddress(data);
                }
              })
              .unsubscribe();
          } else {
            this.store.dispatch(
              new DeliveryMethodAddressActions.setDineIn(null)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setOrderPickup(null)
            );

            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedAddressLoading(false)
            );
            this.store.dispatch(
              new manuallyTriggeredActions.SetCheckAddresssLoading(false)
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setSelectedAddress(
                _.cloneDeep(null)
              )
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setValidStores(_.cloneDeep(null))
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setValidDeliveryAreas(
                _.cloneDeep(null)
              )
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setOpenNotValidAddressModal(
                false
              )
            );
            this.store.dispatch(
              new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
                false
              )
            );
            if (navigator.cookieEnabled) {
              let deliveryMethodAddress = localStorage.getItem(
                "deliveryMethodAddress"
              );
              if (deliveryMethodAddress) {
                localStorage.removeItem("deliveryMethodAddress");
              }
            }
            this.openSelectStorePopupDelivery = "again";
          }
        });
      })
      .unsubscribe();
  }

  selectStoreModalSetAddress(data) {
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((authState) => {
        console.log(
          "set store id selected address service",
          data.data.selectedStoreId
        );
        this.store.dispatch(
          new currentStoreActions.SetCurrentStoreId(
            _.cloneDeep(data.data.selectedStoreId)
          )
        );

        this.store.dispatch(
          new DeliveryMethodAddressActions.setOrderPickup(false)
        );
        this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(false));
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedDineInTable(
            _.cloneDeep(null)
          )
        );
        this.analyticsService.dmAnalyticsMessage(
          "delivery_method",
          null,
          null,
          null,
          null,
          null,
          null,
          this.selectedAddressForCheck,
          null,
          null,
          null
        );
        if (navigator.cookieEnabled) {
          let deliveryMethodAddress = JSON.parse(
            window.localStorage.getItem("deliveryMethodAddress")
          );

          if (deliveryMethodAddress) {
            deliveryMethodAddress.orderPickup = false;
            deliveryMethodAddress.dineIn = false;
            deliveryMethodAddress.selectedDineInTable = null;
          } else {
            deliveryMethodAddress = {
              orderPickup: false,
              dineIn: false,
            };
          }
          window.localStorage.setItem(
            "deliveryMethodAddress",
            JSON.stringify(deliveryMethodAddress)
          );
        }
        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedAddressLoading(false)
        );
        this.store.dispatch(
          new manuallyTriggeredActions.SetCheckAddresssLoading(false)
        );
        let selectedAddress = _.cloneDeep(this.selectedAddressForCheck);
        if (authState && authState.user && authState.user.addresses) {
          const findAddress = _.find(_.cloneDeep(authState.user.addresses), {
            address_id: this.selectedAddressForCheck.address_id,
          });
          if (
            findAddress &&
            !_.isEqual(findAddress, this.selectedAddressForCheck)
          ) {
            selectedAddress = _.cloneDeep(findAddress);
          }
        }

        if (
          this.updated_address_from_check_selected_address &&
          selectedAddress.address_id ===
            this.updated_address_from_check_selected_address.address_id &&
          !_.isEqual(
            this.updated_address_from_check_selected_address,
            selectedAddress
          )
        ) {
          selectedAddress = _.assign(
            selectedAddress,
            _.cloneDeep(this.updated_address_from_check_selected_address)
          );
        }

        this.store.dispatch(
          new DeliveryMethodAddressActions.setSelectedAddress(
            _.cloneDeep(selectedAddress)
          )
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setCheckSelectedAddress(
            _.cloneDeep(null)
          )
        );
        if (navigator.cookieEnabled) {
          if (window.localStorage.getItem("deliveryMethodAddress")) {
            let deliveryMethodAddress = JSON.parse(
              window.localStorage.getItem("deliveryMethodAddress")
            );
            deliveryMethodAddress.selectedAddress = selectedAddress;
            window.localStorage.setItem(
              "deliveryMethodAddress",
              JSON.stringify(deliveryMethodAddress)
            );
          } else {
            let deliveryMethodAddress = {
              orderPickup: this.orderPickup,
              dineIn: this.dineIn,
              selectedAddress: selectedAddress,
            };
            window.localStorage.setItem(
              "deliveryMethodAddress",
              JSON.stringify(deliveryMethodAddress)
            );
          }
          if (
            (this.router.url === "/homepage" ||
              this.router.url === "/ratings" ||
              this.router.url === "/orders" ||
              this.router.url === "/points") &&
            this.navigationOnDeliveryMethodChange
          ) {
            this.router.navigateByUrl("/catalog", {
              replaceUrl: true,
            });
          }
        }
        this.selectedAddressForCheck = null;
        this.openSelectStorePopupDelivery = "again";
        this.store.dispatch(
          new DeliveryMethodAddressActions.setOpenNotValidAddressModal(false)
        );
        this.store.dispatch(
          new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
            false
          )
        );
      })
      .unsubscribe();
  }

  async presentNotValidAddressModal(notValidAddress) {
    this.notValidAddressModal = await this.modalController.create({
      component: NotValidAddressModalComponent,
      cssClass: "my-custom-class",
      componentProps: {
        notValidAddress: _.cloneDeep(notValidAddress),
      },
      backdropDismiss: false,
    });
    await this.notValidAddressModal.present();
    this.notValidAddressModal.onDidDismiss().then((data) => {
      this.notValidAddressModal = null;
    });
  }

  public deselectAddress() {
    if (navigator.cookieEnabled) {
      if (window.localStorage.getItem("deliveryMethodAddress")) {
        let deliveryMethodAddress = JSON.parse(
          window.localStorage.getItem("deliveryMethodAddress")
        );
        deliveryMethodAddress.selectedAddress = null;
        deliveryMethodAddress.orderPickup = null;
        deliveryMethodAddress.dineIn = null;
        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddress)
        );
      }
    }
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));

    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddressLoading(false)
    );
    this.store.dispatch(
      new manuallyTriggeredActions.SetCheckAddresssLoading(false)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setCheckSelectedAddress(null)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidStores(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenNotValidAddressModal(false)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(false)
    );
  }

  public closeAddressModals() {
    if (this.notValidAddressModal) {
      this.notValidAddressModal.dismiss();
      this.notValidAddressModal = null;
    }

    if (this.selectStoreModal) {
      this.selectStoreModal.dismiss();
      this.selectStoreModal = null;
    }
  }

  checkFormattedAddress(manuallyTriggered, groupState) {
    this.store
      .select("auth")
      .pipe(first())
      .subscribe((authState) => {
        this.updated_address_from_check_selected_address = null;
        this.dataStorageService
          .checkFormattedAddress(
            groupState.groupApp.group,
            this.selectedAddressForCheck.userLat,
            this.selectedAddressForCheck.userLng,
            groupState.selectedLangugage,
            authState && authState.user ? authState.user.customer_id : null,
            this.selectedAddressForCheck.address_id
          )

          .subscribe({
            next: async (res: any) => {
              if (!res || !res.success) {
                if (manuallyTriggered) {
                  this.store.dispatch(
                    new manuallyTriggeredActions.SetCheckAddressSuccess(false)
                  );
                }
                const alert = await this.alertController.create({
                  header: this.translateService.instant("alert"),
                  message:
                    res && res.comment_id
                      ? this.translateService.instant(res.comment_id)
                      : this.translateService.instant(
                          "errorMessages.general_error"
                        ),

                  buttons: ["OK"],
                });
                await alert.present();

                this.store.dispatch(
                  new DeliveryMethodAddressActions.setSelectedAddressLoading(
                    false
                  )
                );
                this.store.dispatch(
                  new manuallyTriggeredActions.SetCheckAddresssLoading(false)
                );
                this.store.dispatch(
                  new DeliveryMethodAddressActions.setCheckSelectedAddress(
                    _.cloneDeep(null)
                  )
                );
              } else {
                if (
                  res &&
                  res.updated_customer &&
                  !_.isEmpty(res.updated_customer)
                ) {
                  this.store.dispatch(
                    new AuthActions.SetUser(_.cloneDeep(res.updated_customer))
                  );
                }

                if (
                  res &&
                  res.updated_address &&
                  !_.isEmpty(res.updated_address)
                ) {
                  this.updated_address_from_check_selected_address =
                    res.updated_address;
                }

                let validStores = res.validStores;
                let validDeliveryAreas = res.deliveryAreas;
                if (!validStores || _.isEmpty(validStores)) {
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setOrderPickup(null)
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setDineIn(null)
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setSelectedAddressLoading(
                      false
                    )
                  );
                  this.store.dispatch(
                    new manuallyTriggeredActions.SetCheckAddresssLoading(false)
                  );

                  if (
                    navigator.cookieEnabled &&
                    window.localStorage.getItem("deliveryMethodAddress")
                  ) {
                    let deliveryMethodAddress = JSON.parse(
                      window.localStorage.getItem("deliveryMethodAddress")
                    );
                    //console.log("dddddd", deliveryMethodAddress);
                    if (
                      deliveryMethodAddress &&
                      deliveryMethodAddress.selectedAddress
                    ) {
                      //console.log("remove item called");
                      window.localStorage.removeItem("deliveryMethodAddress");
                    }
                  }
                  this.store
                    .select("deliveryMethodAddress")
                    .pipe(first())
                    .subscribe((state) => {
                      if (state && state.openNotValidAddressModal) {
                        const address = _.cloneDeep(
                          this.selectedAddressForCheck
                        );
                        setTimeout(() => {
                          this.presentNotValidAddressModal(address);
                        }, 200);

                        this.store.dispatch(
                          new DeliveryMethodAddressActions.setCheckSelectedAddress(
                            null
                          )
                        );
                        this.store.dispatch(
                          new DeliveryMethodAddressActions.setSelectedAddress(
                            null
                          )
                        );
                      }
                    })
                    .unsubscribe();
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setCheckSelectedAddress(
                      null
                    )
                  );
                  this.store.dispatch(
                    new DeliveryMethodAddressActions.setSelectedAddress(null)
                  );
                } else {
                  if (validStores && validStores.length === 1) {
                    if (
                      this.currentStoreId &&
                      _.isEqual(validStores[0].store_id, this.currentStoreId)
                    ) {
                      console.log(
                        "set store id selected address service",
                        validStores[0].store_id
                      );
                      this.store.dispatch(
                        new currentStoreActions.SetCurrentStoreId(
                          _.cloneDeep(validStores[0].store_id)
                        )
                      );

                      this.store.dispatch(
                        new DeliveryMethodAddressActions.setValidStores(
                          _.cloneDeep(validStores)
                        )
                      );
                      this.store.dispatch(
                        new DeliveryMethodAddressActions.setValidDeliveryAreas(
                          _.cloneDeep(validDeliveryAreas)
                        )
                      );
                      this.store.dispatch(
                        new DeliveryMethodAddressActions.setOrderPickup(false)
                      );
                      //console.log("set order pickup false");
                      this.store.dispatch(
                        new DeliveryMethodAddressActions.setDineIn(false)
                      );
                      this.store.dispatch(
                        new DeliveryMethodAddressActions.setSelectedDineInTable(
                          _.cloneDeep(null)
                        )
                      );
                      this.analyticsService.dmAnalyticsMessage(
                        "delivery_method",
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        this.selectedAddressForCheck,
                        null,
                        null,
                        null
                      );
                      if (navigator.cookieEnabled) {
                        let deliveryMethodAddress = JSON.parse(
                          window.localStorage.getItem("deliveryMethodAddress")
                        );

                        if (deliveryMethodAddress) {
                          deliveryMethodAddress.orderPickup = false;
                          deliveryMethodAddress.dineIn = false;
                          deliveryMethodAddress.selectedDineInTable = null;
                        } else {
                          deliveryMethodAddress = {
                            orderPickup: false,
                            dineIn: false,
                          };
                        }
                        window.localStorage.setItem(
                          "deliveryMethodAddress",
                          JSON.stringify(deliveryMethodAddress)
                        );
                      }
                      this.store.dispatch(
                        new DeliveryMethodAddressActions.setSelectedAddressLoading(
                          false
                        )
                      );
                      this.store.dispatch(
                        new manuallyTriggeredActions.SetCheckAddresssLoading(
                          false
                        )
                      );
                      let selectedAdd = _.cloneDeep(
                        this.selectedAddressForCheck
                      );
                      if (
                        res &&
                        res.updated_customer &&
                        !_.isEmpty(res.updated_customer)
                      ) {
                        const updatedAddress = _.find(
                          res.updated_customer.addresses,
                          { address_id: selectedAdd.address_id }
                        );

                        if (updatedAddress) {
                          selectedAdd = _.cloneDeep(updatedAddress);
                        }
                      }

                      if (
                        res &&
                        res.updated_address &&
                        !_.isEmpty(res.updated_address)
                      ) {
                        if (
                          !_.isEqual(selectedAdd, res.updated_address) &&
                          selectedAdd.address_id ===
                            res.updated_address.address_id
                        ) {
                          selectedAdd = _.assign(
                            selectedAdd,
                            _.cloneDeep(res.updated_address)
                          );
                        }
                      }
                      this.store.dispatch(
                        new DeliveryMethodAddressActions.setSelectedAddress(
                          _.cloneDeep(selectedAdd)
                        )
                      );
                      if (
                        (this.router.url === "/homepage" ||
                          this.router.url === "/ratings" ||
                          this.router.url === "/orders" ||
                          this.router.url === "/points") &&
                        this.navigationOnDeliveryMethodChange
                      ) {
                        this.router.navigate(["catalog"]);
                      }
                      this.store.dispatch(
                        new DeliveryMethodAddressActions.setCheckSelectedAddress(
                          _.cloneDeep(null)
                        )
                      );
                      if (navigator.cookieEnabled) {
                        if (
                          window.localStorage.getItem("deliveryMethodAddress")
                        ) {
                          let deliveryMethodAddress = JSON.parse(
                            window.localStorage.getItem("deliveryMethodAddress")
                          );
                          deliveryMethodAddress.selectedAddress = selectedAdd;
                          window.localStorage.setItem(
                            "deliveryMethodAddress",
                            JSON.stringify(deliveryMethodAddress)
                          );
                        } else {
                          let deliveryMethodAddress = {
                            orderPickup: this.orderPickup,
                            dineIn: this.dineIn,
                            selectedAddress: selectedAdd,
                          };
                          window.localStorage.setItem(
                            "deliveryMethodAddress",
                            JSON.stringify(deliveryMethodAddress)
                          );
                        }
                      }
                      this.selectedAddressForCheck = null;
                    } else if (
                      this.currentStoreId &&
                      !_.isEqual(validStores[0].store_id, this.currentStoreId)
                    ) {
                      //console.log("here");
                      this.store
                        .select("cart")
                        .pipe(first())
                        .subscribe(async (state) => {
                          if (
                            state &&
                            state.products &&
                            state.products.length > 0
                          ) {
                            const alert = await this.alertController.create({
                              cssClass: "my-custom-class",
                              header: this.translateService.instant("alert"),
                              backdropDismiss: false,
                              message: this.translateService.instant(
                                "delivery-method-address.if-you-change-store-your-cart-products-will-be-deleted-do-you-want-to-continue"
                              ),
                              buttons: [
                                {
                                  text: this.translateService.instant("cancel"),
                                  role: "cancel",
                                  cssClass: "secondary",
                                  handler: () => {
                                    if (!this.selectedAddress) {
                                      if (navigator.cookieEnabled) {
                                        window.localStorage.removeItem(
                                          "deliveryMethodAddress"
                                        );
                                      }
                                      this.store.dispatch(
                                        new DeliveryMethodAddressActions.setDineIn(
                                          null
                                        )
                                      );
                                      this.store.dispatch(
                                        new DeliveryMethodAddressActions.setOrderPickup(
                                          null
                                        )
                                      );

                                      this.store.dispatch(
                                        new DeliveryMethodAddressActions.setSelectedAddress(
                                          _.cloneDeep(null)
                                        )
                                      );
                                      this.store.dispatch(
                                        new DeliveryMethodAddressActions.setValidStores(
                                          _.cloneDeep(null)
                                        )
                                      );
                                      this.store.dispatch(
                                        new DeliveryMethodAddressActions.setValidDeliveryAreas(
                                          _.cloneDeep(null)
                                        )
                                      );
                                      this.store.dispatch(
                                        new DeliveryMethodAddressActions.setOpenNotValidAddressModal(
                                          false
                                        )
                                      );
                                      this.store.dispatch(
                                        new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(
                                          false
                                        )
                                      );
                                      this.openSelectStorePopupDelivery =
                                        "again";
                                    }
                                    this.selectedAddressForCheck = "again";
                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setSelectedAddressLoading(
                                        false
                                      )
                                    );
                                    this.store.dispatch(
                                      new manuallyTriggeredActions.SetCheckAddresssLoading(
                                        false
                                      )
                                    );
                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setCheckSelectedAddress(
                                        null
                                      )
                                    );
                                  },
                                },

                                {
                                  text: this.translateService.instant("yes"),
                                  handler: () => {
                                    console.log(
                                      "set store id selected address service",
                                      validStores[0].store_id
                                    );
                                    this.store.dispatch(
                                      new currentStoreActions.SetCurrentStoreId(
                                        _.cloneDeep(validStores[0].store_id)
                                      )
                                    );

                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setValidStores(
                                        _.cloneDeep(validStores)
                                      )
                                    );
                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setValidDeliveryAreas(
                                        _.cloneDeep(validDeliveryAreas)
                                      )
                                    );
                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setOrderPickup(
                                        false
                                      )
                                    );
                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setDineIn(
                                        false
                                      )
                                    );
                                    this.analyticsService.dmAnalyticsMessage(
                                      "delivery_method",
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null,
                                      null
                                    );
                                    if (navigator.cookieEnabled) {
                                      let deliveryMethodAddressObj: any =
                                        localStorage.getItem(
                                          "deliveryMethodAddress"
                                        );
                                      if (deliveryMethodAddressObj) {
                                        deliveryMethodAddressObj = JSON.parse(
                                          deliveryMethodAddressObj
                                        );
                                        if (deliveryMethodAddressObj) {
                                          deliveryMethodAddressObj.orderPickup =
                                            false;
                                          deliveryMethodAddressObj.dineIn =
                                            false;
                                        } else {
                                          deliveryMethodAddressObj = {
                                            orderPickup: false,
                                            dineIn: false,
                                          };
                                        }
                                      } else {
                                        deliveryMethodAddressObj = {
                                          orderPickup: false,
                                          dineIn: false,
                                        };
                                      }

                                      window.localStorage.setItem(
                                        "deliveryMethodAddress",
                                        JSON.stringify(deliveryMethodAddressObj)
                                      );
                                    }
                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setSelectedAddressLoading(
                                        false
                                      )
                                    );
                                    this.store.dispatch(
                                      new manuallyTriggeredActions.SetCheckAddresssLoading(
                                        false
                                      )
                                    );
                                    let selectedAdd = _.cloneDeep(
                                      this.selectedAddressForCheck
                                    );
                                    if (
                                      res &&
                                      res.updated_customer &&
                                      !_.isEmpty(res.updated_customer)
                                    ) {
                                      const updatedAddress = _.find(
                                        res.updated_customer.addresses,
                                        { address_id: selectedAdd.address_id }
                                      );

                                      if (updatedAddress) {
                                        selectedAdd =
                                          _.cloneDeep(updatedAddress);
                                      }
                                    }

                                    if (
                                      res &&
                                      res.updated_address &&
                                      !_.isEmpty(res.updated_address)
                                    ) {
                                      if (
                                        !_.isEqual(
                                          selectedAdd,
                                          res.updated_address
                                        ) &&
                                        selectedAdd.address_id ===
                                          res.updated_address.address_id
                                      ) {
                                        selectedAdd = _.assign(
                                          selectedAdd,
                                          _.cloneDeep(res.updated_address)
                                        );
                                      }
                                    }
                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setSelectedAddress(
                                        _.cloneDeep(selectedAdd)
                                      )
                                    );
                                    if (
                                      (this.router.url === "/homepage" ||
                                        this.router.url === "/ratings" ||
                                        this.router.url === "/orders" ||
                                        this.router.url === "/points") &&
                                      this.navigationOnDeliveryMethodChange
                                    ) {
                                      this.router.navigateByUrl("/catalog", {
                                        replaceUrl: true,
                                      });
                                    }
                                    this.store.dispatch(
                                      new DeliveryMethodAddressActions.setCheckSelectedAddress(
                                        _.cloneDeep(null)
                                      )
                                    );
                                    if (navigator.cookieEnabled) {
                                      if (
                                        window.localStorage.getItem(
                                          "deliveryMethodAddress"
                                        )
                                      ) {
                                        let deliveryMethodAddress = JSON.parse(
                                          window.localStorage.getItem(
                                            "deliveryMethodAddress"
                                          )
                                        );
                                        deliveryMethodAddress.selectedAddress =
                                          selectedAdd;
                                        window.localStorage.setItem(
                                          "deliveryMethodAddress",
                                          JSON.stringify(deliveryMethodAddress)
                                        );
                                      } else {
                                        let deliveryMethodAddress = {
                                          orderPickup: this.orderPickup,
                                          dineIn: this.dineIn,
                                          selectedAddress: selectedAdd,
                                        };
                                        window.localStorage.setItem(
                                          "deliveryMethodAddress",
                                          JSON.stringify(deliveryMethodAddress)
                                        );
                                      }
                                    }
                                    this.selectedAddressForCheck = null;
                                  },
                                },
                              ],
                            });
                            await alert.present();
                          } else if (
                            (state &&
                              state.products &&
                              state.products.length === 0) ||
                            (state && !state.products)
                          ) {
                            console.log(
                              "set store id selected address service",
                              validStores[0].store_id
                            );
                            this.store.dispatch(
                              new currentStoreActions.SetCurrentStoreId(
                                _.cloneDeep(validStores[0].store_id)
                              )
                            );

                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setValidStores(
                                _.cloneDeep(validStores)
                              )
                            );
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setValidDeliveryAreas(
                                _.cloneDeep(validDeliveryAreas)
                              )
                            );
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setOrderPickup(
                                false
                              )
                            );
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setDineIn(false)
                            );
                            this.analyticsService.dmAnalyticsMessage(
                              "delivery_method",
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              this.selectedAddressForCheck,
                              null,
                              null,
                              null
                            );
                            if (navigator.cookieEnabled) {
                              let deliveryMethodAddressObj: any =
                                localStorage.getItem("deliveryMethodAddress");
                              if (deliveryMethodAddressObj) {
                                deliveryMethodAddressObj = JSON.parse(
                                  deliveryMethodAddressObj
                                );
                                if (deliveryMethodAddressObj) {
                                  deliveryMethodAddressObj.orderPickup = false;
                                  deliveryMethodAddressObj.dineIn = false;
                                } else {
                                  deliveryMethodAddressObj = {
                                    orderPickup: false,
                                    dineIn: false,
                                  };
                                }
                              } else {
                                deliveryMethodAddressObj = {
                                  orderPickup: false,
                                  dineIn: false,
                                };
                              }

                              window.localStorage.setItem(
                                "deliveryMethodAddress",
                                JSON.stringify(deliveryMethodAddressObj)
                              );
                            }
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setSelectedAddressLoading(
                                false
                              )
                            );
                            this.store.dispatch(
                              new manuallyTriggeredActions.SetCheckAddresssLoading(
                                false
                              )
                            );
                            let selectedAdd = _.cloneDeep(
                              this.selectedAddressForCheck
                            );

                            if (
                              res &&
                              res.updated_customer &&
                              !_.isEmpty(res.updated_customer)
                            ) {
                              const updatedAddress = _.find(
                                res.updated_customer.addresses,
                                { address_id: selectedAdd.address_id }
                              );

                              if (updatedAddress) {
                                selectedAdd = _.cloneDeep(updatedAddress);
                              }
                            }

                            if (
                              res &&
                              res.updated_address &&
                              !_.isEmpty(res.updated_address)
                            ) {
                              if (
                                !_.isEqual(selectedAdd, res.updated_address) &&
                                selectedAdd.address_id ===
                                  res.updated_address.address_id
                              ) {
                                selectedAdd = _.cloneDeep(res.updated_address);
                              }
                            }

                            if (
                              res &&
                              res.updated_address &&
                              !_.isEmpty(res.updated_address)
                            ) {
                              if (
                                !_.isEqual(selectedAdd, res.updated_address) &&
                                selectedAdd.address_id ===
                                  res.updated_address.address_id
                              ) {
                                selectedAdd = _.assign(
                                  selectedAdd,
                                  _.cloneDeep(res.updated_address)
                                );
                              }
                            }
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setSelectedAddress(
                                _.cloneDeep(selectedAdd)
                              )
                            );
                            if (
                              (this.router.url === "/homepage" ||
                                this.router.url === "/ratings" ||
                                this.router.url === "/orders" ||
                                this.router.url === "/points") &&
                              this.navigationOnDeliveryMethodChange
                            ) {
                              this.router.navigateByUrl("/catalog", {
                                replaceUrl: true,
                              });
                            }
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setCheckSelectedAddress(
                                _.cloneDeep(null)
                              )
                            );
                            if (navigator.cookieEnabled) {
                              if (
                                window.localStorage.getItem(
                                  "deliveryMethodAddress"
                                )
                              ) {
                                let deliveryMethodAddress = JSON.parse(
                                  window.localStorage.getItem(
                                    "deliveryMethodAddress"
                                  )
                                );
                                deliveryMethodAddress.selectedAddress =
                                  selectedAdd;
                                window.localStorage.setItem(
                                  "deliveryMethodAddress",
                                  JSON.stringify(deliveryMethodAddress)
                                );
                              } else {
                                let deliveryMethodAddress = {
                                  orderPickup: this.orderPickup,
                                  dineIn: this.dineIn,
                                  selectedAddress: selectedAdd,
                                };
                                window.localStorage.setItem(
                                  "deliveryMethodAddress",
                                  JSON.stringify(deliveryMethodAddress)
                                );
                              }
                            }
                            this.selectedAddressForCheck = null;
                          }
                        })
                        .unsubscribe();
                    }
                  } else if (validStores && validStores.length > 1) {
                    this.store.dispatch(
                      new DeliveryMethodAddressActions.setValidStores(
                        _.cloneDeep(validStores)
                      )
                    );
                    this.store.dispatch(
                      new DeliveryMethodAddressActions.setValidDeliveryAreas(
                        _.cloneDeep(validDeliveryAreas)
                      )
                    );
                    this.store
                      .select("deliveryMethodAddress")
                      .pipe(first())
                      .subscribe((state) => {
                        if (
                          state &&
                          !_.isEqual(
                            state.openSelectStorePopupDelivery,
                            this.openSelectStorePopupDelivery
                          )
                        ) {
                          this.openSelectStorePopupDelivery = _.cloneDeep(
                            state.openSelectStorePopupDelivery
                          );
                          if (this.openSelectStorePopupDelivery) {
                            // console.log("present select store modal");

                            this.presentSelectStoreModal(validStores);
                          } else if (!this.openSelectStorePopupDelivery) {
                            //console.log("present select store modal else");

                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setOrderPickup(
                                false
                              )
                            );
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setDineIn(false)
                            );
                            this.analyticsService.dmAnalyticsMessage(
                              "delivery_method",
                              null,
                              null,
                              null,
                              null,
                              null,
                              null,
                              this.selectedAddressForCheck,
                              null,
                              null,
                              null
                            );
                            if (navigator.cookieEnabled) {
                              let deliveryMethodAddress = JSON.parse(
                                window.localStorage.getItem(
                                  "deliveryMethodAddress"
                                )
                              );

                              if (deliveryMethodAddress) {
                                deliveryMethodAddress.orderPickup = false;
                                deliveryMethodAddress.dineIn = false;
                              } else {
                                deliveryMethodAddress = {
                                  orderPickup: false,
                                  dineIn: false,
                                };
                              }
                              window.localStorage.setItem(
                                "deliveryMethodAddress",
                                JSON.stringify(deliveryMethodAddress)
                              );
                            }

                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setSelectedAddressLoading(
                                false
                              )
                            );
                            this.store.dispatch(
                              new manuallyTriggeredActions.SetCheckAddresssLoading(
                                false
                              )
                            );
                            let selectedAdd = _.cloneDeep(
                              this.selectedAddressForCheck
                            );
                            if (
                              res &&
                              res.updated_customer &&
                              !_.isEmpty(res.updated_customer)
                            ) {
                              const updatedAddress = _.find(
                                res.updated_customer.addresses,
                                { address_id: selectedAdd.address_id }
                              );

                              if (updatedAddress) {
                                selectedAdd = _.cloneDeep(updatedAddress);
                              }
                            }

                            if (
                              res &&
                              res.updated_address &&
                              !_.isEmpty(res.updated_address)
                            ) {
                              if (
                                !_.isEqual(selectedAdd, res.updated_address) &&
                                selectedAdd.address_id ===
                                  res.updated_address.address_id
                              ) {
                                selectedAdd = _.assign(
                                  selectedAdd,
                                  _.cloneDeep(res.updated_address)
                                );
                              }
                            }

                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setSelectedAddress(
                                _.cloneDeep(selectedAdd)
                              )
                            );
                            if (
                              (this.router.url === "/homepage" ||
                                this.router.url === "/ratings" ||
                                this.router.url === "/orders" ||
                                this.router.url === "/points") &&
                              this.navigationOnDeliveryMethodChange
                            ) {
                              this.router.navigateByUrl("/catalog", {
                                replaceUrl: true,
                              });
                            }
                            this.store.dispatch(
                              new DeliveryMethodAddressActions.setCheckSelectedAddress(
                                _.cloneDeep(null)
                              )
                            );
                            if (navigator.cookieEnabled) {
                              if (
                                window.localStorage.getItem(
                                  "deliveryMethodAddress"
                                )
                              ) {
                                let deliveryMethodAddress = JSON.parse(
                                  window.localStorage.getItem(
                                    "deliveryMethodAddress"
                                  )
                                );
                                deliveryMethodAddress.selectedAddress =
                                  selectedAdd;
                                window.localStorage.setItem(
                                  "deliveryMethodAddress",
                                  JSON.stringify(deliveryMethodAddress)
                                );
                              } else {
                                let deliveryMethodAddress = {
                                  orderPickup: this.orderPickup,
                                  dineIn: this.dineIn,
                                  selectedAddress: selectedAdd,
                                };
                                window.localStorage.setItem(
                                  "deliveryMethodAddress",
                                  JSON.stringify(deliveryMethodAddress)
                                );
                              }
                            }
                            this.selectedAddressForCheck = null;
                            this.openSelectStorePopupDelivery = "again";
                          }
                        }
                      })
                      .unsubscribe();
                  }
                }
                if (manuallyTriggered) {
                  this.store.dispatch(
                    new manuallyTriggeredActions.SetCheckAddressSuccess(true)
                  );
                }
              }
            },
            error: async (err) => {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message: this.translateService.instant(
                  "errorMessages.problem_reaching_server"
                ),

                buttons: ["OK"],
              });
              await alert.present();
              if (manuallyTriggered) {
                this.store.dispatch(
                  new manuallyTriggeredActions.SetCheckAddressSuccess(false)
                );
              }
              this.store.dispatch(
                new DeliveryMethodAddressActions.setSelectedAddressLoading(
                  false
                )
              );
              this.store.dispatch(
                new manuallyTriggeredActions.SetCheckAddresssLoading(false)
              );
              this.store.dispatch(
                new DeliveryMethodAddressActions.setCheckSelectedAddress(
                  _.cloneDeep(null)
                )
              );
              return err;
            },
          });
      })
      .unsubscribe();
  }
}
