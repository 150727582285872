import { ActionReducerMap } from "@ngrx/store";
import * as fromCatalog from "../catalog/store/catalog.reducer";
import * as fromVerticalCategory from "../catalog/categories/store/categoriesSelection.reducer";
import * as fromCart from "../cart/store/cart.reducer";
import * as fromGroupApp from "./group-app/groupApp.reducer";
import * as fromWindowInfo from "./window-info/windowInfo.reducer";
import * as fromStores from "./stores/stores.reducer";
import * as fromCurrentStore from "./current-store/current-store.reducer";
import * as fromSelectedPaymentMethodID from "../cart/cart-components/payment-methods/store/selectedPaymentMethod.reducer";
import * as fromDeliveryMethodAddress from "../store/deliveryMethodAddress/deliveryMethodAddress.reducer";
import * as fromCartCustomOptionsSelections from "../cart/cart-components/cart-custom-options/store/cart-custom-options-selections/cart-custom-options-selections.reducer";
import * as fromCartCustomerOptions from "../cart/cart-components/cart-custom-options/store/cart-customer-options/cart-customer-options.reducer";
import * as fromSelectedInvoice from "../cart/cart-components/invoice/store/selectedInvoice.reducer";
import * as fromSelectedProduct from "../product-details/store/selectedProduct.reducer";
import * as fromOptionStepper from "../product-details/options/option-stepper/store/optionStepper.reducer";
import * as fromPlatformInfo from "./platform-info/platform-info.reducer";
import * as fromProductOptionsValidaton from "../product-details/store/productOptionsValidation/produtOptionsValidation.reducer";
import * as fromDeveloperMode from "./developer-mode/developer-mode.reducer";
import * as fromPaymentGateWays from "./payment-gateways/payment-gateways.reducer";
import * as fromDisableFinalPrice from "./disable-final-price/disable-final-price.reducer";
import * as fromCheckboxPromotions from "../cart/cart-components/checkbox-promotions/store/checkbox-promotions.reducer";
import * as fromCartPrices from "../cart/cart-components/cart-prices/store/cart-prices.reducer";
import * as fromPoints from "../points/store/points.reducer";
import * as fromProductDetailsDimensions from "../product-details/store/modal-demensions/product-details-dimensions.reducer";
import * as fromAuth from "../auth/store/auth.reducer";
import * as fromOrders from "../orders/store/orders.reducer";
import * as fromAffiliate from "../affiliate/store/affiliate.reducer";
import * as fromCustomerCoupons from "../discount-coupons/store/coupons.reducer";
import * as fromReviews from "../ratings/store/reviews.reducer";
import * as fromCartActiveCoupons from "../cart/cart-components/cart-active-coupons/store/cartActiveCoupons.reducer";

import * as fromFirebaseRecaptcha from "../popups/firebase-recaptcha/store/firebaseRecaptcha.reducer";
import * as fromBranchLinkData from "./branch-link-data/branchLinkData.reducer";
import * as fromFavoriteProducts from "./favorite-products/favoriteProducts.reducer";
import * as fromCompleteOrderValidation from "./complete-order-validation/completeOrderValidation.reducer";
import * as fromHyperCategories from "./hyperCategories/hyperCategories.reducer";
import * as fromOrderComments from "../cart/cart-components/order-comments/store/orderComments.reducer";
import * as fromManuallyTriggerFinalPriceCheckAddressTimestamp from "./manuallyTriggerFinalPriceCheckAddress/manuallyTriggerFinalPrice.reducer";
import * as fromPreviousOrders from "../catalog/previous-order/store/previousOrders.reducer";
import * as fromRequestsErrorHandling from "./requestsErrorHandling/requestsErrorHandling.reducer";
import * as fromOneSignalMessages from "./oneSignalMessages/oneSignalMessages.reducer";
import * as fromPendingOrders from "./pending-orders/pendingOrders.reducer";
import * as fromLoyaltySystem from "../loyalty-system/store/loyalty-system.reducer";
import * as fromReservations from "../reservations/store/reservations.reducer";
import * as fromAppInitLoading from "../store/app-init-loading/appInitLoading.reducer";
import * as fromRecommendedProducts from "./recommended-products/recommended-products.reducer";
import * as fromCustomerPromotions from "./customerPromotions/customerPromotions.reducer";
import * as fromStaticPages from "./static-pages/staticPages.reducer";
import * as fromOrderCouponRules from "./order-coupon-rules/orderCouponRules.reducer";

export interface AppState {
  appInitLoading: fromAppInitLoading.State;
  auth: fromAuth.State;
  catalog: fromCatalog.State;
  hyperCategories: fromHyperCategories.State;
  verticalCategory: fromVerticalCategory.State;
  cart: fromCart.State;
  groupApp: fromGroupApp.State;
  windowInfo: fromWindowInfo.State;
  stores: fromStores.State;
  currentStore: fromCurrentStore.State;
  selectedPaymentMethodID: fromSelectedPaymentMethodID.State;
  deliveryMethodAddress: fromDeliveryMethodAddress.State;
  cartCustomerOptions: fromCartCustomerOptions.State;
  cartCustomOptionsSelections: fromCartCustomOptionsSelections.State;
  selectedInvoice: fromSelectedInvoice.State;
  selectedProduct: fromSelectedProduct.State;
  optionStepper: fromOptionStepper.State;
  platformInfo: fromPlatformInfo.State;
  productOptionsValidation: fromProductOptionsValidaton.State;
  developerMode: fromDeveloperMode.State;
  orders: fromOrders.State;
  loyaltySystem: fromLoyaltySystem.State;
  affiliate: fromAffiliate.State;
  pendingOrders: fromPendingOrders.State;
  previousOrders: fromPreviousOrders.State;
  orderComments: fromOrderComments.State;
  completeOrderValidation: fromCompleteOrderValidation.State;
  paymentGateWays: fromPaymentGateWays.State;
  disableFinalPrice: fromDisableFinalPrice.State;
  checkboxPromotions: fromCheckboxPromotions.State;
  customerPromotions: fromCustomerPromotions.State;
  cartPrices: fromCartPrices.State;
  points: fromPoints.State;
  productDetailsDimensions: fromProductDetailsDimensions.State;
  customerCoupons: fromCustomerCoupons.State;
  cartActiveCoupons: fromCartActiveCoupons.State;
  reviews: fromReviews.State;
  firebaseRecaptcha: fromFirebaseRecaptcha.State;
  branchLinkData: fromBranchLinkData.State;
  favoriteProducts: fromFavoriteProducts.State;
  manuallyTriggerFinalPriceCheckAddress: fromManuallyTriggerFinalPriceCheckAddressTimestamp.State;
  requestsErrorHandling: fromRequestsErrorHandling.State;
  oneSignalMessages: fromOneSignalMessages.State;
  reservations: fromReservations.State;
  recommendedProducts: fromRecommendedProducts.State;
  staticPages: fromStaticPages.State;
  orderCouponRules: fromOrderCouponRules.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  appInitLoading: fromAppInitLoading.appInitLoadingReducer,
  auth: fromAuth.authReducer,
  catalog: fromCatalog.catalogReducer,
  hyperCategories: fromHyperCategories.hyperCategoriesReducer,
  verticalCategory: fromVerticalCategory.verticalCategoryReducer,
  cart: fromCart.cartReducer,
  groupApp: fromGroupApp.groupAppReducer,
  windowInfo: fromWindowInfo.windowInfoReducer,
  stores: fromStores.storesReducer,
  currentStore: fromCurrentStore.currentStoreReducer,
  selectedPaymentMethodID:
    fromSelectedPaymentMethodID.selectedPaymentMethodIDReducer,
  paymentGateWays: fromPaymentGateWays.paymentGateWaysReducer,
  deliveryMethodAddress: fromDeliveryMethodAddress.deliveryMethodAddressReducer,
  cartCustomerOptions: fromCartCustomerOptions.cartCustomerOptionsReducer,
  cartCustomOptionsSelections:
    fromCartCustomOptionsSelections.cartCustomOptionsSelectionsReducer,
  selectedInvoice: fromSelectedInvoice.selectedInvoiceReducer,
  selectedProduct: fromSelectedProduct.selectedProductReducer,
  optionStepper: fromOptionStepper.optionStepperActions,
  platformInfo: fromPlatformInfo.platformInfoReducer,
  productOptionsValidation:
    fromProductOptionsValidaton.productOptionsValidationReducer,

  developerMode: fromDeveloperMode.developerModeReducer,
  orders: fromOrders.ordersReducer,
  loyaltySystem: fromLoyaltySystem.loyaltySystemReducer,
  affiliate: fromAffiliate.affiliateReducer,
  pendingOrders: fromPendingOrders.pendingOrdersReducer,
  orderComments: fromOrderComments.orderCommentsReducer,
  previousOrders: fromPreviousOrders.previousOrdersReducer,
  disableFinalPrice: fromDisableFinalPrice.disableFinalPriceReducer,
  checkboxPromotions: fromCheckboxPromotions.checkboxPromotionsReducer,
  customerPromotions: fromCustomerPromotions.customerPromotionsReducer,
  cartPrices: fromCartPrices.cartPricesReducer,
  points: fromPoints.pointsReducer,
  productDetailsDimensions:
    fromProductDetailsDimensions.productDetailsDimensionsReducer,

  customerCoupons: fromCustomerCoupons.customerCouponsReducer,
  reviews: fromReviews.customerCouponsReducer,
  cartActiveCoupons: fromCartActiveCoupons.customerCouponsReducer,
  firebaseRecaptcha: fromFirebaseRecaptcha.firebaseRecaptchaReducer,
  branchLinkData: fromBranchLinkData.branchLinkDataReducer,
  favoriteProducts: fromFavoriteProducts.favoriteProductsActions,
  completeOrderValidation:
    fromCompleteOrderValidation.completeOrderValidationReducer,
  manuallyTriggerFinalPriceCheckAddress:
    fromManuallyTriggerFinalPriceCheckAddressTimestamp.manuallyTriggerFinalPriceCheckAddressReducer,
  requestsErrorHandling: fromRequestsErrorHandling.requestsErrorHandlingReducer,
  oneSignalMessages: fromOneSignalMessages.oneSignalMessagesReduce,
  reservations: fromReservations.reservationsReducer,
  recommendedProducts: fromRecommendedProducts.recommendedProductsReducer,
  staticPages: fromStaticPages.requestsErrorHandlingReducer,
  orderCouponRules: fromOrderCouponRules.orderCouponRulesReducer,
};
