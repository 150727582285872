import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subscription } from "rxjs";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { InformationMessagesService } from "../services/informationMessages/information-messages.service";
import { PaymentGatewaysService } from "../services/payment-gateways/payment-gateways.service";
import * as fromApp from "../store/app.reducer";
import * as selectors from "../store/app.selectors";
import * as storesActions from "../store/stores/stores.actions";

@Component({
  selector: "app-store-schedule",
  templateUrl: "./store-schedule.page.html",
  styleUrls: ["./store-schedule.page.scss"],
})
export class StoreSchedulePage {
  @Input() reservationAppStore: any;
  public allSchedulesArray: any = null;
  public selectedStoreId: any;
  public selectedStore: any;
  public storeScheduleInfoMessages: any = {
    inline: null,
    alert: null,
    modal: null,
  };
  public selectedLangugage: any;
  public groupApp: any;
  public stores: any;
  public selectedLang: string;
  public mo_base: any;
  deliveryManagerPath = "https://images.deliverymanager.gr/";
  segment;
  atLeastTwoOrderTypes = false;
  private subscription: Subscription;
  private subscription2: Subscription;
  private subscription3: Subscription;
  private subscriptions4: Subscription;
  private subscriptions5: Subscription;
  public windowInfo: any;
  public menuMinWidthToShow: any;
  public storeInfo: any;
  public singleOrderType;
  private unsubscribeBackEvent: Subscription;
  public height: any;

  constructor(
    private store: Store<fromApp.AppState>,
    private paymentGatawaysService: PaymentGatewaysService,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private router: Router,
    private platform: Platform,
    private infoMessagesService: InformationMessagesService,
    private alertController: AlertController,
    public modalCtrl: ModalController
  ) {}

  ionViewDidEnter() {
    this.height = 20;
    // console.log("store Shed ON INIT");
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((groupState) => {
        if (
          groupState &&
          groupState.groupApp &&
          groupState.groupApp.active_reservation_app
        ) {
          this.unsubscribeBackEvent =
            this.platform.backButton.subscribeWithPriority(200, () => {
              this.navigateToHome();
            });

          if (this.reservationAppStore) {
            this.selectedStore = this.reservationAppStore;
          }
        } else {
          this.unsubscribeBackEvent =
            this.platform.backButton.subscribeWithPriority(100, () => {
              this.navigateToHome();
            });
        }
      })
      .unsubscribe();

    this.subscriptions5 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(state.groupApp, this.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          this.mo_base = _.cloneDeep(
            _.find(state.groupApp.languages, {
              mo_base: true,
            })
          );

          if (!this.mo_base) {
            this.mo_base = "en";
          } else {
            this.mo_base = this.mo_base.code;
          }
        }

        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(state.selectedLangugage, this.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });

    this.subscriptions4 = this.store
      .select("currentStore")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.currentStoreId &&
          !_.isEqual(this.selectedStoreId, state.currentStoreId)
        ) {
          this.selectedStoreId = _.cloneDeep(state.currentStoreId);
        }
      });

    this.subscription = this.store
      .select("stores")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(100))
      .subscribe((state) => {
        if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
          this.store
            .select(selectors.getStoreInfo)
            .pipe(first())
            .subscribe((currentStore) => {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupState) => {
                  if (
                    groupState &&
                    groupState.groupApp &&
                    groupState.groupApp.active_reservation_app
                  ) {
                    this.stores = [];
                    this.stores.push(
                      _.cloneDeep(this.reservationAppStore || currentStore)
                    );
                  } else {
                    this.stores = _.cloneDeep(state.stores);
                  }
                  if (this.stores && this.stores.length) {
                    this.checkStoreScheduleInfoMessagesThrottled(
                      _.cloneDeep(currentStore)
                    );
                  }

                  console.log("taking stores store shedule");
                  if (this.stores) {
                    if (groupState && groupState.selectedLangugage) {
                      this.selectedLang = _.cloneDeep(
                        groupState.selectedLangugage
                      );
                      console.log("selectedLang", this.selectedLang);
                      console.log("selected lang subscription");
                      this.transformAllStoreSchedules();
                      console.log(this.allSchedulesArray);
                    }
                  }
                })
                .unsubscribe();
            })
            .unsubscribe();
        }
      });

    this.subscription2 = this.store
      .select("windowInfo")
      .pipe(distinctUntilChanged())

      .subscribe((state) => {
        if (
          state &&
          state.windowDimensions &&
          !_.isEqual(this.windowInfo, state.windowDimensions)
        ) {
          this.windowInfo = _.cloneDeep(state.windowDimensions);
          this.menuMinWidthToShow = getComputedStyle(
            document.documentElement
          ).getPropertyValue("--menuMinWidthToShow");
          if (this.menuMinWidthToShow) {
            this.menuMinWidthToShow = parseInt(this.menuMinWidthToShow);
          }
          console.log("menu min with to show", this.menuMinWidthToShow);
        }
        console.log("window info", this.windowInfo);
      });

    this.subscription3 = this.store
      .select(selectors.getStoreInfo)
      .pipe(distinctUntilChanged())
      .subscribe((storeInfo) => {
        if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
          this.storeInfo = _.cloneDeep(storeInfo);
          this.checkSegmentsForDeliveryMethods(this.storeInfo);
        }
      });
  }

  // checkForMobileOrderInfoMessagesSchedulePage() {
  //   if (this.selectedStore && this.selectedStore.mobileorder_info_messages) {
  //     console.log(
  //       "chekc info message for schedule",
  //       this.selectedStore.mobileorder_info_messages
  //     );
  //     this.storeScheduleInfoMessages = [];
  //     _.each(this.selectedStore.mobileorder_info_messages, (message) => {
  //       if (
  //         message.page &&
  //         message.page.length &&
  //         _.includes(message.page, "storeSchedule") &&
  //         message.activePeriod &&
  //         message.activePeriod === "permanent"
  //       ) {
  //         this.storeScheduleInfoMessages.push(message);
  //       }
  //     });
  //     console.log("storeScheduleInfoMessages", this.storeScheduleInfoMessages);
  //   }
  // }

  changeSelectedSchedule(ev) {
    console.log("test", ev);
  }

  private checkStoreScheduleInfoMessagesThrottled = _.throttle(
    (selectedStore) => {
      this.checkStoreScheduleInfoMessages(selectedStore);
    },
    1000,
    { leading: true, trailing: false }
  );

  checkStoreScheduleInfoMessages(selectedStore) {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.groupApp) {
          console.log("selected Store", selectedStore);
          const storeScheduleInfoMessagesStore = _.cloneDeep(
            this.infoMessagesService.checkForMobileOrderInfoMessagesStore(
              _.cloneDeep(selectedStore),
              "storeSchedule"
            )
          );

          this.storeScheduleInfoMessages.alert = _.cloneDeep(
            _.filter(storeScheduleInfoMessagesStore, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "alert")
              ) {
                return infoMessage;
              }
            })
          );

          this.storeScheduleInfoMessages.inline = _.cloneDeep(
            _.filter(storeScheduleInfoMessagesStore, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "inline")
              ) {
                return infoMessage;
              }
            })
          );

          this.storeScheduleInfoMessages.modal = _.cloneDeep(
            _.filter(storeScheduleInfoMessagesStore, (infoMessage) => {
              if (
                infoMessage &&
                infoMessage.view_type &&
                _.includes(infoMessage.view_type, "modal")
              ) {
                return infoMessage;
              }
            })
          );

          console.log(
            "storeSchedule info messages",
            this.storeScheduleInfoMessages
          );

          let mo_base = _.cloneDeep(
            _.find(state.groupApp.languages, {
              mo_base: true,
            })
          );

          if (!mo_base) {
            mo_base = "en";
          }

          _.each(this.storeScheduleInfoMessages.alert, (infoMessage) => {
            this.showInfoMessageAlert(
              infoMessage,
              state.selectedLangugage,
              mo_base
            );
          });
        }
      });
  }

  showInfoMessageAlert(infoMessage, selectedLangugage, mo_base) {
    this.infoMessageAlert(infoMessage, selectedLangugage, mo_base);
  }

  async infoMessageAlert(infoMessage, selectedLangugage, mo_base) {
    let alert = await this.alertController.create({
      message:
        (infoMessage && infoMessage.title
          ? infoMessage.title[selectedLangugage]
            ? infoMessage.title[selectedLangugage]
            : infoMessage.title[mo_base]
            ? infoMessage.title[mo_base]
            : infoMessage.title["en"]
          : null) +
        (infoMessage && infoMessage.description
          ? infoMessage.description[selectedLangugage]
            ? infoMessage.description[selectedLangugage]
            : infoMessage.description[mo_base]
            ? infoMessage.description[mo_base]
            : infoMessage.description["en"]
          : null),
      cssClass: "info-message-alert",
      buttons: ["OK"],
    });

    if (
      infoMessage &&
      infoMessage.auto_dismiss &&
      infoMessage.auto_dismiss_seconds
    ) {
      setTimeout(() => {
        if (alert) {
          alert.dismiss();
        }
      }, infoMessage.auto_dismiss_seconds * 1000);
    }

    await alert.present();

    await alert.onDidDismiss().then(() => {
      alert = null;
    });
  }

  transformAllStoreSchedules() {
    this.allSchedulesArray = [];

    _.each(this.stores, (store, key, value) => {
      //Adding the store address and schedule to allSchedulesArray
      this.allSchedulesArray.push({
        store_id: this.stores[key].store_id,
        group: this.stores[key].group,
        store_name: this.stores[key].store_name,
        store_name_panel: this.stores[key].store_name_panel,
        store_panel_name_langs: this.stores[key].store_panel_name_langs,
        store_name_mobileorder: this.stores[key].store_name_mobileorder,
        address: this.stores[key].address,
        address_langs: this.stores[key].address_langs,
        telephoneArray: this.stores[key].telephone
          ? this.stores[key].telephone.split(",")
          : null,
        scheduleArray: this.formatStoreSchedule(this.stores[key].schedule),
        pauseStoreActive: this.stores[key].pauseStoreActive,
        pauseOnlineOrdersMsg: this.stores[key].pauseOnlineOrdersMsg,
        stopStoreActive: this.stores[key].stopStoreActive,
        disableOrderPickup: this.stores[key].disableOrderPickup,
        disableOrderDelivery: this.stores[key].disableOrderDelivery,
        pickupScheduleActive: this.stores[key].pickupScheduleActive,
        pickupScheduleArray: this.stores[key].pickupScheduleActive
          ? this.formatStoreSchedule(this.stores[key].pickupSchedule)
          : [],
        dinein: store.dinein,
        dineinScheduleActive: this.stores[key].dineinScheduleActive,
        dineinScheduleArray: this.stores[key].dineinScheduleActive
          ? this.formatStoreSchedule(this.stores[key].dineinSchedule)
          : [],

        stopOnlineOrdersMsg: this.stores[key].stopOnlineOrdersMsg,
        isExpanded: false,
      });
    });

    /** If allScheduleArray has only one element the expanded attribute is true (no need to toggle expanding) */
    if (this.allSchedulesArray.length === 1) {
      this.allSchedulesArray[0].isExpanded = true;
      this.allSchedulesArray[0].loading = true;
      this.dataStorageService
        .checkStoreOnline({
          languageCode: this.selectedLang,
          store_id: this.allSchedulesArray[0].store_id,
          group: this.allSchedulesArray[0].group,
        })
        .subscribe((res) => {
          if (res) {
            this.allSchedulesArray[0].storeStatus = res;
            this.allSchedulesArray[0].isExpanded = true;
            this.allSchedulesArray[0].loading = false;
          }
        });
    }

    if (this.selectedStoreId) {
      this.toggleStoreSchedule();
    }

    console.log("$rootScope.allSchedulesArray", this.allSchedulesArray);
  }

  formatStoreSchedule(schedule) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];

    var singleScheduleArray = [];

    _.each(schedule, (scheduleDay, key, list) => {
      if (scheduleDay.active && !scheduleDay.all_day) {
        var commentSchedule = "";
        scheduleDay.time_periods.forEach((tp, i) => {
          if (i > 0) {
            commentSchedule += ", ";
          }
          commentSchedule +=
            this.toHHMMSS(tp.start) + " - " + this.toHHMMSS(tp.stop);
        });

        singleScheduleArray.push({
          day: weekDays[key],
          schedule: commentSchedule,
        });
      } else if (scheduleDay.active && scheduleDay.all_day) {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: this.translateService.instant("store-schedule.24-hours"),
        });
      } else {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: this.translateService.instant("store-schedule.closed"),
        });
      }
    });

    var sunday = _.head(singleScheduleArray);
    singleScheduleArray = _.takeRight(singleScheduleArray, 6);
    singleScheduleArray.push(sunday);
    return singleScheduleArray;
  }

  toHHMMSS(val) {
    console.log("val", val);
    if (val) {
      let sec_num = parseInt(val, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let newhours = hours.toString();
      let newmins = minutes.toString();
      if (hours < 10) {
        newhours = "0" + hours;
      }
      if (minutes < 10) {
        newmins = "0" + minutes;
      }

      return newhours + ":" + newmins;
    } else {
      return "00:00";
    }
  }

  selectedStoreChangedFromSegment(store_id) {
    this.selectedStore = store_id;

    this.toggleStoreSchedule();
  }

  checkSegmentsForDeliveryMethods(selectedStore) {
    if (selectedStore && !selectedStore.disableOrderDelivery) {
      this.segment = "delivery";
    } else if (
      selectedStore &&
      selectedStore.disableOrderDelivery &&
      !selectedStore.disableOrderPickup
    ) {
      this.segment = "takeaway";
    } else if (!selectedStore.disableOrderPickup && selectedStore.dinein) {
      this.segment = "dinein";
    } else if (!selectedStore.disableOrderDelivery && selectedStore.dinein) {
      this.segment = "dinein";
    }

    if (
      (selectedStore.dinein && !selectedStore.disableOrderDelivery) ||
      (selectedStore.dinein && !selectedStore.disableOrderPickup) ||
      (!selectedStore.disableOrderDelivery && !selectedStore.disableOrderPickup)
    ) {
      this.atLeastTwoOrderTypes = true;
    } else {
      this.atLeastTwoOrderTypes = false;
    }

    if (
      selectedStore.dinein &&
      selectedStore.disableOrderDelivery &&
      selectedStore.disableOrderPickup
    ) {
      this.singleOrderType = "dinein";
    } else if (
      !selectedStore.dinein &&
      selectedStore.disableOrderDelivery &&
      !selectedStore.disableOrderPickup
    ) {
      this.singleOrderType = "takeaway";
    } else if (
      !selectedStore.dinein &&
      !selectedStore.disableOrderDelivery &&
      selectedStore.disableOrderPickup
    ) {
      this.singleOrderType = "delivery";
    }
  }

  toggleStoreSchedule() {
    if (this.allSchedulesArray.length === 1) {
      console.log("Single Store");
      return;
    }

    console.log("toggleStoreSchedule", this.selectedStore);

    this.selectedStore = _.find(this.stores, {
      store_id: this.selectedStoreId,
    });
    this.checkStoreScheduleInfoMessagesThrottled(this.selectedStore);

    this.checkSegmentsForDeliveryMethods(this.selectedStore);

    var selectedScheduleIndex = _.findIndex(this.allSchedulesArray, {
      store_id: this.selectedStoreId,
    });
    if (this.allSchedulesArray[selectedScheduleIndex].isExpanded) {
      this.allSchedulesArray[selectedScheduleIndex].isExpanded = false;
    } else {
      _.each(this.allSchedulesArray, (store, key, list) => {
        this.allSchedulesArray[key].isExpanded = false;
      });

      this.allSchedulesArray[selectedScheduleIndex].loading = true;
      this.dataStorageService
        .checkStoreOnline({
          languageCode: this.selectedLang,
          store_id: this.allSchedulesArray[selectedScheduleIndex].store_id,
          group: this.allSchedulesArray[selectedScheduleIndex].group,
        })
        .subscribe((res) => {
          if (res) {
            this.allSchedulesArray[selectedScheduleIndex].storeStatus = res;
            this.allSchedulesArray[selectedScheduleIndex].isExpanded = true;
            this.allSchedulesArray[selectedScheduleIndex].loading = false;
            this.store.dispatch(
              new storesActions.SetCheckStoreInfo(_.cloneDeep(res))
            );
          }
        });
    }
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription3) {
      this.subscription3.unsubscribe();
    }
    if (this.subscriptions4) {
      this.subscriptions4.unsubscribe();
    }
    if (this.subscriptions5) {
      this.subscriptions5.unsubscribe();
    }
  }
}
