import { Component, OnDestroy, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription, auditTime, distinctUntilChanged, first } from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as _ from "lodash";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import * as LoyaltySystemActions from "../store/loyalty-system.actions";

import { AccountInfoForContestModalComponent } from "../account-info-for-contest-modal/account-info-for-contest-modal.component";
import { AnalyticsServiceService } from "src/app/services/analitycsService/analytics-service.service";

@Component({
  selector: "app-loyalty-contests",
  templateUrl: "./loyalty-contests.page.html",
  styleUrls: ["./loyalty-contests.page.scss"],
})
export class LoyaltyContestsPage implements OnDestroy {
  public groupContests: any;
  public currentUser: any;
  public groupApp: any;
  public activeContestDraws: any;
  public activeContestDrawsSum: number = null;
  public noLoyaltyApp;
  public loginState;
  private activeContest;
  private unsubscribeBackEvent: Subscription;
  private groupAppSub: any;
  private sub: any;
  private authSub: Subscription;
  accountInfoAlert: HTMLIonAlertElement;
  currentDrawAcceptedTerms: any;
  customerDataSaveLoading: boolean;
  mo_token: any;
  errorMessage: string;

  constructor(
    private store: Store<fromApp.AppState>,
    private platform: Platform,
    private router: Router,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private analyticsService: AnalyticsServiceService,
    private toastController: ToastController,
    private alertController: AlertController,
    private modalController: ModalController
  ) {}

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        console.log("navitate to home from coupons page");
        if (!this.accountInfoAlert) {
          if (!this.noLoyaltyApp) {
            this.navigateToLoyaltyHome();
          } else {
            this.router.navigateByUrl("/homepage", {
              replaceUrl: true,
            });
          }
        }
      }
    );

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("contests");
    this.analyticsService.tikTokPixelPageView("contests");

    this.sub = this.store
      .select("loyaltySystem")
      .pipe(distinctUntilChanged())
      .pipe(auditTime(200))
      .subscribe((state) => {
        if (
          state &&
          state.groupContests &&
          !_.isEqual(this.groupContests, state.groupContests)
        ) {
          this.groupContests = _.sortBy(
            _.cloneDeep(state.groupContests),
            "status"
          );
        }
        if (
          state &&
          state.activeContestDraws &&
          !_.isEqual(this.activeContestDraws, state.activeContestDraws)
        ) {
          this.activeContestDraws = _.cloneDeep(state.activeContestDraws);
          console.log("activeContestDraws", this.activeContestDraws);
          this.activeContestDrawsSum = _.sumBy(
            _.filter(_.cloneDeep(this.activeContestDraws), (draw) => {
              if (draw.status === "active") {
                return draw;
              }
            }),
            "draws"
          );
        }
      });

    this.groupAppSub = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);
          if (this.groupApp && !this.groupApp.contestSystemEnabled) {
            this.router.navigateByUrl("/homepage", {
              replaceUrl: true,
            });
          }
        }
      });

    this.authSub = this.store
      .select("auth")
      .pipe(auditTime(200))
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (state && !_.isEqual(state.user, this.currentUser)) {
          this.currentUser = _.cloneDeep(state.user);

          this.store
            .select("groupApp")
            .pipe(first())
            .subscribe((groupState) => {
              if (
                this.currentUser &&
                (!this.currentUser.firstName.trim() ||
                  !this.currentUser.lastName.trim()) &&
                groupState &&
                groupState.groupApp &&
                groupState.groupApp.only_loyalty_app
              ) {
                if (!this.accountInfoAlert) {
                  this.askForNameAndPushNotifPermission();
                }
              }
            })
            .unsubscribe();
        }

        if (
          state &&
          state.mo_token &&
          !_.isEqual(state.mo_token, this.mo_token)
        ) {
          this.mo_token = _.cloneDeep(state.mo_token);
        }

        if (
          state &&
          state.loginState &&
          !_.isEqual(state.loginState, this.loginState)
        ) {
          this.loginState = _.cloneDeep(state.loginState);
          if (
            this.loginState &&
            this.loginState === "login_completed" &&
            this.router &&
            this.router.url &&
            !this.router.url.toString().includes("loyalty")
          ) {
            this.noLoyaltyApp = true;
            this.store
              .select("groupApp")
              .pipe(first())
              .subscribe((groupState) => {
                this.fetchGroupContests(
                  groupState.groupApp,
                  true,
                  state.mo_token
                );
              })
              .unsubscribe();
          } else if (this.loginState === "no_login") {
            this.store
              .select("groupApp")
              .pipe(first())
              .subscribe((groupState) => {
                if (groupState) {
                  if (
                    this.router &&
                    this.router.url &&
                    !this.router.url.toString().includes("loyalty")
                  ) {
                    this.noLoyaltyApp = true;
                    this.fetchGroupContests(groupState.groupApp, null, null);
                  }
                }
              })
              .unsubscribe();
          }
        }
      });
  }

  async openAccountInfoForContestModal() {
    const modal = await this.modalController.create({
      component: AccountInfoForContestModalComponent,
      backdropDismiss: false,
      breakpoints: [0, 0.25, 0.5, 0.75],
      initialBreakpoint: 0.5,
      componentProps: {
        currentUser: this.currentUser,
      },
    });

    await modal.present();
  }

  async askForNameAndPushNotifPermission() {
    this.accountInfoAlert = await this.alertController.create({
      header: "Συμμετοχή στην κλήρωση",
      message: "Είναι απαραίτητο να συμπληρώσετε το ονοματεπώνυμο σας",
      backdropDismiss: false,
      inputs: [],
      buttons: [
        {
          text: this.translateService.instant("continue"),
          handler: () => {
            this.openAccountInfoForContestModal();
            this.accountInfoAlert = null;
          },
        },
      ],
    });

    await this.accountInfoAlert.present();
  }

  navigateToLoyaltyHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/loyaltySystem/loyalty-home", {
        replaceUrl: true,
      });
    }
  }

  trackFnc(index, contest) {
    return contest.contest_id;
  }

  ngOnDestroy() {
    console.log("loyaly contest destroy");
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.noLoyaltyApp) {
      this.store.dispatch(new LoyaltySystemActions.SetGroupContests(null));

      this.store.dispatch(
        new LoyaltySystemActions.SetLatestContestWinners(null)
      );

      this.store.dispatch(
        new LoyaltySystemActions.SetActiveContestCustomerDraws(null)
      );
    }
    this.currentUser = null;
    this.accountInfoAlert = null;
  }

  ionViewDidLeave() {
    console.log("loyaly contest did leave");
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.groupAppSub) {
      this.groupAppSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }

    if (this.noLoyaltyApp) {
      this.store.dispatch(new LoyaltySystemActions.SetGroupContests(null));

      this.store.dispatch(
        new LoyaltySystemActions.SetLatestContestWinners(null)
      );

      this.store.dispatch(
        new LoyaltySystemActions.SetActiveContestCustomerDraws(null)
      );
    }
    this.currentUser = null;
    this.accountInfoAlert = null;
  }

  fetchGroupContests(groupApp, fetchDraws, mo_token) {
    const currentDate = Date.now();
    this.dataStorageService.fetchGroupContests(groupApp.group).subscribe({
      next: async (res: any) => {
        if (res && res.success) {
          console.log("fetchGroupContests", res);
          if (res.contests) {
            this.store.dispatch(
              new LoyaltySystemActions.SetGroupContests(
                _.cloneDeep(res.contests)
              )
            );
            this.activeContest = _.find(res.contests, (contest) => {
              return (
                currentDate >= contest.activeFrom &&
                currentDate < contest.activeTo
              );
            });
            console.log("active conterst", this.activeContest);
            if (this.activeContest && fetchDraws && mo_token) {
              this.fetchCustomerDraws(this.activeContest.contest_id, mo_token);
            }
          }
          if (res.latestContestWinners && res.latestContestWinners.length > 0) {
            this.store.dispatch(
              new LoyaltySystemActions.SetLatestContestWinners(
                _.cloneDeep(res.latestContestWinners)
              )
            );
          }
        } else {
          if (res.comment_id) {
            const alert = await this.alertController.create({
              header:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              message: this.translateService.instant("cant-load-contests"),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          } else if (res.comments) {
            const toast = await this.toastController.create({
              message: this.translateService.instant(res.comments),
              color: "danger",
              position: "middle",
              duration: 5000,
              buttons: [
                {
                  side: "end",
                  text: "OK",
                  role: "cancel",
                  handler: () => {
                    console.log("Cancel clicked");
                  },
                },
              ],
            });
            await toast.present();
          }
        }
      },
      error: async (err) => {
        const errMsg = this.translateService.instant(
          "errorMessages.error: There was an error on saving"
        );
        const toast = await this.toastController.create({
          message: errMsg,
          color: "danger",
          position: "middle",
          duration: 5000,
          buttons: [
            {
              side: "end",
              text: "OK",
              role: "cancel",
              handler: () => {
                console.log("Cancel clicked");
              },
            },
          ],
        });
        await toast.present();
        return err;
      },
    });
  }

  fetchCustomerDraws(activeContestId, mo_token) {
    this.dataStorageService
      .fetchCustomerDraws(_.cloneDeep(mo_token), activeContestId, null, null)
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            console.log("fetchCustomerDraws", res);
            if (res.customerDraws) {
              this.store.dispatch(
                new LoyaltySystemActions.SetActiveContestCustomerDraws(
                  _.cloneDeep(res.customerDraws)
                )
              );
            }
          } else {
            if (res.comment_id) {
              const alert = await this.alertController.create({
                header:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),

                backdropDismiss: false,
                buttons: ["OK"],
              });
              await alert.present();
            } else if (res.comments) {
              const toast = await this.toastController.create({
                message: this.translateService.instant(res.comments),
                color: "danger",
                position: "middle",
                duration: 5000,
                buttons: [
                  {
                    side: "end",
                    text: "OK",
                    role: "cancel",
                    handler: () => {
                      console.log("Cancel clicked");
                    },
                  },
                ],
              });
              await toast.present();
            }
          }
        },
        error: async (err) => {
          const errMsg = this.translateService.instant(
            "errorMessages.error: There was an error on saving"
          );
          const toast = await this.toastController.create({
            message: errMsg,
            color: "danger",
            position: "middle",
            duration: 5000,
            buttons: [
              {
                side: "end",
                text: "OK",
                role: "cancel",
                handler: () => {
                  console.log("Cancel clicked");
                },
              },
            ],
          });
          await toast.present();
          return err;
        },
      });
  }
}
