import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import {
  auditTime,
  catchError,
  distinctUntilChanged,
  first,
} from "rxjs/operators";
import * as _ from "lodash";
import * as fromApp from "../store/app.reducer";
import * as selectors from "../store/app.selectors";
import { Store } from "@ngrx/store";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import { TranslateService } from "@ngx-translate/core";
import { AlertController, Platform, ToastController } from "@ionic/angular";
import * as CustomerCouponsActions from "./store/coupons.actions";
import { Router } from "@angular/router";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import * as CouponsActions from "../discount-coupons/store/coupons.actions";
import { isMoment } from "moment-timezone";
import moment from "moment";
import { AnimationOptions } from "ngx-lottie";

@Component({
  selector: "app-discount-coupons",
  templateUrl: "./discount-coupons.page.html",
  styleUrls: ["./discount-coupons.page.scss"],
})
export class DiscountCouponsPage {
  public currentUser: any;
  public allCoupons: any;
  public mo_token: any;
  public loginLoading: any;
  public windowInfo: any;
  public menuMinWidthToShow: any;
  public storeInfo: any;
  public stores: any;
  public couponFieldActiveInStore: boolean;
  public groupApp: any;
  public windowDimensions: any;
  public couponCodeLoading = false;
  public couponsSchedule: any;
  public todayIsCouponsExceptionDay: any = null;
  public currentDayName = moment().format("dddd").toLowerCase();
  public currentDayCouponsSchedule: any;
  public couponsExceptionDaysSchedule: any;
  public filterType = "mobileorder";
  public kioskCouponsFound;
  public mobileorderCouponsFound;
  public atLeastOneKioskCoupon = false;
  private addNewCouponCodeAlert: any;
  private errorToast: any;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private fetchCouponsAgainTimestamp;

  public sadAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/sad-emoji-1.json",
  };
  constructor(
    private store: Store<fromApp.AppState>,
    private dataStorageService: DataStorageService,
    private translateService: TranslateService,
    private toastController: ToastController,
    private router: Router,
    private analyticsService: AnalyticsServiceService,
    private alertController: AlertController,
    private platform: Platform
  ) {}

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.addNewCouponCodeAlert) {
          this.addNewCouponCodeAlert.dismiss();
          this.addNewCouponCodeAlert = null;
        } else {
          this.navigateToHome();
        }
      }
    );
    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("coupons");
    this.analyticsService.tikTokPixelPageView("coupons");
    this.subscriptions.push(
      this.store
        .select("customerCoupons")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.allCoupons &&
            !_.isEqual(state.allCoupons, this.allCoupons)
          ) {
            this.allCoupons = _.cloneDeep(state.allCoupons);
            this.atLeastOneKioskCoupon = !!_.find(
              _.cloneDeep(this.allCoupons),
              { kiosk: true }
            );
            this.store
              .select("groupApp")
              .pipe(first())
              .subscribe((groupState) => {
                this.store
                  .select(selectors.getStoreInfo)
                  .pipe(first())
                  .subscribe((storeInfo) => {
                    if (
                      storeInfo &&
                      storeInfo.dm_kiosk_app_active &&
                      !(
                        groupState &&
                        groupState.groupApp &&
                        groupState.groupApp.design_settings &&
                        groupState.groupApp.design_settings
                          .dm_kiosk_app_disable_mobileorder
                      )
                    ) {
                      this.kioskCouponsFound = _.find(this.allCoupons, {
                        kiosk: true,
                      });

                      this.mobileorderCouponsFound = _.find(
                        this.allCoupons,
                        (coupon) => {
                          if (!coupon.only_kiosk) {
                            return true;
                          }
                        }
                      );
                    }
                  })
                  .unsubscribe();
              })
              .unsubscribe();
          }

          if (
            state &&
            state.fetchCouponsAgainTimestamp &&
            !_.isEqual(
              state.fetchCouponsAgainTimestamp,
              this.fetchCouponsAgainTimestamp
            )
          ) {
            this.fetchCouponsAgainTimestamp = _.cloneDeep(
              state.fetchCouponsAgainTimestamp
            );
            this.getUserCoupons();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowDimensions, state.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.mo_token &&
            !_.isEqual(this.mo_token, state.mo_token)
          ) {
            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && state.user && !_.isEqual(this.currentUser, state.user)) {
            this.currentUser = _.cloneDeep(state.user);
            this.getUserCoupons();
          }
          if (
            state &&
            state.loginLoading &&
            !_.isEqual(this.loginLoading, state.loginLoading)
          ) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
            if (this.loginLoading === "not-loading" && _.isEmpty(state.user)) {
              this.presentErrorToast(
                this.translateService.instant(
                  "discount-coupons.you-must-be-logged-in-to-see-your-coupons"
                ),
                "danger"
              );
              this.router.navigateByUrl("/homepage", {
                replaceUrl: true,
              });
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(this.stores, state.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (this.stores && this.stores.length && this.stores.length === 1) {
              this.couponsSchedule = this.formatStoreSchedule(
                this.stores[0].couponsSchedule
              );
              console.log("coupon schedule", this.couponsSchedule);
              if (!_.isEmpty(this.stores[0].couponsSchedule)) {
                console.log("couponsschedule", this.stores[0].couponsSchedule);
                this.initializeActions(
                  _.cloneDeep(this.stores[0].couponsSchedule),
                  "couponsSchedule"
                );
              }
              if (!_.isEmpty(this.stores[0].couponsExceptionDays)) {
                this.initializeActions(
                  _.cloneDeep(this.stores[0].couponsExceptionDays),
                  "couponsExceptionDays"
                );
              }

              console.log("coupons schedule", this.couponsSchedule);
            }
            if (this.stores)
              this.couponFieldActiveInStore = _.find(this.stores, {
                couponFieldActive: true,
              });
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(this.storeInfo, storeInfo)) {
            this.storeInfo = _.cloneDeep(storeInfo);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowInfo, state.windowDimensions)
          ) {
            this.windowInfo = _.cloneDeep(state.windowDimensions);
            this.menuMinWidthToShow = getComputedStyle(
              document.documentElement
            ).getPropertyValue("--menuMinWidthToShow");
            if (this.menuMinWidthToShow) {
              this.menuMinWidthToShow = parseInt(this.menuMinWidthToShow);
            }
            console.log("menu min with to show", this.menuMinWidthToShow);
          }
          console.log("window info", this.windowInfo);
        })
    );
  }
  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  initializeActions(schedule, type) {
    console.log("initialize actions called", schedule, type);
    if (type === "couponsExceptionDays") {
      _.each(schedule, (item, key) => {
        let day = item.date.split("-");
        let date = moment();
        console.log(
          "valuesss",
          parseInt(day[0]),
          parseInt(day[1]),
          parseInt(day[2])
        );
        date.set("date", parseInt(day[0]));
        date.set("month", parseInt(day[1]) - 1);
        date.set("year", parseInt(day[2]));
        let today = moment();
        console.log("date today", date, today);
        console.log("diff", date.diff(today), key);
        if (date.diff(today, "days") > 0 && date.diff(today, "days") < 10) {
          item.showExceptionDay = true;
        } else {
          item.showExceptionDay = false;
        }
      });
    }

    console.log("schedule", schedule);

    if (!_.isEmpty(schedule)) {
      let allDaysSchedule = schedule.map((item) => {
        if (!_.isObject(item)) {
          item = {};
        }

        if (!_.isArray(item.time_periods)) {
          item.time_periods = [];
        }
        if (item.date) {
          let day = item.date.split("-");
          // let month = item.date.split("-", 1);
          // let year = item.date.split("-", 2);
          // let date = moment().set({
          //   day: parseInt(day[0]),
          //   month: parseInt(day[1]) - 1,
          //   year: parseInt(day[2]),
          //   hour: 0,
          //   minute: 0,
          //   second: 0,
          // });
          let date = moment();
          date.date(parseInt(day[0]));
          date.month(parseInt(day[1]) - 1);
          date.year(parseInt(day[2]));

          console.log(
            "moment date values",
            parseInt(day[0]),
            parseInt(day[1]),
            parseInt(day[2])
          );
          console.log("moment date object", date);
          item.date = _.cloneDeep(date);
        }
        for (let index = 0; index < item.time_periods.length; index++) {
          if (
            !_.isNumber(item.time_periods[index].stop) ||
            !item.time_periods[index].stop
          ) {
            item.time_periods[index].stop = 1;
          }

          if (
            !_.isNumber(item.time_periods[index].start) ||
            !item.time_periods[index].start
          ) {
            item.time_periods[index].start = 1;
          }

          const start_minutes = +item.time_periods[index].start / 60;
          const start_hours = start_minutes / 60;
          const start_rest_minutes = start_minutes % 60;

          item.time_periods[index].start_timestamp = moment(0)
            .hour(start_hours)
            .minutes(0)
            .add(start_rest_minutes, "minutes")
            .startOf("minute")
            .format();

          const stop_minutes = +item.time_periods[index].stop / 60;
          const stop_hours = stop_minutes / 60;
          const stop_rest_minutes = stop_minutes % 60;

          item.time_periods[index].stop_timestamp = moment(0)
            .hour(stop_hours)
            .minutes(0)
            .add(stop_rest_minutes, "minutes")
            .startOf("minute")
            .format();
        }

        return item;
      });

      if (type === "couponsSchedule") {
        this.currentDayCouponsSchedule = _.cloneDeep(
          allDaysSchedule[moment().weekday()]
        );
      } else if (type === "couponsExceptionDays") {
        this.couponsExceptionDaysSchedule = _.cloneDeep(allDaysSchedule);
        this.checkIfIsExceptionDay(this.couponsExceptionDaysSchedule);
      }
    }

    console.log("type shedule", schedule);
  }

  formatStoreSchedule(schedule) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];

    var singleScheduleArray = [];

    _.each(schedule, (scheduleDay, key, list) => {
      if (scheduleDay.active && !scheduleDay.all_day) {
        var commentSchedule = "";
        scheduleDay.time_periods.forEach((tp, i) => {
          if (i > 0) {
            commentSchedule += ", ";
          }
          commentSchedule +=
            this.toHHMMSS(tp.start) + " - " + this.toHHMMSS(tp.stop);
        });

        singleScheduleArray.push({
          day: weekDays[key],
          schedule: commentSchedule,
        });
      } else if (scheduleDay.active && scheduleDay.all_day) {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: null,
        });
      } else {
        singleScheduleArray.push({
          day: weekDays[key],
          schedule: "disabled",
        });
      }
    });

    var sunday = _.head(singleScheduleArray);
    singleScheduleArray = _.takeRight(singleScheduleArray, 6);
    singleScheduleArray.push(sunday);
    return singleScheduleArray;
  }

  toHHMMSS(val) {
    console.log("val", val);
    if (val) {
      let sec_num = parseInt(val, 10); // don't forget the second param
      let hours = Math.floor(sec_num / 3600);
      let minutes = Math.floor((sec_num - hours * 3600) / 60);
      let newhours = hours.toString();
      let newmins = minutes.toString();
      if (hours < 10) {
        newhours = "0" + hours;
      }
      if (minutes < 10) {
        newmins = "0" + minutes;
      }

      return newhours + ":" + newmins;
    } else {
      return "00:00";
    }
  }

  checkIfIsExceptionDay(couponsExceptionDays) {
    var weekDays = [
      this.translateService.instant("store-schedule.sunday"),
      this.translateService.instant("store-schedule.monday"),
      this.translateService.instant("store-schedule.tuesday"),
      this.translateService.instant("store-schedule.wednesday"),
      this.translateService.instant("store-schedule.thursday"),
      this.translateService.instant("store-schedule.friday"),
      this.translateService.instant("store-schedule.saturday"),
    ];
    this.todayIsCouponsExceptionDay = null;
    console.log("exception days called", couponsExceptionDays);
    if (!_.isEmpty(couponsExceptionDays)) {
      _.each(couponsExceptionDays, (day, key) => {
        if (isMoment(day.date)) {
          day.date.set("hour", 0);
          day.date.set("minutes", 0);
          day.date.set("seconds", 0);
          day.date.set("milliseconds", 0);
          let now = moment();
          now.set("hour", 0);
          now.set("minutes", 0);
          now.set("seconds", 0);
          now.set("milliseconds", 0);
          if (moment(day.date).isSame(now)) {
            this.todayIsCouponsExceptionDay = weekDays[moment().weekday()];

            this.currentDayCouponsSchedule = _.cloneDeep(
              couponsExceptionDays[key]
            );
            console.log(
              "today is excpetion day",
              this.todayIsCouponsExceptionDay,
              this.currentDayCouponsSchedule
            );
          }
        }
      });
    }
  }

  async addNewCouponCode() {
    this.addNewCouponCodeAlert = await this.alertController.create({
      header: this.translateService.instant("discount-coupons.coupon-code"),
      backdropDismiss: false,
      message: this.translateService.instant(
        "discount-coupons.please-enter-your-coupon-code"
      ),
      inputs: [
        {
          name: "couponCode",
          type: "text",

          placeholder: this.translateService.instant(
            "discount-coupons.write-here"
          ),
        },
      ],
      buttons: [
        {
          text: this.translateService.instant(
            "popups.magic-login-confirmation.cancel"
          ),
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "OK",
          role: "OK",
          handler: (data) => {
            if (data && data.couponCode) {
              this.couponCodeLoading = true;
              const coupon_code = data.couponCode.toString().toUpperCase();
              this.dataStorageService
                .moCheckCouponCode({
                  coupon_code: coupon_code,
                  group: this.groupApp.group,
                  store_id: this.storeInfo.store_id,
                  mobile:
                    this.currentUser && this.currentUser.mobile
                      ? this.currentUser.mobile
                      : null,
                })

                .subscribe({
                  next: async (res: any) => {
                    if (res && res.success) {
                      this.couponCodeLoading = false;
                      const alert = await this.alertController.create({
                        header: this.translateService.instant("information"),
                        subHeader:
                          res.data && res.data.coupon_rule_description
                            ? res.data.coupon_rule_description
                            : res.data && res.data.firstName
                            ? "Έγκυρος κωδικός από: " + res.data.firstName
                            : "",

                        message: res.comment_id
                          ? this.translateService.instant(res.comment_id) +
                            " " +
                            this.translateService.instant(
                              "wait-a-minute-for-your-coupon-to-be-created"
                            )
                          : res.comments +
                            " " +
                            this.translateService.instant(
                              "wait-a-minute-for-your-coupon-to-be-created"
                            ),
                        buttons: ["OK"],
                      });
                      await alert.present();

                      if (res && res.data && res.data.affiliate_coupon_code) {
                        this.store.dispatch(
                          new CouponsActions.SetTriggerCouponCodeCheck({
                            timestamp: Date.now(),
                            type: "affiliate_coupon_code",
                            coupon_code: _.cloneDeep(
                              res.data.affiliate_coupon_code
                            ),
                          })
                        );
                      } else if (res && res.data && res.data.coupon_rule_id) {
                        this.store.dispatch(
                          new CouponsActions.SetTriggerCouponCodeCheck({
                            timestamp: Date.now(),
                            type: "coupon_rule_id",
                            coupon_code: _.cloneDeep(res.data.coupon_rule_id),
                          })
                        );
                      } else if (
                        res &&
                        res.data &&
                        res.data.partner_coupon_id
                      ) {
                        this.store.dispatch(
                          new CouponsActions.SetTriggerCouponCodeCheck({
                            timestamp: Date.now(),
                            type: "partner_coupon_id",
                            coupon_code: _.cloneDeep(
                              res.data.partner_coupon_id
                            ),
                          })
                        );
                      } else {
                        // this.store.dispatch(
                        //   new CouponsActions.SetTriggerCouponCodeCheck({
                        //     timestamp: Date.now(),
                        //     type: "coupon_rule_id",
                        //     coupon_code: _.cloneDeep(coupon_code),
                        //   })
                        // );
                      }
                    } else if (
                      !res.success &&
                      (res.comment_id || res.comments)
                    ) {
                      this.couponCodeLoading = false;
                      console.log("The coupon rule id is false or something");
                      this.presentErrorToast(
                        this.translateService.instant(
                          res.comment_id || res.comments
                        ),
                        "danger"
                      );
                    } else {
                      this.couponCodeLoading = false;
                      this.presentErrorToast(
                        this.translateService.instant(
                          "errorMessages.classic_error"
                        ),
                        "danger"
                      );
                    }
                  },
                  error: (error) => {
                    this.couponCodeLoading = false;
                    this.presentErrorToast(
                      this.translateService.instant(
                        "errorMessages.  problem_reaching_server"
                      ),
                      "danger"
                    );

                    console.log("error check coupon", error);
                  },
                });
            } else {
              if (this.errorToast) {
                this.errorToast.dismiss();
                this.errorToast = null;
              }
              this.presentErrorToast(
                this.translateService.instant(
                  "discount-coupons.please-type-a-coupon-code"
                ),
                "danger"
              );
              return false;
            }
          },
        },
      ],
    });

    await this.addNewCouponCodeAlert.present();
    this.addNewCouponCodeAlert
      .onDidDismiss()
      .then(() => {
        this.addNewCouponCodeAlert = null;
      })
      .finally(() => {});
  }

  getUserCoupons() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((groupState) => {
        this.store
          .select(selectors.getStoreInfo)
          .pipe(first())
          .subscribe((store_info) => {
            let kioskFetch;
            if (
              store_info &&
              store_info.dm_kiosk_app_active &&
              !(
                groupState &&
                groupState.groupApp &&
                groupState.groupApp.design_settings &&
                groupState.groupApp.design_settings
                  .dm_kiosk_app_disable_mobileorder
              )
            ) {
              kioskFetch = true;
            }

            this.allCoupons = null;

            this.dataStorageService
              .getCustomerCoupons(
                this.mo_token,
                kioskFetch ? true : false,
                kioskFetch ? true : false
              )

              .subscribe({
                next: (res: any) => {
                  if (res && res.success) {
                    console.log("success", res);
                    let allCoupons = res.coupons;
                    this.store.dispatch(
                      new CustomerCouponsActions.SetAllCoupons(allCoupons)
                    );
                  } else {
                    if (res.comment_id) {
                      let errorMsg = this.translateService.instant(
                        "errorMessages-an-error-occurred"
                      );
                      this.presentErrorToast(errorMsg, "danger");
                    } else {
                      this.presentErrorToast(res.comments, "danger");
                    }
                  }
                },
                error: async (err) => {
                  const alert = await this.alertController.create({
                    header: this.translateService.instant("alert"),
                    message: this.translateService.instant(
                      "errorMessages.problem_reaching_server"
                    ),

                    buttons: ["OK"],
                  });
                  await alert.present();
                  return err;
                },
              });
          })
          .unsubscribe();
      })
      .unsubscribe();
  }

  async presentErrorToast(message, color) {
    this.errorToast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    this.errorToast.present();
  }

  trackFn(index, item) {
    return item.timestamp;
  }

  ionViewDidLeave() {
    this.allCoupons = null;
    if (this.addNewCouponCodeAlert) {
      this.addNewCouponCodeAlert.dismiss();
      this.addNewCouponCodeAlert = null;
    }
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.store.dispatch(
      new CustomerCouponsActions.SetFetchCouponsAgainTimestamp(null)
    );

    this.subscriptions = [];
  }
}
