<ion-list
  class="ion-no-padding"
  *ngIf="
    store_info &&
    store_info.customerInvoice &&
    (!store_info.vatBasedCustomerProductsActive ||
      (currentUser &&
        currentUser.invoiceData &&
        currentUser.invoiceData.length))
  "
>
  <ion-item
    [hidden]="store_info.hide_invoice_checkbox && invoiceCheckboxValue"
    id="invoice-cart"
    lines="none"
    [disabled]="
      invoicePaymentError || finalPriceLoading || disableInvoiceCheckbox
    "
    (click)="checkForCustomerInvoices($event)"
    button
    detail="false"
    [color]="!valid ? 'danger' : ''"
  >
    <ion-label
      >{{
        "cart.cart-components.customer-info.get-company-invoice" | translate
      }}
    </ion-label>

    <ion-icon
      slot="end"
      *ngIf="invoiceCheckboxValue"
      color="primary"
      src="assets/ionicons/checkbox.svg"
    ></ion-icon>
    <ion-icon
      slot="end"
      *ngIf="!invoiceCheckboxValue"
      color="dark"
      src="assets/ionicons/square-outline.svg"
    ></ion-icon>
  </ion-item>

  <div
    style="font-size: inherit"
    *ngIf="currentUser && selectedCustomerInvoice"
  >
    <ion-item
      style="cursor: pointer"
      [disabled]="finalPriceLoading"
      (click)="presentSelectInvoiceModal(currentUser.invoices || null)"
    >
      <!-- {{ "cart.cart-components.invoice.vat-number" | translate }}: -->
      <ion-label>
        <strong> {{ selectedCustomerInvoice.vatNumber }}</strong>

        {{ selectedCustomerInvoice.company_name }}
      </ion-label>

      <ion-button slot="end" fill="clear"
        ><ion-icon src="assets/flatIcons/cartEdit.svg"></ion-icon
      ></ion-button>
    </ion-item>
  </div>

  <ion-label
    class="ion-text-center ion-text-wrap"
    *ngIf="invoicePaymentError && !currentUser"
  >
    <ion-text color="danger">
      {{
        "cart.cart-components.payment-methods.in-order-to-be-able-to-add-invoice-you-must-login"
          | translate
      }}
    </ion-text>
  </ion-label>
</ion-list>
