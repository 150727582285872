import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import * as _ from "lodash";
import * as fromApp from "../store/app.reducer";
import * as selectors from "../store/app.selectors";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import * as PointsActions from "../points/store/points.actions";
import { Subscription } from "rxjs";
import {
  AlertController,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { ActivatedRoute, Router } from "@angular/router";
import { KioskQrInfoModalComponent } from "./kiosk-qr-info-modal/kiosk-qr-info-modal.component";
import { KioskGetPromoModalComponent } from "./kiosk-get-promo-modal/kiosk-get-promo-modal.component";
import { LoginPopupsServiceService } from "../services/login-popups-service/login-popups-service.service";
import { Capacitor } from "@capacitor/core";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { AnimationOptions } from "ngx-lottie";
import { SelectStorePopupComponent } from "../popups/select-store-popup/select-store-popup.component";
import * as AuthActions from "../auth/store/auth.actions";
import * as CouponsActions from "../discount-coupons/store/coupons.actions";
import moment from "moment";
import { SubmitUserDataModalComponent } from "../popups/submit-user-data-modal/submit-user-data-modal.component";
@Component({
  selector: "app-kiosk",
  templateUrl: "./kiosk.page.html",
  styleUrls: ["./kiosk.page.scss"],
})
export class KioskPage implements OnInit, OnDestroy {
  public currentUser: any;
  public mo_token: any;
  public loginState: any;
  public groupApp: any;
  colSize = "6";
  colSizeRewards = "6";
  public windowDimensions: any;
  public segment = "couponRules";
  public kioskCoupons = null;
  public customerCouponRules = null;
  public customerRewards = null;
  public currentStoreId;
  public loginLoading = false;
  public rootDir;
  public selectedLogoFileName;
  public userPoints;
  public pointsProgressBarColor;
  public usePointsLoading = false;
  usePointsAnimation;
  public canGetAtLeastOneReward = false;

  timestamp_to_open;
  private openFirstCoupon = false;
  private openSpecificCouponTimestamp;
  private waitForUserPointsAndFetchRewards = false;
  private highestReward;
  private newCustomer;
  private subscriptions: Subscription[] = [];
  private unsubscribeBackEvent: Subscription;
  private kioskQrInfoModal: any;
  private clearTimestamp;
  private selectedLangugage;
  private kioskCouponsLoading: boolean = false;

  private stores;
  private kioskQrTimerInterval;
  private fetchCouponsAgainTimestamp = null;

  //timer kiosk qr expiration vars
  timeLimit: number = 60 * 60; // one hour
  secondsRemaining: number = 0;
  formattedTime: string = "";

  couponRulesGridWidth = "100%";
  usePointsAnimationInstance;

  usePointsSuccessAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/use-points-success-animation.json",
  };

  public sadAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/sad-emoji-1.json",
  };

  constructor(
    private store: Store<fromApp.AppState>,
    private alertController: AlertController,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private router: Router,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private toastController: ToastController,
    private LoginPopupsService: LoginPopupsServiceService,
    private analyticsService: AnalyticsServiceService,
    private platform: Platform,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    console.log("on init kiosk page");
  }

  animationCreated(ev) {
    this.usePointsAnimationInstance = ev;
    this.usePointsAnimationInstance.setSpeed(1);
  }

  animationComplete(ev) {
    this.usePointsAnimationInstance.stop();
    this.usePointsAnimation = false;
    this.changeDetector.detectChanges();
  }

  ionViewDidEnter() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        this.navigateToHome();
      }
    );

    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("kiosk");
    this.analyticsService.tikTokPixelPageView("kiosk");

    this.route.queryParamMap
      .subscribe((params) => {
        if (params && params.get("openFirstCoupon")) {
          this.handleRewardsItemEmmiter("navigate_coupons_tab");
        }
      })
      .unsubscribe();

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
            this.calcualteColSizes();
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("customerCoupons")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.fetchCouponsAgainTimestamp &&
            !_.isEqual(
              state.fetchCouponsAgainTimestamp,
              this.fetchCouponsAgainTimestamp
            )
          ) {
            this.fetchCouponsAgainTimestamp = _.cloneDeep(
              state.fetchCouponsAgainTimestamp
            );
            this.changePromoType("kiosk-coupons");
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.userPoints &&
            !_.isEqual(state.userPoints, this.userPoints)
          ) {
            this.userPoints = _.cloneDeep(state.userPoints);
            if (this.waitForUserPointsAndFetchRewards) {
              this.waitForUserPointsAndFetchRewards = false;
              this.fetchRewardsAfterLogin();
            }
          }
          if (
            state &&
            state.highestReward &&
            !_.isEqual(state.highestReward, this.highestReward)
          ) {
            this.highestReward = _.cloneDeep(state.highestReward);

            if (this.highestReward && this.highestReward.user_points_percent) {
              if (this.highestReward.user_points_percent < 50) {
                this.pointsProgressBarColor = "dark";
              } else if (
                this.highestReward.user_points_percent >= 50 &&
                this.highestReward.user_points_percent <= 99
              ) {
                this.pointsProgressBarColor = "warning";
              } else if (this.highestReward.user_points_percent === 100) {
                this.pointsProgressBarColor = "success";
              }
            }
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("currentStore")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.currentStoreId &&
            !_.isEqual(state.currentStoreId, this.currentStoreId)
          ) {
            this.currentStoreId = _.cloneDeep(state.currentStoreId);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(state.groupApp, this.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            if (!_.isEmpty(this.groupApp.logosImages)) {
              this.selectedLogoFileName = _.find(this.groupApp.logosImages, {
                selected: true,
              }).fileName;
            }

            if (
              this.groupApp &&
              !this.groupApp.pointsSystem &&
              !this.groupApp.couponsPage
            ) {
              this.router.navigateByUrl("/homepage", {
                replaceUrl: true,
              });
            }
          }
          if (
            state &&
            state.rootDir &&
            !_.isEqual(this.rootDir, state.rootDir)
          ) {
            //console.log("inside rootDir");
            this.rootDir = _.cloneDeep(state.rootDir);
          }

          if (
            state &&
            state.selectedLangugage &&
            !_.isEqual(this.selectedLangugage, state.selectedLangugage)
          ) {
            //console.log("inside rootDir");
            this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(state.windowDimensions, this.windowDimensions)
          ) {
            this.windowDimensions = _.cloneDeep(state.windowDimensions);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && !_.isEqual(state.mo_token, this.mo_token)) {
            if (state.mo_token && !this.mo_token) {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupAppState) => {
                  this.store
                    .select("currentStore")
                    .pipe(first())
                    .subscribe((currentStoreState) => {
                      if (this.segment === "couponRules") {
                        this.fetchCouponRules(
                          state.mo_token,
                          currentStoreState.currentStoreId,
                          null,
                          groupAppState.groupApp
                            ? groupAppState.groupApp.group
                            : null
                        );
                      }
                    })
                    .unsubscribe();
                });
            }

            this.mo_token = _.cloneDeep(state.mo_token);
          }
          if (state && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);

            if (
              this.currentUser &&
              this.currentUser.kiosk_expiration_timestamp
            ) {
              let momentTimestamp;

              if (
                this.timestampIsMilliseconds(
                  _.cloneDeep(this.currentUser.kiosk_expiration_timestamp)
                )
              ) {
                momentTimestamp = moment(
                  parseInt(
                    _.cloneDeep(
                      this.currentUser.kiosk_expiration_timestamp
                    ).toString()
                  )
                );
              } else {
                momentTimestamp = moment.unix(
                  parseInt(
                    _.cloneDeep(
                      this.currentUser.kiosk_expiration_timestamp
                    ).toString()
                  )
                );
              }

              // Calculate the remaining seconds
              this.secondsRemaining = _.cloneDeep(
                momentTimestamp.diff(moment(), "seconds")
              );

              if (this.secondsRemaining) {
                this.startTimerKioskQr();
              }
            }

            if (navigator.cookieEnabled && this.currentUser) {
              const opened = localStorage.getItem("kioskInfoModalOpened");
              //console.log("askldjlkasjd", opened);
              if (!opened || opened !== "yes") {
                localStorage.setItem("kioskInfoModalOpened", "yes");

                setTimeout(() => {
                  this.openKioskQrInfoModal();
                }, 300);
              }
            }
          }
          if (state && !_.isEqual(state.loginState, this.loginState)) {
            console.log("waitForUserPointsAndFetchRewards", state.loginState);
            if (
              state.loginState &&
              state.loginState === "login_completed" &&
              (this.loginState === "login_loading" ||
                this.loginState === "no_login") &&
              this.segment === "pointRewards"
            ) {
              this.waitForUserPointsAndFetchRewards = true;
              console.log("waitForUserPointsAndFetchRewards", true);
            }
            this.loginState = _.cloneDeep(state.loginState);
            if (this.loginState === "no_login") {
              this.store
                .select("groupApp")
                .pipe(first())
                .subscribe((groupAppState) => {
                  this.store
                    .select("currentStore")
                    .pipe(first())
                    .subscribe((currentStoreState) => {
                      console.log("fetch coupon rules for no user");
                      this.fetchCouponRules(
                        state.mo_token,
                        currentStoreState.currentStoreId,
                        true,
                        groupAppState.groupApp
                          ? groupAppState.groupApp.group
                          : null
                      );
                    })
                    .unsubscribe();
                })
                .unsubscribe();
            }
          }
          if (state && !_.isEqual(state.newCustomer, this.newCustomer)) {
            this.newCustomer = _.cloneDeep(state.newCustomer);
          }
          if (state && !_.isEqual(state.loginLoading, this.loginLoading)) {
            this.loginLoading = _.cloneDeep(state.loginLoading);
          }
        })
    );
  }

  timestampIsMilliseconds(timestamp) {
    const length = timestamp.toString().length;
    return length === 13;
  }

  openInBrowser(reward) {
    window.open(reward.third_party_url, "_system", "location=yes");
  }

  triggerRenewToken() {
    this.store.dispatch(
      new AuthActions.SetTriggerRenewTokenTimestamp(Date.now().toString())
    );
  }

  async openGetPromoModal(promo, type) {
    if (
      this.groupApp &&
      this.groupApp.allUserDataRequiredCoupons &&
      this.currentUser &&
      (!this.currentUser.email ||
        !this.currentUser.firstName ||
        !this.currentUser.lastName)
    ) {
      let userDataEntryCompleted = false;
      //ask user for data and show GDPR

      const modal = await this.modalController.create({
        component: SubmitUserDataModalComponent,
        cssClass: "submit-user-data-modal",
        backdropDismiss: false,
        componentProps: {},
      });

      await modal.present();
      await modal.onDidDismiss().then((data) => {
        if (data && data.data && data.data === "success") {
          userDataEntryCompleted = true;
        }
      });

      if (!userDataEntryCompleted) {
        return false;
      }
    }

    this.kioskQrInfoModal = await this.modalController.create({
      component: KioskGetPromoModalComponent,
      cssClass: "kioskGetPromoModal",
      backdropDismiss: false,
      componentProps: {
        promo: _.cloneDeep(promo),
        type: type,
        groupApp: this.groupApp,
        stores: this.stores,
        showLoginRegisterButton: this.currentUser ? null : true,
      },
    });

    await this.kioskQrInfoModal.present();

    await this.kioskQrInfoModal.onDidDismiss().then((data) => {
      if (this.kioskQrInfoModal) {
        this.kioskQrInfoModal = null;
      }
      if (
        data &&
        data.data &&
        _.isString(data.data) &&
        data.data === "navigate_coupons_tab"
      ) {
        this.openFirstCoupon = true;
        this.segment = "kiosk-coupons";
      } else if (data && data.data && data.data === "coupon_get_success") {
        if (
          !(
            this.groupApp &&
            this.groupApp.design_settings &&
            this.groupApp.design_settings.dm_kiosk_app_disable_mobileorder
          ) &&
          (promo.only_kiosk || promo.kiosk)
        ) {
          this.openFirstCoupon = true;
          this.segment = "kiosk-coupons";
        }
      } else if (
        data &&
        data.data &&
        _.isString(data.data) &&
        data.data === "showLoginRegister"
      ) {
        this.presentMagicLoginPopup();
      } else if (
        data &&
        data.data &&
        _.isObject(data.data) &&
        data.data.openSpecificCouponTimestamp
      ) {
        this.openSpecificCouponTimestamp =
          data.data.openSpecificCouponTimestamp;
        this.segment = "kiosk-coupons";
      }
    });
  }

  async openSelectStoreModal(storesForSelection) {
    return new Promise(async (resolve, reject) => {
      let cssClass = "selectStorePopupCss";
      if (
        storesForSelection &&
        storesForSelection.length > 1 &&
        storesForSelection.length <= 3
      ) {
        cssClass = "selectStorePopupCssThreeStores";
      } else if (storesForSelection && storesForSelection.length > 3) {
        cssClass = "selectStorePopupCssMultipleStores";
      }
      const modal = await this.modalController.create({
        component: SelectStorePopupComponent,
        componentProps: {
          storesForSelection: storesForSelection,
          onlyReturnSelectedStoreId: true,
        },
        cssClass: cssClass,
        backdropDismiss: false,
      });

      await modal.present();

      await modal.onDidDismiss().then((data) => {
        if (data && data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      });
    });
  }

  fetchCustomerPoints() {
    this.dataStorageService
      .fetchCustomerPoint(
        this.mo_token,
        this.groupApp &&
          this.groupApp.points &&
          this.groupApp.points.loyaltyCardLevelsActive
      )
      .subscribe({
        next: async (res: any) => {
          if (res && res.success) {
            let userPoints = res.data;
            this.store.dispatch(
              new PointsActions.SetUserPoints(_.cloneDeep(userPoints))
            );
            let tempGroupApp = _.cloneDeep(this.groupApp);
            this.canGetAtLeastOneReward = false;
            _.each(tempGroupApp.points.rewards, (reward) => {
              if (reward.points <= userPoints.pointsSystem.active) {
                reward.user_points_percent = 100;

                this.canGetAtLeastOneReward = true;
              } else {
                var percent =
                  (userPoints.pointsSystem.active / reward.points) * 100;
                reward.user_points_percent = percent.toFixed();
              }

              console.log("reward", reward.user_points_percent);
            });
            this.store.dispatch(
              new PointsActions.SetRewards(
                _.cloneDeep(tempGroupApp.points.rewards)
              )
            );
            let highestReward = _.maxBy(
              tempGroupApp.points.rewards,
              "user_points_percent"
            );
            this.store.dispatch(
              new PointsActions.SetHighestReward(_.cloneDeep(highestReward))
            );
          } else if (!res || !res.success) {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message:
                res && res.comment_id
                  ? this.translateService.instant(res.comment_id)
                  : this.translateService.instant(
                      "errorMessages.general_error"
                    ),
              backdropDismiss: false,
              buttons: ["OK"],
            });
            await alert.present();
          }
        },
        error: async (error) => {
          const alert = await this.alertController.create({
            header: this.translateService.instant("alert"),
            message: this.translateService.instant(
              "errorMessages.problem_reaching_server"
            ),

            buttons: ["OK"],
          });
          await alert.present();
        },
      });
  }

  async presentMagicLoginPopup() {
    this.analyticsService.dmAnalyticsMessage(
      "login_button",
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    // this.MagicLoginservice.presentLoginModal(this.newCustomer);
    if (!Capacitor.isNativePlatform()) {
      this.LoginPopupsService.presentFirebaseModalLogin(null, null);
    } else {
      this.LoginPopupsService.closeMobileMagicLoginAlert();
      this.LoginPopupsService.presentMobileMagicLoginAlert(
        this.newCustomer,
        this.groupApp
      );
    }
  }

  changePromoType(ev) {
    this.kioskCoupons = null;
    this.customerCouponRules = null;
    this.customerRewards = null;
    if (this.segment === "couponRules") {
      this.fetchCouponRules(
        this.mo_token,
        this.currentStoreId,
        this.currentUser ? false : true,
        this.groupApp.group
      );
    } else if (this.segment === "pointRewards") {
      this.store
        .select("points")
        .pipe(first())
        .subscribe((state) => {
          this.calculatePointRewards(
            this.mo_token,
            this.currentStoreId,
            state.userPoints,
            this.currentUser ? false : true,
            this.groupApp.group
          );
        })
        .unsubscribe();
    } else if (this.segment === "kiosk-coupons") {
      this.getKioskCoupons();
    }
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  calculatePointRewards(token, store_id, userPoints, noUser, group) {
    (noUser
      ? this.dataStorageService.getNoUserKioskCouponRulesRewards(
          store_id,
          group
        )
      : this.dataStorageService.getCustomerKioskCouponRulesRewards(
          token,
          store_id
        )
    ).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          console.log("success", res);

          this.customerRewards = res.rewards;
          this.canGetAtLeastOneReward = false;
          _.each(this.customerRewards, (reward) => {
            if (userPoints) {
              if (reward.points <= userPoints.pointsSystem.active) {
                reward.user_points_percent = 100;
                this.canGetAtLeastOneReward = true;
              } else {
                var percent =
                  (userPoints.pointsSystem.active / reward.points) * 100;
                reward.user_points_percent = percent.toFixed();
              }
            }

            console.log("reward", reward.user_points_percent);
          });

          this.customerRewards = _.cloneDeep(
            _.filter(_.cloneDeep(this.customerRewards), (reward) => {
              if (reward.active) {
                return reward;
              }
            })
          );
        } else {
          if (res.comment_id) {
            let errorMsg = this.translateService.instant(
              "errorMessages-an-error-occurred"
            );
            this.presentErrorToast(errorMsg, "danger");
          } else {
            this.presentErrorToast(res.comments, "danger");
          }
        }
      },
      error: async (err) => {
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "errorMessages.problem_reaching_server"
          ),

          buttons: ["OK"],
        });
        await alert.present();
        return err;
      },
    });
  }

  fetchCouponRules(token, store_id, no_user, group) {
    (no_user
      ? this.dataStorageService.getNoUserKioskCouponRulesRewards(
          store_id,
          group
        )
      : this.dataStorageService.getCustomerKioskCouponRulesRewards(
          token,
          store_id
        )
    ).subscribe({
      next: (res: any) => {
        if (res && res.success) {
          console.log("success", res);
          this.customerCouponRules = res.coupon_rules;
        } else {
          if (res.comment_id) {
            let errorMsg = this.translateService.instant(
              "errorMessages-an-error-occurred"
            );
            this.presentErrorToast(errorMsg, "danger");
          } else {
            this.presentErrorToast(res.comments, "danger");
          }
        }
      },
      error: async (err) => {
        const alert = await this.alertController.create({
          header: this.translateService.instant("alert"),
          message: this.translateService.instant(
            "errorMessages.problem_reaching_server"
          ),

          buttons: ["OK"],
        });
        await alert.present();
        return err;
      },
    });
  }

  couponTrackFn(index, item) {
    return item.timestamp;
  }

  startTimerKioskQr() {
    if (this.kioskQrTimerInterval) {
      clearInterval(this.kioskQrTimerInterval);
      this.kioskQrTimerInterval = null;
    }

    this.updateTimerKioskQr();

    this.kioskQrTimerInterval = setInterval(() => {
      this.secondsRemaining--;

      if (this.secondsRemaining < 0) {
        if (this.kioskQrTimerInterval) {
          clearInterval(this.kioskQrTimerInterval);
          this.kioskQrTimerInterval = null;
        }
      } else {
        this.updateTimerKioskQr();
      }
    }, 1000);
  }

  updateTimerKioskQr() {
    const minutes = parseInt(
      Math.floor(_.cloneDeep(this.secondsRemaining) / 60).toString()
    );
    const seconds = parseInt(
      (_.cloneDeep(this.secondsRemaining) % 60).toString()
    );

    this.formattedTime = moment()
      .set("minutes", minutes)
      .set("seconds", seconds)
      .format("mm:ss");

    this.changeDetector.detectChanges();
  }

  fetchRewardsAfterLogin() {
    console.log(
      "fetch rewards after login",
      this.mo_token,
      this.currentStoreId,
      this.userPoints,
      this.currentUser ? false : true,
      this.groupApp ? this.groupApp.group : null
    );
    this.calculatePointRewards(
      this.mo_token,
      this.currentStoreId,
      this.userPoints,
      this.currentUser ? false : true,
      this.groupApp ? this.groupApp.group : null
    );
  }

  clearTimestampToOpen(timestamp) {
    if (!_.isEqual(this.clearTimestamp, timestamp)) {
      this.clearTimestamp = _.cloneDeep(timestamp);
      this.timestamp_to_open = null;
      this.changeDetector.detectChanges();
    }
  }

  getKioskCoupons() {
    if (!this.kioskCouponsLoading) {
      this.kioskCouponsLoading = true;
      this.dataStorageService
        .getCustomerKioskCoupons(this.mo_token)

        .subscribe({
          next: (res: any) => {
            this.kioskCouponsLoading = false;
            if (res && res.success) {
              console.log("success", res);
              this.kioskCoupons = res.coupons;

              if (
                this.kioskCoupons &&
                this.kioskCoupons.length &&
                this.openFirstCoupon
              ) {
                this.openFirstCoupon = false;
                setTimeout(() => {
                  this.timestamp_to_open = this.kioskCoupons[0].timestamp;
                }, 300);
              }

              if (
                this.kioskCoupons &&
                this.kioskCoupons.length &&
                !this.openFirstCoupon &&
                this.openSpecificCouponTimestamp
              ) {
                const couponFound = _.find(this.kioskCoupons, {
                  timestamp: this.openSpecificCouponTimestamp,
                });

                if (couponFound) {
                  this.openSpecificCouponTimestamp = null;
                  setTimeout(() => {
                    this.timestamp_to_open = couponFound.timestamp;
                  }, 300);
                }
              }
            } else {
              if (res.comment_id) {
                let errorMsg = this.translateService.instant(
                  "errorMessages-an-error-occurred"
                );
                this.presentErrorToast(errorMsg, "danger");
              } else {
                this.presentErrorToast(res.comments, "danger");
              }
            }
          },
          error: async (err) => {
            this.kioskCouponsLoading = false;
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            return err;
          },
        });
    }
  }

  async openKioskQrInfoModal() {
    this.kioskQrInfoModal = await this.modalController.create({
      component: KioskQrInfoModalComponent,
      cssClass: "kioskQrInfoModal",
      backdropDismiss: false,
      componentProps: {},
    });

    await this.kioskQrInfoModal.present();

    await this.kioskQrInfoModal.onDidDismiss().then((data) => {
      if (this.kioskQrInfoModal) {
        this.kioskQrInfoModal = null;
      }
    });
  }

  handleRewardsItemEmmiter(ev) {
    if (ev === "navigate_coupons_tab") {
      setTimeout(() => {
        this.openFirstCoupon = true;
        this.segment = "kiosk-coupons";
        this.changePromoType("kiosk-coupons");
      }, 300);
    } else if (ev === "showLoginRegister") {
      this.presentMagicLoginPopup();
    }
  }

  calcualteColSizes() {
    if (this.windowDimensions && this.windowDimensions.width) {
      if (this.windowDimensions.width < 345) {
        this.colSize = "12";
        this.couponRulesGridWidth = "100%";
      } else if (
        this.windowDimensions.width >= 345 &&
        this.windowDimensions.width < 550
      ) {
        this.colSize = "6";
        this.couponRulesGridWidth = "310px";
      } else if (
        this.windowDimensions.width >= 550 &&
        this.windowDimensions.width < 700
      ) {
        this.colSize = "4";
        this.couponRulesGridWidth = "450px";
      } else if (this.windowDimensions.width >= 700) {
        this.colSize = "3";
        this.couponRulesGridWidth = "600px";
      } else {
        this.couponRulesGridWidth = "100%";
      }

      if (this.windowDimensions.width < 370) {
        this.colSizeRewards = "12";
      } else if (
        this.windowDimensions.width >= 370 &&
        this.windowDimensions.width < 550
      ) {
        this.colSizeRewards = "6";
      } else if (
        this.windowDimensions.width >= 550 &&
        this.windowDimensions.width < 700
      ) {
        this.colSizeRewards = "4";
      } else if (this.windowDimensions.width >= 700) {
        this.colSizeRewards = "3";
      }
    }
  }

  ionViewDidLeave(): void {
    this.store.dispatch(new CouponsActions.SetFetchCouponsAgainTimestamp(null));
    if (this.kioskQrInfoModal) {
      this.kioskQrInfoModal.dismiss();
      this.kioskQrInfoModal = null;
    }
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
      this.unsubscribeBackEvent = null;
    }

    if (this.kioskQrTimerInterval) {
      clearInterval(this.kioskQrTimerInterval);
      this.kioskQrTimerInterval = null;
    }

    this.secondsRemaining = 0;
    this.currentUser = null;
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new CouponsActions.SetFetchCouponsAgainTimestamp(null));
    if (this.subscriptions && !_.isEmpty(this.subscriptions)) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }

    this.secondsRemaining = 0;
    this.currentUser = null;

    if (this.kioskQrTimerInterval) {
      clearInterval(this.kioskQrTimerInterval);
      this.kioskQrTimerInterval = null;
    }
  }
}
