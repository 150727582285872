import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";

import * as _ from "lodash";
import * as fromApp from "../store/app.reducer";
import { Store } from "@ngrx/store";
import { auditTime, distinctUntilChanged, first } from "rxjs/operators";
import { Subscription } from "rxjs";
import * as GroupAppActions from "../store/group-app/groupApp.actions";
import { AnalyticsServiceService } from "../services/analitycsService/analytics-service.service";
import { Platform } from "@ionic/angular";
import { ActivatedRoute, Router } from "@angular/router";
import { DataStorageService } from "../services/data-storage/data-storage.service";
import * as PointsActions from "./store/points.actions";
import { AnimationOptions } from "ngx-lottie";
import { LoginPopupsServiceService } from "../services/login-popups-service/login-popups-service.service";

@Component({
  selector: "app-points",
  templateUrl: "./points.page.html",
  styleUrls: ["./points.page.scss"],
})
export class PointsPage implements OnDestroy {
  public currentUser: any;
  public currentTab: string = "rewards";
  segmentValue = "rewards";
  public userPoints: any;
  public windowInfo: any;
  public menuMinWidthToShow: any;
  public loading: boolean = false;
  public usePointsAnimationInstance: any;
  public usePointsAnimation: boolean = false;
  public loginState: string;
  public groupApp;

  private unsubscribeBackEvent: Subscription;
  private highestReward: any;
  private subscriptions: Subscription[] = [];
  usePointsSuccessAnimationOptions: AnimationOptions = {
    path: "https://data.deliverymanager.gr/animations/use-points-success-animation.json",
  };
  onlyLoyaltyApp: boolean;
  constructor(
    private store: Store<fromApp.AppState>,
    private analyticsService: AnalyticsServiceService,
    private platform: Platform,
    private router: Router,
    private loginPopupsService: LoginPopupsServiceService,
    private dataStorageService: DataStorageService,
    private changeDetector: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ionViewDidEnter() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((queryParams) => {
        if (queryParams && queryParams["segment"]) {
          this.currentTab = queryParams["segment"];
          this.segmentValue = queryParams["segment"];
        }
      })
    );

    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      100,
      () => {
        if (this.onlyLoyaltyApp) {
          this.pageChange("loyaltySystem/loyalty-more");
        } else if (this.loginPopupsService.checkMobileMagicLoginIsOpen()) {
          this.loginPopupsService.closeMobileMagicLoginAlert();
        } else {
          this.navigateToHome();
        }
      }
    );
    this.analyticsService.fbqTrackPageView();
    this.analyticsService.setCurrentScreenFirebase("points");
    this.analyticsService.tikTokPixelPageView("points");

    this.subscriptions.push(
      this.store
        .select("auth")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(300))
        .subscribe((state) => {
          if (state && state.user && !_.isEqual(state.user, this.currentUser)) {
            this.currentUser = _.cloneDeep(state.user);
          }
          if (
            state &&
            state.loginState &&
            !_.isEqual(state.loginState, this.loginState)
          ) {
            this.loginState = _.cloneDeep(state.loginState);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("points")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.userPoints &&
            !_.isEqual(this.userPoints, state.userPoints)
          ) {
            this.userPoints = _.cloneDeep(state.userPoints);
          }
        })
    );

    this.subscriptions.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())

        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
          }
        })
    );
    this.subscriptions.push(
      this.store
        .select("windowInfo")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.windowDimensions &&
            !_.isEqual(this.windowInfo, state.windowDimensions)
          ) {
            this.windowInfo = _.cloneDeep(state.windowDimensions);
            this.menuMinWidthToShow = getComputedStyle(
              document.documentElement
            ).getPropertyValue("--menuMinWidthToShow");
            if (this.menuMinWidthToShow) {
              this.menuMinWidthToShow = parseInt(this.menuMinWidthToShow);
            }
            console.log("menu min with to show", this.menuMinWidthToShow);
          }
          console.log("window info", this.windowInfo);
        })
    );

    this.fetchCustomerPoints();
  }

  successUsePointsEvent(ev) {
    this.usePointsAnimation = true;
  }

  handleSegmentChange(ev) {
    if (ev && ev.target && ev.target.value) {
      this.currentTab = ev.target.value;
      this.segmentValue = ev.target.value;
    }
  }

  fetchCustomerPoints() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe((state) => {
        if (state && state.groupApp) {
          let groupApp = _.cloneDeep(state.groupApp);
          if (groupApp.only_loyalty_app) {
            this.onlyLoyaltyApp = true;
            this.currentTab = "history";
            this.segmentValue = "history";
          }
          this.store
            .select("auth")
            .pipe(first())
            .subscribe((state) => {
              if (state && state.mo_token && state.user) {
                let mo_token = _.cloneDeep(state.mo_token);
                console.log("fetch customer points from points page");
                this.loading = true;
                this.dataStorageService
                  .fetchCustomerPoint(
                    mo_token,
                    groupApp &&
                    groupApp.points &&
                    groupApp.points.loyaltyCardLevelsActive
                  )
                  .subscribe({
                    next: (res: any) => {
                      if (res && res.success) {
                        let userPoints = res.data;
                        this.store.dispatch(
                          new PointsActions.SetUserPoints(
                            _.cloneDeep(userPoints)
                          )
                        );
                        let tempGroupApp = _.cloneDeep(groupApp);

                        _.each(tempGroupApp.points.rewards, (reward) => {
                          if (reward.points <= userPoints.pointsSystem.active) {
                            reward.user_points_percent = 100;
                          } else {
                            var percent =
                              (userPoints.pointsSystem.active / reward.points) *
                              100;
                            reward.user_points_percent = percent.toFixed();
                          }

                          console.log("reward", reward.user_points_percent);
                        });
                        this.store.dispatch(
                          new PointsActions.SetRewards(
                            _.cloneDeep(tempGroupApp.points.rewards)
                          )
                        );
                        let highestReward = _.maxBy(
                          tempGroupApp.points.rewards,
                          "user_points_percent"
                        );
                        this.store.dispatch(
                          new PointsActions.SetHighestReward(
                            _.cloneDeep(highestReward)
                          )
                        );
                      } else if (res && !res.success) {
                        console.log("error on points no response");
                      } else if (!res) {
                      }
                      this.loading = false;
                    },
                    error: (error) => {
                      this.loading = false;
                      console.log("error on points", error);
                      return error;
                    },
                  });
              }
            })
            .unsubscribe();
        }
      });
  }

  animationCreated(ev) {
    this.usePointsAnimationInstance = ev;
    this.usePointsAnimationInstance.setSpeed(1);
  }

  animationComplete(ev) {
    this.usePointsAnimationInstance.stop();
    this.usePointsAnimation = false;
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
  }

  private navigateToHome() {
    if (this.platform.is("android")) {
      this.router.navigateByUrl("/homepage", {
        replaceUrl: true,
      });
    }
  }

  pageChange(route) {
    this.router.navigateByUrl("/" + route, {
      replaceUrl: true,
    });
  }

  ionViewDidLeave() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subscriptions = [];
  }
}
