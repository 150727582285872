import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import * as fromApp from "../../store/app.reducer";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as _ from "lodash";
import {
  auditTime,
  distinctUntilChanged,
  filter,
  first,
  Subscription,
  takeWhile,
  timeout,
} from "rxjs";
import moment from "moment";
import { AlertController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import * as CartActions from "../../cart/store/cart.actions";
import { Router } from "@angular/router";
import * as selectors from "../../store/app.selectors";
import { DataStorageService } from "../data-storage/data-storage.service";

@Injectable({
  providedIn: "root",
})
export class DineinValidationsService {
  private dineInOrderStartTimestamp: any;
  private dineInOrderStopAlert: any;
  private intervalCheckingDineInStartTimestamp: any;

  private sub: Subscription;

  constructor(
    private store: Store<fromApp.AppState>,
    private alertController: AlertController,
    private dataStorageService: DataStorageService,
    private router: Router,
    private translateService: TranslateService
  ) {}

  public publicStopDineInOrderStartTimestampChecks() {
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub = null;
      this.clearDineInOrderStartTimestamp();
    }
  }

  getNoLocationDineInModalStatus() {
    return new Promise((resolve, reject) => {
      this.store
        .select("deliveryMethodAddress") // Assuming you select the state object
        .pipe(
          filter(
            (state) => state && state.noLocationDineInModalOpen !== undefined
          ), // Ensure state is valid
          first((state) => state.noLocationDineInModalOpen === "closed"), // Complete when the modal is closed
          timeout(4000) // Timeout after 3 seconds
        )
        .subscribe({
          next: (state) => {
            if (state && state.noLocationDineInModalOpen === "closed") {
              resolve({ status: "closed", dineIn: state.dineIn });
            } else {
              if (state && state.noLocationDineInModalOpen !== "opened") {
                resolve({ status: "closed", dineIn: state.dineIn });
              }
              if (state && state.noLocationDineInModalOpen === "opened") {
                resolve({ status: "opened", dineIn: state.dineIn });
              }
            }
            resolve(false);
          },
          error: (err) => {
            if (err.name === "TimeoutError") {
              resolve(false);
              // if (state && state.noLocationDineInModalOpen !== "opened") {
              //   resolve("closed");
              // }
            }
          },
        });
    });
  }

  public subscribeToDineInOrderStarTimestamp() {
    if (!this.sub) {
      this.sub = this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(200))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(
              state.dineInOrderStartTimestamp,
              this.dineInOrderStartTimestamp
            )
          ) {
            this.dineInOrderStartTimestamp = _.cloneDeep(
              state.dineInOrderStartTimestamp
            );

            if (this.dineInOrderStartTimestamp) {
              this.checkDineInOrderStartTimestamp(
                state.orderPickup,
                state.dineIn
              );
            }
          }

          if (state && (state.orderPickup || !state.dineIn)) {
            if (state.dineInOrderStartTimestamp) {
              this.clearDineInOrderStartTimestamp();
            }
            this.dismissDineInOrderStopAlert();
          }
        });
    }
  }

  private async checkDineInOrderStartTimestamp(orderPickup, dineIn) {
    const currentUser: any = await this.getCurrentUser();
    if (!dineIn || (currentUser && currentUser.uposMoUser)) {
      this.clearDineInOrderStartTimestamp();
    } else {
      if (this.dineInOrderStartTimestamp) {
        const orderStartTime = moment(
          parseInt(this.dineInOrderStartTimestamp.toString())
        );
        const currentTime = moment();

        if (currentTime.diff(orderStartTime, "hours") >= 1) {
          console.log("More than 1 hour has passed since the order started.");

          const statusObject: any = await this.getNoLocationDineInModalStatus();

          console.log("statusObject", statusObject);

          if (
            statusObject &&
            statusObject.status &&
            statusObject.status === "closed" &&
            statusObject.dineIn
          ) {
            this.showDineInOrderStopAlert();
          }
        } else {
          console.log("Less than 1 hour has passed since the order started.");
        }
      }
    }
  }

  async getCurrentUser() {
    return new Promise((resolve, reject) => {
      this.store
        .select("auth")
        .pipe(first())
        .subscribe((authState) => {
          if (authState && authState.user) {
            resolve(authState.user);
          } else {
            resolve(null);
          }
        })
        .unsubscribe();
    });
  }

  async getDeliveryMethodAddress() {
    return new Promise((resolve, reject) => {
      this.store
        .select("deliveryMethodAddress")
        .pipe(first())
        .subscribe((state) => {
          if (state) {
            resolve(state);
          } else {
            resolve(null);
          }
        })
        .unsubscribe();
    });
  }

  public async setDineInOrderStartTimestamp() {
    const currentUser: any = await this.getCurrentUser();

    const deliveryMethodAddress: any = await this.getDeliveryMethodAddress();

    if (currentUser && currentUser.uposMoUser) {
      this.clearDineInOrderStartTimestamp();
      return false;
    }

    const timestamp = Date.now().toString();
    this.store.dispatch(
      new DeliveryMethodAddressActions.setDineInOrderStartTimestamp(timestamp)
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddress: any = window.localStorage.getItem(
        "deliveryMethodAddress"
      );

      if (deliveryMethodAddress) {
        deliveryMethodAddress = JSON.parse(deliveryMethodAddress);

        deliveryMethodAddress.dineInOrderStartTimestamp = timestamp;
        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddress)
        );
      }
    }

    if (this.intervalCheckingDineInStartTimestamp) {
      clearInterval(this.intervalCheckingDineInStartTimestamp);
      this.intervalCheckingDineInStartTimestamp = null;
    }

    this.intervalCheckingDineInStartTimestamp = setInterval(() => {
      this.checkDineInOrderStartTimestamp(
        deliveryMethodAddress.orderPickup,
        deliveryMethodAddress.dineIn
      );
    }, 3601000);
  }

  public clearDineInOrderStartTimestamp() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.setDineInOrderStartTimestamp(null)
    );
    if (this.intervalCheckingDineInStartTimestamp) {
      clearInterval(this.intervalCheckingDineInStartTimestamp);
      this.intervalCheckingDineInStartTimestamp = null;
    }

    if (navigator.cookieEnabled) {
      let deliveryMethodAddress: any = window.localStorage.getItem(
        "deliveryMethodAddress"
      );

      if (deliveryMethodAddress) {
        deliveryMethodAddress = JSON.parse(deliveryMethodAddress);

        deliveryMethodAddress.dineInOrderStartTimestamp = null;
        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddress)
        );
      }
    }
  }

  private clearCart() {
    this.store.dispatch(new CartActions.ClearCart());
    if (navigator.cookieEnabled) {
      window.localStorage.removeItem("cart");
    }
  }

  private clearDeliveryMethod() {
    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedAddress(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidStores(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setValidDeliveryAreas(_.cloneDeep(null))
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenNotValidAddressModal(false)
    );
    this.store.dispatch(
      new DeliveryMethodAddressActions.setOpenSelectStorePopupDelivery(false)
    );
    this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(null));
    this.store.dispatch(new DeliveryMethodAddressActions.setOrderPickup(null));

    this.store.dispatch(
      new DeliveryMethodAddressActions.setSelectedDineInTable(null)
    );

    if (navigator.cookieEnabled) {
      let deliveryMethodAddressObj: any = localStorage.getItem(
        "deliveryMethodAddress"
      );
      if (deliveryMethodAddressObj) {
        deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
        deliveryMethodAddressObj.dineIn = null;
        deliveryMethodAddressObj.orderPickup = null;
        deliveryMethodAddressObj.selectedAddress = null;
        deliveryMethodAddressObj.selectedDineInTable = null;

        localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
    }
  }

  async getStoreInfo() {
    return new Promise((resolve, reject) => {
      this.store
        .select(selectors.getStoreInfo)
        .pipe(
          filter((storeInfo) => !!storeInfo),
          first()
        )
        .subscribe((storeInfo) => {
          resolve(storeInfo);
        });
    });
  }

  private async fetchDineInTables() {
    const storeInfo: any = await this.getStoreInfo();
    return new Promise((resolve) => {
      this.dataStorageService.getDineInTables(storeInfo.store_id).subscribe({
        next: async (res: any) => {
          if (!res || !res.success) {
            resolve("error");
          } else {
            resolve(res.tables);
          }
        },
        error: async (error) => {
          resolve("error");
        },
      });
    });
  }

  public async dismissDineInOrderStopAlert() {
    if (this.dineInOrderStopAlert && this.dineInOrderStopAlert !== true) {
      await this.dineInOrderStopAlert.dismiss();
      this.dineInOrderStopAlert = null;
    } else if (this.dineInOrderStopAlert === true) {
      setTimeout(async () => {
        await this.dineInOrderStopAlert.dismiss();
        this.dineInOrderStopAlert = null;
      }, 100);
    }
  }

  private async showDineInOrderStopAlert() {
    if (!this.dineInOrderStopAlert) {
      this.dineInOrderStopAlert = true;
      const deliveryMethodAddressState: any =
        await this.getDeliveryMethodAddress();

      const dineInTable: any = deliveryMethodAddressState.selectedDineInTable;
      const allDineInTables: any = await this.fetchDineInTables();

      let uniqueDineInTableStore: boolean = false;
      if (
        allDineInTables &&
        _.isArray(allDineInTables) &&
        allDineInTables.length &&
        allDineInTables.length === 1
      ) {
        uniqueDineInTableStore = true;
      }

      this.dineInOrderStopAlert = await this.alertController.create({
        header: this.translateService.instant("on-going-order"),
        message: uniqueDineInTableStore
          ? this.translateService.instant(
              "do-you-want-to-continue-your-current-dine-in-order"
            )
          : this.translateService.instant(
              "do-you-want-to-continue-your-current-dine-in-order-at-table"
            ) +
            " " +
            this.translateService.instant(dineInTable.name) +
            "?",
        backdropDismiss: false,
        buttons: [
          {
            text: this.translateService.instant("new-order"),
            cssClass: "secondary",
            handler: (blah) => {
              this.clearDeliveryMethod();
              this.clearCart();
              if (
                this.router &&
                this.router.url &&
                !this.router.url.includes("home")
              ) {
                this.router.navigateByUrl("/homepage", {
                  replaceUrl: true,
                });
              }
            },
          },
          {
            text: this.translateService.instant("continue"),
            role: "cancel",
            handler: () => {},
          },
        ],
      });

      await this.dineInOrderStopAlert.present();
      await this.dineInOrderStopAlert.onDidDismiss().then((data) => {
        this.dineInOrderStopAlert = null;
      });
    }
  }
}
