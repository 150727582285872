import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import {
  AlertController,
  IonSelect,
  ModalController,
  Platform,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import {
  auditTime,
  debounceTime,
  distinctUntilChanged,
  Subscription,
} from "rxjs";
import * as fromApp from "../../store/app.reducer";
import * as selectors from "../../store/app.selectors";
import * as _ from "lodash";
import { TranslateService } from "@ngx-translate/core";
import { DataStorageService } from "src/app/services/data-storage/data-storage.service";
import { Loader } from "@googlemaps/js-api-loader";
import mapStyleJson from "../../../assets/mapStyle/mapStyle.json";
import { DeliveryAreasInfoModalComponent } from "src/app/delivery-areas/delivery-areas-info-modal/delivery-areas-info-modal.component";
import { FixedPointsAddressSelectionModalComponent } from "./fixed-points-address-selection-modal/fixed-points-address-selection-modal.component";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
@Component({
  selector: "app-selected-fixed-points-address-modal",
  templateUrl: "./selected-fixed-points-address-modal.component.html",
  styleUrls: ["./selected-fixed-points-address-modal.component.scss"],
})
export class SelectedFixedPointsAddressModalComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChildren("selectAddressPoint")
  ionSelectAddressPoint: QueryList<ElementRef>;
  public loader: any;
  public libraryLoading: boolean = false;
  public loading: boolean = false;
  public map: any;
  public deliveryAreas: any;
  public fixedPointsAddresses: any;
  public selected_fixed_address_id;
  public fixedAddressPointsByFixedAddressId;
  public renderCardDeliveryAreas: any;
  public hideDeliveryCard: boolean = false;
  public fetchFixedPointsLoading = false;
  public currentStore;
  public gpsMode;
  public fixedPointsAddressMarkers;

  private groupApp: any;
  private stores: any;
  private selectedLanguage: any;
  private initSelectOpen = false;
  private subs: Subscription[] = [];
  private fixedPointsAddressSelectionModal: any;
  private deliveryAreaInfoModal: any;
  private unsubscribeBackEvent: Subscription;

  constructor(
    private modalController: ModalController,
    private store: Store<fromApp.AppState>,
    private changeDetector: ChangeDetectorRef,
    private toastController: ToastController,
    private translateService: TranslateService,
    private dataStorageService: DataStorageService,
    private alertController: AlertController,

    private platform: Platform
  ) {}

  ngAfterViewInit(): void {}

  fetchFixedPointsAdresses(group) {
    this.fetchFixedPointsLoading = true;
    this.dataStorageService.fetchFixedPointsAddresses(group).subscribe({
      next: async (res: any) => {
        this.fetchFixedPointsLoading = false;
        if (res && res.success) {
          this.store.dispatch(
            new DeliveryMethodAddressActions.SetFixedPointsAddresses(
              res.fixedDeliveryAddresses
            )
          );
          if (
            res &&
            res.fixedDeliveryAddresses &&
            res.fixedDeliveryAddresses.length
          ) {
            setTimeout(() => {
              if (!this.fixedPointsAddressSelectionModal) {
                this.fixedPointsAddressSelectionModal = true;
                this.openFixedPointsAddressSelectionModal();
              }
            }, 0);
          }
        } else {
          console.log("error fetch fixed points addresses");
        }
      },
      error: async (err) => {
        this.fetchFixedPointsLoading = false;
        console.log("error fetch user ixed points addresses (no response)");
        return err;
      },
    });
  }

  ngOnInit() {
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      300,
      () => {
        if (this.platform.is("android")) {
          this.modalController.dismiss();
        }
      }
    );

    this.subs.push(
      this.store
        .select("deliveryMethodAddress")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            !_.isEqual(this.fixedPointsAddresses, state.fixedPointsAddresses)
          ) {
            this.fixedPointsAddresses = _.cloneDeep(state.fixedPointsAddresses);
            if (this.fixedPointsAddresses) {
              this.fixedAddressPointsByFixedAddressId = {};
              _.each(this.fixedPointsAddresses, (fixedPoint) => {
                if (fixedPoint.fixed_address_id) {
                  this.fixedAddressPointsByFixedAddressId[
                    fixedPoint.fixed_address_id
                  ] = _.cloneDeep(fixedPoint);
                }
              });
            }
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.fixedPointsAddresses &&
              this.fixedPointsAddresses.length
            ) {
              this.initEmptySelectedPoint();
              this.createMapsScript();
            }
          }
        })
    );

    this.subs.push(
      this.store
        .select("groupApp")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (
            state &&
            state.groupApp &&
            !_.isEqual(this.groupApp, state.groupApp)
          ) {
            this.groupApp = _.cloneDeep(state.groupApp);
            this.fetchFixedPointsAdresses(this.groupApp.group);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.fixedPointsAddresses &&
              this.fixedPointsAddresses.length
            ) {
              this.initEmptySelectedPoint();
              this.createMapsScript();
            }
          }
          if (
            state &&
            state.selectedLangugage &&
            this.groupApp &&
            !_.isEqual(this.selectedLanguage, state.selectedLangugage)
          ) {
            this.selectedLanguage = _.cloneDeep(state.selectedLangugage);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.fixedPointsAddresses &&
              this.fixedPointsAddresses.length
            ) {
              this.initEmptySelectedPoint();
              this.createMapsScript();
            }
          }
        })
    );

    this.subs.push(
      this.store
        .select("stores")
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((state) => {
          if (state && state.stores && !_.isEqual(state.stores, this.stores)) {
            this.stores = _.cloneDeep(state.stores);
            if (
              this.groupApp &&
              this.selectedLanguage &&
              this.stores &&
              this.fixedPointsAddresses &&
              this.fixedPointsAddresses.length
            ) {
              this.initEmptySelectedPoint();
              this.createMapsScript();
            }
          }
        })
    );

    this.subs.push(
      this.store
        .select(selectors.getStoreInfo)
        .pipe(distinctUntilChanged())
        .pipe(auditTime(100))
        .subscribe((storeInfo) => {
          if (storeInfo && !_.isEqual(storeInfo, this.currentStore)) {
            this.currentStore = _.cloneDeep(storeInfo);
          }
        })
    );
  }

  async openFixedPointsAddressSelectionModal() {
    this.fixedPointsAddressSelectionModal = await this.modalController.create({
      component: FixedPointsAddressSelectionModalComponent,
      cssClass: "fixed-points-address-selection-modal",
      animated: true,

      backdropDismiss: false,
      componentProps: {},
    });

    await this.fixedPointsAddressSelectionModal.present();
    await this.fixedPointsAddressSelectionModal.onDidDismiss().then((data) => {
      this.fixedPointsAddressSelectionModal = null;

      if (data && data.data) {
        this.selected_fixed_address_id = data.data;
        this.changeFixedPointAddress(data.data);
      }
    });
  }

  initEmptySelectedPoint() {
    // if (
    //   !this.selectedFixedAddressPoint &&
    //   this.fixedPointsAddresses[0] &&
    //   this.fixedPointsAddresses[0].address
    // ) {
    //   this.selectedFixedAddressPoint = _.cloneDeep(
    //     this.fixedPointsAddresses[0].address
    //   );
    //   this.selected_fixed_address_id =
    //     this.fixedPointsAddresses[0].fixed_address_id;
    // }
  }

  changeFixedPointAddress(ev) {
    if (this.selected_fixed_address_id) {
      this.setFixedAddressPointsMarkers(true);
      this.checkMarker();
    }
  }

  trackDeliveryAreas(index, item) {
    return index;
  }

  setFixedAddressPointsMarkers(setZoomToSelected) {
    _.each(this.fixedPointsAddressMarkers, (marker) => {
      marker.marker.setMap(null);
    });
    this.fixedPointsAddressMarkers = [];

    _.each(this.fixedPointsAddresses, (pointAddress) => {
      const address = pointAddress.address;
      this.fixedPointsAddressMarkers.push({
        marker: new google.maps.Marker({
          animation: google.maps.Animation.DROP,
          icon:
            this.selected_fixed_address_id === pointAddress.fixed_address_id
              ? "assets/ionicons/marker-red.png"
              : "assets/ionicons/marker-blue.png",

          position: {
            lat: address.userLat ? parseFloat(address.userLat) : 0,
            lng: address.userLng ? parseFloat(address.userLng) : 0,
          },
          map: this.map,
          anchorPoint: new google.maps.Point(0, -29),
          draggable: false,
        }),
        address_id: address.address_id,
        infoBox: new google.maps.InfoWindow({
          content:
            "<h6 style='padding:0;margin:2px;'>" +
            (address.formatted_address ? address.formatted_address : "") +
            "</h6>",

          disableAutoPan: true,
        }),
        infoWindowIsOpen: false,
      });

      _.each(this.fixedPointsAddressMarkers, (marker) => {
        marker.marker.setVisible(true);
      });

      if (
        this.selected_fixed_address_id === pointAddress.fixed_address_id &&
        setZoomToSelected
      ) {
        this.map.setZoom(12);
        setTimeout(() => {
          this.map.setCenter({
            lat: address.userLat,
            lng: address.userLng,
          });
        }, 450);
        setTimeout(() => {
          this.map.setZoom(19);
        }, 1500);
      }
    });
    this.changeDetector.detectChanges();
  }

  calculateRenderedDeliveryAreas() {
    if (this.deliveryAreas && this.deliveryAreas.length) {
      this.renderCardDeliveryAreas = _.cloneDeep(this.deliveryAreas);
    } else {
      this.renderCardDeliveryAreas = [];
    }
    if (
      this.deliveryAreas &&
      this.deliveryAreas.length &&
      this.stores &&
      this.stores.length == 1
    ) {
      let areaWithShedule = _.find(this.deliveryAreas, (area) => {
        if (area.showSchedule) {
          return true;
        }
      });
      if (!areaWithShedule) {
        this.renderCardDeliveryAreas = _.cloneDeep([
          _.head(
            _.orderBy(
              this.deliveryAreas,
              ["minOrder", "deliveryCost"],
              ["asc", "asc"]
            )
          ),
        ]);
      }
    } else if (
      this.deliveryAreas &&
      this.deliveryAreas.length &&
      this.stores &&
      this.stores.length > 1
    ) {
      let areaWithShedule = _.find(this.deliveryAreas, (area) => {
        if (area.showSchedule) {
          return true;
        }
      });
      if (!areaWithShedule) {
        let tempAreas = [];
        _.each(this.stores, (store) => {
          tempAreas.push(
            _.cloneDeep(
              _.head(
                _.orderBy(
                  this.deliveryAreas.filter(
                    (area) => store.store_id === area.store_id
                  ),
                  ["minOrder", "deliveryCost"],
                  ["asc", "asc"]
                )
              )
            )
          );
        });

        if (tempAreas && tempAreas.length > 0) {
          tempAreas = _.cloneDeep(
            tempAreas.filter((element) => {
              return element !== undefined;
            })
          );
          this.renderCardDeliveryAreas = tempAreas;
          //  console.log("tempsareas", this.renderCardDeliveryAreas);
        }
      }
    }
    this.hideDeliveryCard = false;
    //console.log("rendered delivery areas", this.renderCardDeliveryAreas);
  }

  checkMarker() {
    const selectedFixedAddressPoint = _.find(this.fixedPointsAddresses, {
      fixed_address_id: this.selected_fixed_address_id,
    });

    if (selectedFixedAddressPoint && selectedFixedAddressPoint.address) {
      this.hideDeliveryCard = true;
      const address = _.cloneDeep(selectedFixedAddressPoint.address);
      this.renderCardDeliveryAreas = null;
      const data = {
        lat: address.userLat,
        lng: address.userLng,
        group: this.groupApp.group,
        //store_id: selectedFixedAddressPoint.store_id,
        fixed_address_id: this.selected_fixed_address_id,
        languageCode: this.selectedLanguage,
      };
      this.changeDetector.detectChanges();

      this.dataStorageService
        .checkMarker(data)

        .subscribe({
          next: async (res: any) => {
            if (res && res.success) {
              this.deliveryAreas = res.deliveryAreas;
              this.calculateRenderedDeliveryAreas();
              this.hideDeliveryCard = false;
              this.changeDetector.detectChanges();
            } else {
              const alert = await this.alertController.create({
                header: this.translateService.instant("alert"),
                message:
                  res && res.comment_id
                    ? this.translateService.instant(res.comment_id)
                    : this.translateService.instant(
                        "errorMessages.general_error"
                      ),

                buttons: ["OK"],
              });
              await alert.present();
              this.hideDeliveryCard = false;

              this.changeDetector.detectChanges();
            }
            console.log("res", res);

            this.changeDetector.detectChanges();
          },
          error: async (err) => {
            const alert = await this.alertController.create({
              header: this.translateService.instant("alert"),
              message: this.translateService.instant(
                "errorMessages.problem_reaching_server"
              ),

              buttons: ["OK"],
            });
            await alert.present();
            this.hideDeliveryCard = false;

            this.changeDetector.detectChanges();
            return err;

            console.log("error updating the information");
          },
        });
    }
  }

  createMapsScript() {
    if (!document.getElementById("googleMapsLoader")) {
      this.loader = new Loader({
        id: "googleMapsLoader",
        apiKey: this.groupApp.googleMapsKey,
        language: this.selectedLanguage,
        libraries: ["geometry", "drawing", "places"],
      });
      this.loader.load().then(() => {
        console.log("library loaded");
        this.libraryLoading = false;
        this.changeDetector.detectChanges();
        this.mapInitializations();
        this.setFixedAddressPointsMarkers(false);
      });
    } else {
      this.libraryLoading = false;
      this.changeDetector.detectChanges();
      this.mapInitializations();
      this.setFixedAddressPointsMarkers(false);
    }
  }

  async openDeliveryAreaInfoModal(area) {
    this.deliveryAreaInfoModal = await this.modalController.create({
      component: DeliveryAreasInfoModalComponent,
      cssClass: "openDeliveryAreaInfoModal",
      backdropDismiss: false,
      componentProps: {
        currentDeliveryArea: area,
      },
    });
    await this.deliveryAreaInfoModal.present();

    this.deliveryAreaInfoModal.onDidDismiss().then(() => {
      this.deliveryAreaInfoModal = null;
    });
  }

  ondismiss() {
    this.modalController.dismiss();
  }

  mapInitializations() {
    this.map = new google.maps.Map(
      document.getElementById("map") as HTMLElement,
      {
        zoom: parseInt(this.groupApp.groupMapZoom),
        gestureHandling: "greedy",
        center: {
          lat: parseFloat(this.groupApp.groupMapCenterLat),
          lng: parseFloat(this.groupApp.groupMapCenterLng),
        },

        zoomControl: true,
        scaleControl: false,
        scrollwheel: true,
        disableDefaultUI: true,
        mapTypeControl: false,
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: mapStyleJson,
      }
    );
    this.deliveryAreasInitialization(this.map);

    this.map.setZoom(13);

    this.changeDetector.detectChanges();
  }

  deliveryAreasInitialization(map) {
    _.each(this.stores, (store, key, list) => {
      if (_.isEmpty(store.deliveryAreas) && store.boundaryCoordinates1) {
        console.log("Creating the group's delivery areas...");
        console.log("How many stores the group has: ", this.stores.length);
        //Polygon coordinates
        var boundary = store.boundaryCoordinates1;
        var boundaryData = [];
        var latlngMap = boundary.split(",");
        var lat = [];
        var lng = [];
        for (var i = 0; i < latlngMap.length; i++) {
          if (i % 2 === 0) {
            lat.push(latlngMap[i]);
          } else {
            lng.push(latlngMap[i]);
          }
        }
        for (i = 0; i < latlngMap.length / 2; i++) {
          boundaryData.push(new google.maps.LatLng(lat[i], lng[i]));
        }

        new google.maps.Polygon({
          paths: boundaryData,
          strokeColor: "#0000FF",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FFCC00", //YEllOW
          fillOpacity: 0.3,
          zIndex: 10,
          map: map,
        });
      } else {
        console.log("The store has the new deliveryAreas", store.deliveryAreas);
        _.each(store.deliveryAreas, (area, k, l) => {
          if (!area.active) {
            return;
          }

          var boundary = area.boundaries;
          var boundaryData = [];
          var latlngMap = boundary.split(",");
          var lat = [];
          var lng = [];
          for (var i = 0; i < latlngMap.length; i++) {
            if (i % 2 === 0) {
              lat.push(parseFloat(latlngMap[i]));
            } else {
              lng.push(parseFloat(latlngMap[i]));
            }
          }
          for (i = 0; i < latlngMap.length / 2; i++) {
            boundaryData.push(new google.maps.LatLng(lat[i], lng[i]));
          }

          new google.maps.Polygon({
            paths: boundaryData,
            strokeColor: area.strokeColor ? area.strokeColor : "#0000FF",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: area.fillColor ? area.fillColor : "#FFCC00",
            fillOpacity: area.fillOpacity ? parseFloat(area.fillOpacity) : 0.3,
            zIndex: 10,
            map: map,
          });
        });
      }
    });
  }

  ngOnDestroy() {
    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
    if (this.deliveryAreaInfoModal) {
      this.deliveryAreaInfoModal.dismiss();
      this.deliveryAreaInfoModal = null;
    }

    if (this.fixedPointsAddressSelectionModal) {
      this.fixedPointsAddressSelectionModal.dismiss();
      this.deliveryAreaInfoModal = null;
    }

    if (this.subs && this.subs.length > 0) {
      this.subs.forEach((sub) => {
        sub.unsubscribe();
      });
    }
    this.subs = [];
  }

  selectedFixedPointAddress() {
    this.modalController.dismiss(this.selected_fixed_address_id);
  }
}
