import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  AlertController,
  ModalController,
  ToastController,
} from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, first } from "rxjs";
import { NewAddressModalComponent } from "src/app/address/new-address-modal/new-address-modal.component";
import { FirebaseRecaptchaLoginComponent } from "src/app/popups/firebase-recaptcha-login/firebase-recaptcha-login.component";
import { FirebaseRecaptchaComponent } from "src/app/popups/firebase-recaptcha/firebase-recaptcha.component";
import { MagicLoginConfirmationComponent } from "src/app/popups/magic-login-confirmation/magic-login-confirmation.component";
import { OrderNowSettingsPopupComponent } from "src/app/popups/order-now-popup/order-now-settings-popup/order-now-settings-popup.component";
import { currentStoreID } from "src/app/store/app.selectors";
import * as AuthActions from "../../auth/store/auth.actions";
import * as fromApp from "../../store/app.reducer";
import { AnalyticsServiceService } from "../analitycsService/analytics-service.service";
import * as DeliveryMethodAddressActions from "../../store/deliveryMethodAddress/deliveryMethodAddress.actions";
import * as CurrentStoreActions from "../../store/current-store/current-store.actions";
import * as _ from "lodash";
import { DineInModalComponent } from "src/app/popups/dine-in-modal/dine-in-modal.component";
import { Capacitor } from "@capacitor/core";
import { SelectStoreImagePopupComponent } from "src/app/popups/select-store-image-popup/select-store-image-popup.component";
import { DeliveryInfoModalComponent } from "src/app/popups/delivery-info-modal/delivery-info-modal.component";
import { SelectedFixedPointsAddressModalComponent } from "src/app/address/selected-fixed-points-address-modal/selected-fixed-points-address-modal.component";
import { fixedAddressesService } from "../fixedAddressesService/fixed-addresses.service";
import { v4 as uuid } from "uuid";
import { FixedPointsAddressConfirmationPopupComponent } from "src/app/address/selected-fixed-points-address-modal/fixed-points-address-confirmation-popup/fixed-points-address-confirmation-popup.component";
import { DineinValidationsService } from "../dineInValidations/dinein-validations.service";
declare let window: any;
@Injectable({
  providedIn: "root",
})
export class OrderNowModalPopupsService {
  private settingsModal: any;
  private addNewAddressModal: any;
  private firebaseRecaptchaModal: any;
  private groupApp: any;
  private dineInModal: any;
  private deliveryInfoModal;
  private selectedLangugage;
  private baseLanguage;
  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private translateService: TranslateService,
    private toastController: ToastController,
    private fixedAddressesService: fixedAddressesService,
    private dineInValidationsService: DineinValidationsService,
    private store: Store<fromApp.AppState>,
    private analyticsService: AnalyticsServiceService,
    private router: Router
  ) {
    console.log("service constructor called");
    this.groupAppSubscription();
  }

  public groupAppSubscription() {
    this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.groupApp &&
          !_.isEqual(this.groupApp, state.groupApp)
        ) {
          this.groupApp = _.cloneDeep(state.groupApp);

          const moBaseLanguage = _.find(this.groupApp.languages, {
            mo_base: true,
          });
          if (moBaseLanguage && moBaseLanguage.code) {
            this.baseLanguage = moBaseLanguage.code;
          }
        }
        if (
          state &&
          state.selectedLangugage &&
          !_.isEqual(this.selectedLangugage, state.selectedLangugage)
        ) {
          this.selectedLangugage = _.cloneDeep(state.selectedLangugage);
        }
      });
  }

  private async presentSettingsModal(mode, stores) {
    if (
      mode === "take-away" &&
      this.groupApp &&
      this.groupApp.images_select_store_mobileorder
    ) {
      this.settingsModal = await this.modalCtrl.create({
        component: SelectStoreImagePopupComponent,
        cssClass: "selectStoreImagePopup",
        backdropDismiss: true,
        componentProps: {
          settingsPopupMode: true,
        },
      });
      await this.settingsModal.present();
      this.settingsModal.onDidDismiss().then(() => {
        this.settingsModal = null;
      });
    } else {
      let cssClass = "orderNowSettingsPopup";
      if (mode === "take-away") {
        if (stores && stores.length > 1 && stores.length <= 3) {
          cssClass = "orderNowSettingsPopupThreeStores";
        } else if (stores && stores.length > 3) {
          cssClass = "orderNowSettingsPopupMultipleStores";
        }
      }

      if (
        mode === "delivery" &&
        this.groupApp &&
        this.groupApp.fixedPointsAddressSystemActive
      ) {
        const confirmation = await this.fixedPointsAddressPrompt();

        if (confirmation && confirmation === "new-address") {
          this.openSettingsPopupModal(mode, cssClass);
        } else if (confirmation && confirmation === "fixed-points-selection") {
          this.settingsModal = await this.modalCtrl.create({
            component: SelectedFixedPointsAddressModalComponent,
            cssClass: "",
            backdropDismiss: false,
            componentProps: {},
          });
          await this.settingsModal.present();
          this.settingsModal.onDidDismiss().then(async (data) => {
            this.settingsModal = null;
            if (data && data.data) {
              //fixed address id here

              const authState: any = await this.getStateObject("auth");
              const deliveryMethodAddressState: any = await this.getStateObject(
                "deliveryMethodAddress"
              );

              if (
                deliveryMethodAddressState &&
                deliveryMethodAddressState.fixedPointsAddresses &&
                deliveryMethodAddressState.fixedPointsAddresses.length
              ) {
                const selectedFixedAddress = _.find(
                  _.cloneDeep(deliveryMethodAddressState.fixedPointsAddresses),
                  { fixed_address_id: data.data }
                );
                if (selectedFixedAddress && selectedFixedAddress.address) {
                  const address = _.cloneDeep(selectedFixedAddress.address);

                  address.fixed_address_id = data.data;
                  address.fixed_address_name = _.cloneDeep(
                    selectedFixedAddress.name
                  );
                  address.fixed_address_type = _.cloneDeep(
                    selectedFixedAddress.type
                  );
                  if (authState.user && authState.mo_token && address) {
                    let new_user = _.cloneDeep(authState.user);
                    if (!new_user.addresses) {
                      new_user.addresses = [];
                    }

                    if (
                      !_.find(new_user.addresses, {
                        address_id: address.address_id,
                      })
                    ) {
                      new_user.addresses.push(_.cloneDeep(address));
                    }

                    //await for response and then select the address
                    const response =
                      await this.fixedAddressesService.updateCustomerData(
                        { updatedData: _.cloneDeep(new_user) },
                        authState.mo_token
                      );

                    if (response === "success") {
                      this.fixedAddressesService.selectAddress(address);
                    } else {
                      this.presentErrorToast(
                        this.translateService.instant(
                          "errorMessages.something_went_wrong_try_again"
                        ),
                        "danger"
                      );
                    }
                  } else if (!authState.user && address) {
                    this.fixedAddressesService.selectAddress(address);
                  }
                }
              }
            }
          });
        }
      } else {
        this.openSettingsPopupModal(mode, cssClass);
      }
    }
  }

  private async openSettingsPopupModal(mode, cssClass) {
    this.settingsModal = await this.modalCtrl.create({
      component: OrderNowSettingsPopupComponent,
      cssClass: cssClass,
      backdropDismiss: false,
      componentProps: {
        mode: mode,
      },
    });
    await this.settingsModal.present();
    this.settingsModal.onDidDismiss().then(() => {
      this.settingsModal = null;
    });
  }

  private getStateObject(object) {
    return new Promise((resolve) => {
      this.store
        .select(object)
        .pipe(first())
        .subscribe((state) => {
          resolve(_.cloneDeep(state));
        })
        .unsubscribe();
    });
  }

  async fixedPointsAddressPrompt() {
    return new Promise(async (resolve) => {
      const modal = await this.modalCtrl.create({
        component: FixedPointsAddressConfirmationPopupComponent,
        cssClass: "fixedPointsAddressPrompt",
        backdropDismiss: false,
        componentProps: {},
      });
      await modal.present();

      await modal.onDidDismiss().then((data) => {
        if (data && data.data) {
          resolve(data.data);
        }
        resolve("");
      });
    });
  }

  closeSettingsModal() {
    if (this.settingsModal) {
      this.settingsModal.dismiss();
      this.settingsModal = null;
    }
  }

  async addNewAddress() {
    this.addNewAddressModal = await this.modalCtrl.create({
      component: NewAddressModalComponent,
      cssClass: "my-custom-class",
      backdropDismiss: false,
    });
    await this.addNewAddressModal.present();
    this.addNewAddressModal.onDidDismiss().then(() => {
      this.addNewAddressModal = null;
    });
  }

  closeNewAddressModal() {
    if (this.addNewAddressModal) {
      this.addNewAddressModal.dismiss();
      this.addNewAddressModal = null;
    }
  }

  async presentFirebaseRecaptchaModal() {
    this.firebaseRecaptchaModal = await this.modalCtrl.create({
      component: FirebaseRecaptchaComponent,
      backdropDismiss: false,
      cssClass: "fireBaseRecaptchaModal",
    });
    await this.firebaseRecaptchaModal.present();

    this.firebaseRecaptchaModal
      .onDidDismiss()
      .then(() => {
        this.firebaseRecaptchaModal = null;
        this.store
          .select("firebaseRecaptcha")
          .pipe(first())
          .subscribe((state) => {
            if (state && state.token && state.token !== "no-token") {
              this.checkOpenAddNewAddress();
            } else {
            }
          })
          .unsubscribe();
      })
      .finally(() => {});
  }

  closeFirebaseRecaptchaModal() {
    if (this.firebaseRecaptchaModal) {
      this.firebaseRecaptchaModal.dismiss();
      this.firebaseRecaptchaModal = null;
    }
  }

  selectDeliveryMethod(mode, navigationOnDeliveryMethodChange) {
    if (mode === "delivery") {
      this.store.dispatch(
        new DeliveryMethodAddressActions.setOrderPickup(false)
      );
      this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(false));
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedDineInTable(
          _.cloneDeep(null)
        )
      );

      this.analyticsService.dmAnalyticsMessage(
        "delivery_method",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      if (navigator.cookieEnabled) {
        let deliveryMethodAddressObj: any = localStorage.getItem(
          "deliveryMethodAddress"
        );
        if (deliveryMethodAddressObj) {
          deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj.orderPickup = false;
            deliveryMethodAddressObj.dineIn = false;
            deliveryMethodAddressObj.selectedDineInTable = null;
          } else {
            deliveryMethodAddressObj = {
              orderPickup: false,
              dineIn: false,
            };
          }
        } else {
          deliveryMethodAddressObj = {
            orderPickup: false,
            dineIn: false,
          };
        }

        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
      if (
        (this.router.url === "/homepage" ||
          this.router.url === "/ratings" ||
          this.router.url === "/orders" ||
          this.router.url === "/points") &&
        navigationOnDeliveryMethodChange
      ) {
        this.router.navigateByUrl("/catalog", {
          replaceUrl: true,
        });
      }
    } else if (mode === "take-away") {
      console.log("tryiing to change to take away");
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedAddress(null)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setOrderPickup(true)
      );
      this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(false));
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedDineInTable(
          _.cloneDeep(null)
        )
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
      );

      this.analyticsService.dmAnalyticsMessage(
        "takeaway_method",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );

      if (navigator.cookieEnabled) {
        let deliveryMethodAddressObj: any = localStorage.getItem(
          "deliveryMethodAddress"
        );
        if (deliveryMethodAddressObj) {
          deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj.orderPickup = true;
            deliveryMethodAddressObj.dineIn = false;
            deliveryMethodAddressObj.selectedAddress = null;
            deliveryMethodAddressObj.selectedDineInTable = null;
          } else {
            deliveryMethodAddressObj = {
              orderPickup: true,
              dineIn: false,
            };
          }
        } else {
          deliveryMethodAddressObj = {
            orderPickup: true,
            dineIn: false,
          };
        }

        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
      if (
        (this.router.url === "/homepage" ||
          this.router.url === "/ratings" ||
          this.router.url === "/orders" ||
          this.router.url === "/points") &&
        navigationOnDeliveryMethodChange
      ) {
        this.router.navigateByUrl("/catalog", {
          replaceUrl: true,
        });
      }
    } else if (mode === "dine-in") {
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedAddress(null)
      );
      this.store.dispatch(
        new DeliveryMethodAddressActions.setSelectedPreorderDate(null)
      );

      this.store.dispatch(
        new DeliveryMethodAddressActions.setOrderPickup(false)
      );
      this.store.dispatch(new DeliveryMethodAddressActions.setDineIn(true));
      this.store.dispatch(
        new DeliveryMethodAddressActions.setValidDeliveryAreas(null)
      );

      this.analyticsService.dmAnalyticsMessage(
        "dinein_method",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      if (navigator.cookieEnabled) {
        let deliveryMethodAddressObj: any = localStorage.getItem(
          "deliveryMethodAddress"
        );
        if (deliveryMethodAddressObj) {
          deliveryMethodAddressObj = JSON.parse(deliveryMethodAddressObj);
          if (deliveryMethodAddressObj) {
            deliveryMethodAddressObj.orderPickup = false;
            deliveryMethodAddressObj.dineIn = true;
            deliveryMethodAddressObj.selectedPreorderDate = null;
          } else {
            deliveryMethodAddressObj = {
              orderPickup: false,
              dineIn: true,
            };
          }
        } else {
          deliveryMethodAddressObj = {
            orderPickup: false,
            dineIn: true,
          };
        }

        window.localStorage.setItem(
          "deliveryMethodAddress",
          JSON.stringify(deliveryMethodAddressObj)
        );
      }
      this.dineInValidationsService.setDineInOrderStartTimestamp();
      if (
        (this.router.url === "/homepage" ||
          this.router.url === "/ratings" ||
          this.router.url === "/orders" ||
          this.router.url === "/points") &&
        navigationOnDeliveryMethodChange
      ) {
        this.router.navigateByUrl("/catalog", {
          replaceUrl: true,
        });
      }
    }
  }

  getGroupApp() {
    return new Promise((resolve) => {
      this.store
        .select("groupApp")
        .pipe(first())
        .subscribe((groupState) => {
          if (groupState && groupState.groupApp) {
            resolve(groupState.groupApp);
          } else {
            resolve({});
          }
        })
        .unsubscribe();
    });
  }

  async checkAndOpenSettingsModal(
    mode,
    currentUser,
    selectedAddress,
    stores,
    currentStore_id,
    navigationOnDeliveryMethodChanged,
    modalDismiss
  ) {
    const groupApp: any = await this.getGroupApp();
    if (mode === "take-away") {
      return this.pickupOrder(
        mode,
        stores,
        currentStore_id,
        navigationOnDeliveryMethodChanged,
        modalDismiss
      );
    } else if (mode === "delivery") {
      this.analyticsService.dmAnalyticsMessage(
        "selected_delivery_method",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );

      if (
        (currentUser &&
          currentUser.addresses &&
          currentUser.addresses.length) ||
        (groupApp && groupApp.vatBasedCustomerAddressesActive)
      ) {
        console.log("present settings modal");
        this.presentSettingsModal(mode, stores);
        if (modalDismiss) {
          this.modalCtrl.dismiss();
        }
      } else {
        console.log("present add new address modal");
        if (!selectedAddress && !currentUser) {
          if (!Capacitor.isNativePlatform()) {
            this.store
              .select("firebaseRecaptcha")
              .pipe(first())
              .subscribe((state) => {
                if (state && state.token && state.token !== "no-token") {
                  this.checkOpenAddNewAddress();
                } else {
                  this.presentFirebaseRecaptchaModal();
                }
              })
              .unsubscribe();
          } else if (Capacitor.isNativePlatform()) {
            this.checkOpenAddNewAddress();
          }
        } else {
          this.checkOpenAddNewAddress();
        }
        if (modalDismiss) {
          this.modalCtrl.dismiss();
        }
      }
    } else if (mode === "dine-in") {
      this.openDineInModal(null, null);
      if (modalDismiss) {
        this.modalCtrl.dismiss();
      }
    } else if (mode === "delivery-info-modal") {
      this.openDeliveryInfoModal();
    }
  }

  checkOpenAddNewAddress() {
    this.store
      .select("groupApp")
      .pipe(first())
      .subscribe(async (state) => {
        if (
          state &&
          state.groupApp &&
          state.groupApp.fixedPointsAddressSystemActive
        ) {
          const confirmation = await this.fixedPointsAddressPrompt();

          if (confirmation && confirmation === "new-address") {
            this.addNewAddress();
          } else if (
            confirmation &&
            confirmation === "fixed-points-selection"
          ) {
            this.settingsModal = await this.modalCtrl.create({
              component: SelectedFixedPointsAddressModalComponent,
              cssClass: "",
              backdropDismiss: false,
              componentProps: {},
            });
            await this.settingsModal.present();
            this.settingsModal.onDidDismiss().then(async (data) => {
              this.settingsModal = null;
              if (data && data.data) {
                //fixed address id here

                const authState: any = await this.getStateObject("auth");
                const deliveryMethodAddressState: any =
                  await this.getStateObject("deliveryMethodAddress");

                if (
                  deliveryMethodAddressState &&
                  deliveryMethodAddressState.fixedPointsAddresses &&
                  deliveryMethodAddressState.fixedPointsAddresses.length
                ) {
                  const selectedFixedAddress = _.find(
                    _.cloneDeep(
                      deliveryMethodAddressState.fixedPointsAddresses
                    ),
                    { fixed_address_id: data.data }
                  );
                  if (selectedFixedAddress && selectedFixedAddress.address) {
                    const address = _.cloneDeep(selectedFixedAddress.address);

                    address.fixed_address_id = data.data;
                    address.fixed_address_name = _.cloneDeep(
                      selectedFixedAddress.name
                    );
                    address.fixed_address_type = _.cloneDeep(
                      selectedFixedAddress.type
                    );

                    if (authState.user && authState.mo_token && address) {
                      let new_user = _.cloneDeep(authState.user);
                      if (!new_user.addresses) {
                        new_user.addresses = [];
                      }

                      if (
                        !_.find(new_user.addresses, {
                          address_id: address.address_id,
                        })
                      ) {
                        new_user.addresses.push(_.cloneDeep(address));
                      }

                      //await for response and then select the address
                      const response =
                        await this.fixedAddressesService.updateCustomerData(
                          { updatedData: _.cloneDeep(new_user) },
                          authState.mo_token
                        );

                      if (response === "success") {
                        this.fixedAddressesService.selectAddress(address);
                      } else {
                        this.presentErrorToast(
                          this.translateService.instant(
                            "errorMessages.something_went_wrong_try_again"
                          ),
                          "danger"
                        );
                      }
                    } else if (!authState.user && address) {
                      this.fixedAddressesService.selectAddress(address);
                    }
                  }
                }
              }
            });
          }
        } else {
          this.addNewAddress();
        }
      });
  }

  pickupOrder(
    mode,
    stores,
    currentStoreId,
    navigationOnDeliveryMethodChanged,
    modalDismiss
  ) {
    if (stores.length > 1 || this.groupApp.pickupModal) {
      this.presentSettingsModal(mode, stores);
      if (modalDismiss) {
        this.modalCtrl.dismiss();
      }
    } else {
      this.store.dispatch(
        new CurrentStoreActions.SetCurrentStoreId(stores[0].store_id)
      );
      if (navigator.cookieEnabled) {
        window.localStorage.setItem("store_id", currentStoreId);
      }
      if (modalDismiss) {
        this.modalCtrl.dismiss();
      }
      this.selectDeliveryMethod(mode, navigationOnDeliveryMethodChanged);
    }
  }

  async openDineInModal(selectedTableId, selectedStoreId) {
    this.dineInModal = await this.modalCtrl.create({
      component: DineInModalComponent,
      cssClass: "dineInModal",
      componentProps: {
        selectedTableIdFromLink: selectedTableId,
        selectedStoreFromLink: selectedStoreId,
      },
    });
    await this.dineInModal.present();
    this.dineInModal.onDidDismiss().then(() => {
      this.dineInModal = null;
    });
  }

  closeDineInModal() {
    if (this.dineInModal) {
      this.dineInModal.dismiss();
      this.dineInModal = null;
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  // checkTheCatalog() {
  //   if (stores.length > 1) {
  //     this.presentSelectStoreModal();
  //   } else {
  //     this.store.dispatch(
  //       new CurrentStoreActions.SetCurrentStoreId(stores[0].store_id)
  //     );
  //     if (navigator.cookieEnabled) {
  //       window.localStorage.setItem("store_id", this.currentStoreId);
  //     }

  //     this.router.navigate(["catalog"]);
  //   }
  // }

  // async presentSelectStoreModal() {
  //   const modal = await this.modalCtrl.create({
  //     component: SelectStorePopupComponent,
  //     cssClass: "selectStoreInPaymentCardsPopup",
  //     backdropDismiss: false,
  //     componentProps: {},
  //   });

  //   await modal.present();
  //   modal.onDidDismiss().then((data) => {});
  // }

  checkToShowInfoDeliveryButton() {
    return new Promise((resolve) => {
      this.store
        .select("groupApp")
        .pipe(first())
        .subscribe((groupState) => {
          const groupApp = groupState.groupApp;

          if (
            groupApp &&
            groupApp.design_settings &&
            groupApp.design_settings.infoShowDeliveryButton
          ) {
            resolve(true);
          }

          resolve(false);
        })
        .unsubscribe();
    });
  }

  async openDeliveryInfoModal() {
    await this.modalCtrl.dismiss();

    this.deliveryInfoModal = await this.modalCtrl.create({
      component: DeliveryInfoModalComponent,
      cssClass: "delivery-info-modal",
      animated: true,
      componentProps: {
        message:
          this.groupApp &&
          this.groupApp.design_settings &&
          this.groupApp.design_settings.disableDeliveryInfo
            ? this.groupApp.design_settings.disableDeliveryInfo.message
            : null,
        button_one:
          this.groupApp &&
          this.groupApp.design_settings &&
          this.groupApp.design_settings.disableDeliveryInfo
            ? this.groupApp.design_settings.disableDeliveryInfo.button_one
            : null,
        button_two:
          this.groupApp &&
          this.groupApp.design_settings &&
          this.groupApp.design_settings.disableDeliveryInfo
            ? this.groupApp.design_settings.disableDeliveryInfo.button_two
            : null,
        selectedLanguage: this.selectedLangugage,
        baseLanguage: this.baseLanguage,
      },
      backdropDismiss: false,
    });

    await this.deliveryInfoModal.present();
    this.deliveryInfoModal.onDidDismiss().then(async (data) => {
      this.deliveryInfoModal = null;
    });
  }

  selectStore(storeID, modalDismiss) {
    if (storeID) {
      console.log("set store id order now modal");
      this.store.dispatch(new CurrentStoreActions.SetCurrentStoreId(storeID));
    }

    if (modalDismiss) {
      this.modalCtrl.dismiss();
    }
    this.router.navigateByUrl("/catalog", {
      replaceUrl: true,
    });
  }
}
