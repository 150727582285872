import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ModalController, Platform, ToastController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { distinctUntilChanged, Subscription } from "rxjs";
import * as fromApp from "../../../store/app.reducer";
import * as _ from "lodash";

@Component({
  selector: "app-billing-info",
  templateUrl: "./billing-info.component.html",
  styleUrls: ["./billing-info.component.scss"],
})
export class BillingInfoComponent implements OnInit, OnDestroy {
  @Input() billingInfo;
  @Input() billing_order_formatted_address: any;
  public darkMode: boolean;
  private selectedTheme: any;
  private subscription1: Subscription;
  private subscription2: Subscription;

  billingInfoForm: FormGroup;
  private unsubscribeBackEvent: Subscription;
  constructor(
    private modalCtrl: ModalController,
    private toastController: ToastController,
    private translateService: TranslateService,
    private platform: Platform,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit() {
    console.log("the biiling info ",this.billingInfo)
    this.subscription1 = this.store
      .select("groupApp")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.selectedTheme &&
          !_.isEqual(this.selectedTheme, state.selectedTheme)
        ) {
          this.selectedTheme = _.cloneDeep(state.selectedTheme);
          console.log("selected theme", this.selectedTheme);
          if (this.selectedTheme === "dark") {
            this.darkMode = true;
          } else {
            this.darkMode = false;
          }
        }
      });

    this.subscription2 = this.store
      .select("paymentGateWays")
      .pipe(distinctUntilChanged())
      .subscribe((state) => {
        if (
          state &&
          state.newCardBillingInfo &&
          !_.isEqual(this.billingInfo, state.newCardBillingInfo)
        ) {
          this.billingInfo = _.cloneDeep(state.newCardBillingInfo);
          if (
            !this.billing_order_formatted_address ||
            !this.billing_order_formatted_address.same_as_billing_address
          ) {
            this.initializeFormWithAllAttributes();
          } else {
            this.initializeFormOnlyCardHolderName();
          }
        }
      });
    console.log("billing info", this.billingInfo);
    this.unsubscribeBackEvent = this.platform.backButton.subscribeWithPriority(
      400,
      () => {
        if (this.platform.is("android")) {
          this.closeModal();
        }
      }
    );
    console.log(
      "billing order formatted address",
      this.billing_order_formatted_address
    );
    if (
      !this.billing_order_formatted_address ||
      !this.billing_order_formatted_address.same_as_billing_address
    ) {
      this.initializeFormWithAllAttributes();
    } else {
      this.initializeFormOnlyCardHolderName();
    }
  }

  changeSameAsBillingAddress() {
    if (!this.billing_order_formatted_address.same_as_billing_address) {
      this.initializeFormWithAllAttributes();
    } else {
      this.initializeFormOnlyCardHolderName();
    }
  }

  initializeFormOnlyCardHolderName() {
    this.billingInfoForm = null;
    if (
      this.billingInfo &&
      (this.billingInfo.gateway_id === "piraeus" || this.billingInfo.gateway_id === "nexi" ||
        this.billingInfo.CardHolderName)
    ) {
      this.billingInfoForm = new FormGroup({
        CardHolderName: new FormControl(this.billingInfo.CardHolderName || "", [
          this.cardHolderNameValidator,
          Validators.pattern("^[A-Za-z. ]+$"),
        ]),
      });
    }
  }

  initializeFormWithAllAttributes() {
    this.billingInfoForm = null;
    if (
      this.billingInfo &&
      (this.billingInfo.gateway_id === "piraeus" ||
        this.billingInfo.CardHolderName || this.billingInfo.gateway_id === "nexi")
    ) {
      this.billingInfoForm = new FormGroup({
        CardHolderName: new FormControl(this.billingInfo.CardHolderName || "", [
          this.cardHolderNameValidator,
          Validators.pattern("^[A-Za-z. ]+$"),
        ]),
        billAddress: new FormControl(this.billingInfo.billAddress || "", [
          this.billAddressValidator,
        ]),
        billAddressNumber: new FormControl(
          this.billingInfo.billAddressNumber || "",
          [this.billAddressNumberValidator]
        ),
        billZip: new FormControl(this.billingInfo.billZip || "", [
          this.billZipValidator,
        ]),
        billCountry: new FormControl(this.billingInfo.billCountry || "Greece", [
          this.billCountryValidator,
        ]),
      });
    } else {
      this.billingInfoForm = new FormGroup({
        billAddress: new FormControl(this.billingInfo.billAddress || "", [
          this.billAddressValidator,
        ]),
        billAddressNumber: new FormControl(
          this.billingInfo.billAddressNumber || "",
          [this.billAddressNumberValidator]
        ),
        billZip: new FormControl(this.billingInfo.billZip || "", [
          this.billZipValidator,
        ]),
        billCountry: new FormControl(this.billingInfo.billCountry || "", [
          this.billCountryValidator,
        ]),
      });
    }
  }

  cardHolderNameValidator(v: AbstractControl) {
    if (!!v.value) {
      return null;
    } else {
      return { CardHolderNameRequired: true };
    }
  }
  billAddressNumberValidator(v: AbstractControl) {
    if (!!v.value) {
      return null;
    } else {
      return { billAddressNumberRequired: true };
    }
  }
  billAddressValidator(v: AbstractControl) {
    if (!!v.value) {
      return null;
    } else {
      return { billAddressRequired: true };
    }
  }
  billZipValidator(v: AbstractControl) {
    if (!!v.value) {
      return null;
    } else {
      return { billZipRequired: true };
    }
  }

  billCountryValidator(v: AbstractControl) {
    if (!!v.value) {
      return null;
    } else {
      return { billCountryRequired: true };
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  onSubmit() {
    if (
      !this.billing_order_formatted_address ||
      !this.billing_order_formatted_address.same_as_billing_address
    ) {
      if (this.billingInfoForm && this.billingInfoForm.valid) {
        this.modalCtrl.dismiss(this.billingInfoForm.value);
      } else {
        let message = this.translateService.instant(
          "popups.billing-info.please-fill-all-the-billing-info"
        );
        this.presentErrorToast(message, "danger");
      }
    } else if (this.billing_order_formatted_address.same_as_billing_address) {
      console.log("billing info form", this.billingInfoForm);
      if (this.billingInfoForm && this.billingInfoForm.valid) {
        this.modalCtrl.dismiss({
          billingInfo:
            this.billingInfoForm && this.billingInfoForm.value
              ? this.billingInfoForm.value
              : null,
          billing_order_formatted_address: this.billing_order_formatted_address,
        });
      } else if (!this.billingInfoForm) {
        this.modalCtrl.dismiss({
          billingInfo:
            this.billingInfoForm && this.billingInfoForm.value
              ? this.billingInfoForm.value
              : null,
          billing_order_formatted_address: this.billing_order_formatted_address,
        });
      } else if (this.billingInfoForm && !this.billingInfoForm.valid) {
        let message = this.translateService.instant(
          "popups.billing-info.please-fill-all-the-billing-info"
        );
        this.presentErrorToast(message, "danger");
      }
    }
  }

  async presentErrorToast(message, color) {
    const toast = await this.toastController.create({
      message: message,
      duration: 20000,
      position: "middle",

      color: color,
      buttons: [
        {
          side: "end",
          icon: "assets/ionicons/close.svg",

          handler: () => {
            console.log("Toast Closed");
          },
        },
      ],
    });
    toast.present();
  }

  ngOnDestroy() {
    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }

    if (this.unsubscribeBackEvent) {
      this.unsubscribeBackEvent.unsubscribe();
    }
  }
}
